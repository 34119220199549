import { Box } from "@mui/material";
import { GridFooter, GridFooterContainer } from "@mui/x-data-grid-pro";
import { AddButton } from "../buttons/AddButton";

export function Footer({ show = true, onClick }) {
  return (
    <>
      <GridFooterContainer
        sx={{
          ".MuiDataGrid-footerContainer": {
            borderTop: "none",
            outline: "none",
          },
          minHeight: "20px",
          mb: 2,
        }}
      >
        <GridFooter
          sx={{
            borderTop: "none",
            outline: "none",
            minHeight: "0px",
            visibility: "hidden",
          }}
        />
      </GridFooterContainer>
      {show && onClick ? (
        <Box width={"180px"} my={2}>
          <AddButton onClick={onClick} label={"add"} />
        </Box>
      ) : null}
    </>
  );
}
