import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Formik, Form, FormikProps } from "formik";
import { InputField, SelectField } from "../inputs/InputField";
import { debounce, useLocation, yup } from "../../lib";
import {
  adminActions,
  adminSelectors,
  Recording,
  useDispatch,
  useSelector,
} from "../../state";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { JSONField } from "..";
import { DraggableCard } from "../DraggableCard";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { arrayMoveImmutable } from "array-move";



export const MenuForm = () => {
  const inputFields = [
    { name: "internal_name", type: "text", initial_value: "" },
    { name: "label", type: "text", initial_value: "" },
    { name: "valid_digits_regex", type: "text", initial_value: "" },
    { name: "highest_valid_number", type: "number", initial_value: -1 },
    { name: "note", type: "text", initial_value: "" },
    { name: "keypress_option", type: "text", initial_value: "" },
  ];
  const inputfieldValues = inputFields.reduce(
    (o, cur) => ({ ...o, [cur.name]: cur.initial_value }),
    {},
  );

  const _initialValues = {
    ...inputfieldValues,
    app_type_id_internal: "",
    app_type_id_external: "",
    app_type_id: -1,
    app_recordings: [],
    instructions: {},
    parent_apps: [],
    on_success_app: "",
    on_failure_app: "",
    action: ""
  };

  const dispatch = useDispatch();
  const location = useLocation();
  const formRef = useRef<FormikProps<any> | null>(null);

  const [initialValues, setInitialValues] = useState(_initialValues);
  const [recordings, setRecordings] = useState<Recording[]>([]);
  const [recSearch, setRecSearch] = useState<string>("");
  const params = useParams();
  const validationSchema = yup.object({
    internal_name: yup
      .string()
      .matches(/^[a-zA-Z0-9_]*$/g, {
        excludeEmptyString: true,
        message: "Only letters and numbers allowed",
      })
      .required("Internal Name is required"),
    label: yup.string().required("Label is required"),
    keypress_option: yup.string(),
    app_type_id_internal: yup.number().required("Internal App Type is required"),
    app_type_id_external: yup.number().required("External App Type is required"),
    on_success_app: yup.string().when("app_type_id_external", {
      is: (a) => (a !== 11 || params?.id),
      then: yup.string().required("On success app is required"),
      otherwise: yup.string(),
    }),
    on_failure_app: yup.string().when("app_type_id_external", {
      is: (a) => (a !== 11 || params?.id),
      then: yup.string().required("On failure app is required"),
      otherwise: yup.string(),
    })
  });

  const serials = useSelector(adminSelectors.serials);
  const internal_app_options = useSelector(adminSelectors.internal_app_options);
  const appTypes = useSelector(adminSelectors.appTypes);
  const menusObj = useSelector(adminSelectors.menusObj);
  useEffect(() => {
    if (params?.id) {
      const newValues = { ...initialValues, ...menusObj[params.id] };
      setInitialValues(newValues);
    }
    if (location.query.from) {
      const newValues = { ...initialValues, ...menusObj[location.query.from] };
      delete newValues.id;
      setInitialValues(newValues);
    }
  }, [menusObj]);

  const handleSubmit = async () => {
    dispatch(
      adminActions.saveMenu(formRef.current?.values, formRef.current?.values.action !== "stay"),
    );
  };

  useEffect(() => {
    dispatch(adminActions.getMenus());
    dispatch(adminActions.getAppTypes());
    dispatch(adminActions.getSerials());
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const escapedSearch = encodeURIComponent(recSearch);
      const s = recSearch !== "" ? `q=${escapedSearch}` : "field=id";
      const local = (await dispatch(
        adminActions.getRecordingsLocal(`${s}&range=0&range=100&sort=desc`),
      )) as unknown as Recording[];
      setRecordings(local);
    })();
  }, [recSearch]);

  const search = (e: SelectChangeEvent) => {
    const {
      target: { value },
    } = e;
    setRecSearch(value.trim());
  };
  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    const form = formRef.current;
    const recordings = formRef.current?.values?.app_recordings ?? [];
    form?.setFieldValue(
      "app_recordings",
      arrayMoveImmutable(recordings, dragIndex, hoverIndex),
    );
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {({ isSubmitting, values, setFieldValue, errors, touched }) => (
        <Form>
          <Grid sx={{}} alignItems={"center"} container spacing={4}>
            <Grid sx={{ width: "100%" }} item xs={12} md={6}>
              {inputFields.map((f) => (
                <InputField
                  key={f.name}
                  type={f.type}
                  label={f.name}
                  name={f.name}
                />
              ))}
              <SelectField
                label="Internal App Type"
                name="app_type_id_internal"
                options={appTypes.internal}
                initialValues={initialValues}
              />
              <SelectField
                label="External App Type"
                name="app_type_id_external"
                options={appTypes.external}
                initialValues={initialValues}
              />

              {values.app_type_id_external === 13 && (
                <SelectField
                  label="Select Serial Recording"
                  name="app_type_id"
                  options={serials.map((s) => {
                    return { id: s.id, label: s.name };
                  })}
                  initialValues={initialValues}
                />
              )}
              <Autocomplete
                options={internal_app_options}
                value={internal_app_options.find(r => r.id === values?.on_success_app) ?? null}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id
                }
                onChange={(e, v) => {
                  setFieldValue("on_success_app", v?.id);
                }}
                getOptionLabel={(option) => option?.label ?? ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="On Success App"
                    name="on_success_app"
                    error={touched["on_success_app"] &&
                      Boolean(errors["on_success_app"])}
                    helperText={touched["on_success_app"] &&
                      errors["on_success_app"]}
                  />
                )}
              />
              <Autocomplete
                options={internal_app_options}
                value={internal_app_options.find(r => r.id === values?.on_failure_app) ?? null}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id
                }
                onChange={(e, v) => {
                  setFieldValue("on_failure_app", v?.id);
                }}
                getOptionLabel={(option) => option?.label ?? ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="On Failure App"
                    name="on_failure_app"
                    error={touched["on_failure_app"] &&
                      Boolean(errors["on_failure_app"])}
                    helperText={touched["on_failure_app"] &&
                      errors["on_failure_app"]}
                  />
                )}
              />
              {
                <Autocomplete
                  freeSolo
                  filterOptions={(o, _s) => o}
                  disableCloseOnSelect
                  multiple
                  filterSelectedOptions
                  options={recordings}
                  value={values.app_recordings}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(e, v) => {
                    setFieldValue("app_recordings", v);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Recordings"
                      onChange={debounce(search, 500)}
                    />
                  )}
                />
              }
              {!!values.app_recordings.length && (
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel>Recording orders</InputLabel>
                  <Box sx={{ border: "2px solid #000", padding: 2, mt: 6 }}>
                    <DndProvider backend={HTML5Backend}>
                      {values.app_recordings.map((r: Recording, idx) => (
                        <Grid key={idx} container spacing={2}>
                          <Grid item md={9}>
                            <DraggableCard
                              index={idx}
                              id={r.id}
                              text={r.label}
                              moveCard={moveCard}
                            />
                          </Grid>
                          <Grid item md={3}>
                            <audio
                              style={{ maxWidth: 180 }}
                              preload="none"
                              controls
                              src={r.full_url}
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </DndProvider>
                  </Box>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <JSONField name="instructions" label={"Instructions"} />
            </Grid>
            <Grid item xs={12} md={6}>
              {internal_app_options.length && (
                <Autocomplete
                  multiple
                  filterSelectedOptions
                  options={internal_app_options.map((o) => o.id)}
                  value={values.parent_apps || []}
                  onChange={(e, v) => {
                    setFieldValue("parent_apps", v);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Parent Apps" />
                  )}
                />
              )}
            </Grid>
          </Grid>
          <Grid
            item
            width={"50%"}
            display={"flex"}
            justifyContent={"space-between"}
            mb={2}
          >
            <Box width={"150px"}>
              <Button
                sx={{ mt: 3, ml: 0 }}
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                onClick={() => setFieldValue("action", "save")}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Box>
            <Box width={"150px"}>
              <Button
                sx={{ mt: 3, ml: 0 }}
                color="primary"
                fullWidth
                variant="contained"
                type="submit"
                onClick={() => setFieldValue("action", "stay")}
                value={"stay"}
              >
                Save And Stay
              </Button>
            </Box>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
