export const states = [
  { code: "AL", state: " Alabama", country: "US" },
  { code: "AK", state: " Alaska", country: "US" },
  { code: "AZ", state: " Arizona", country: "US" },
  { code: "AR", state: " Arkansas", country: "US" },
  { code: "CA", state: " California", country: "US" },
  { code: "CO", state: " Colorado", country: "US" },
  { code: "CT", state: " Connecticut", country: "US" },
  { code: "DE", state: " Delaware", country: "US" },
  { code: "FL", state: " Florida", country: "US" },
  { code: "GA", state: " Georgia", country: "US" },
  { code: "HI", state: " Hawaii", country: "US" },
  { code: "ID", state: " Idaho", country: "US" },
  { code: "IL", state: " Illinois", country: "US" },
  { code: "IN", state: " Indiana", country: "US" },
  { code: "IA", state: " Iowa", country: "US" },
  { code: "KS", state: " Kansas", country: "US" },
  { code: "KY", state: " Kentucky", country: "US" },
  { code: "LA", state: " Louisiana", country: "US" },
  { code: "ME", state: " Maine", country: "US" },
  { code: "MD", state: " Maryland", country: "US" },
  { code: "MA", state: " Massachusetts", country: "US" },
  { code: "MI", state: " Michigan", country: "US" },
  { code: "MN", state: " Minnesota", country: "US" },
  { code: "MS", state: " Mississippi", country: "US" },
  { code: "MO", state: " Missouri", country: "US" },
  { code: "MT", state: " Montana", country: "US" },
  { code: "NE", state: " Nebraska", country: "US" },
  { code: "NV", state: " Nevada", country: "US" },
  { code: "NH", state: " New Hampshire", country: "US" },
  { code: "NJ", state: " New Jersey", country: "US" },
  { code: "NM", state: " NewMexico", country: "US" },
  { code: "NY", state: " New York", country: "US" },
  { code: "NC", state: " North Carolina", country: "US" },
  { code: "ND", state: " North  Dakota", country: "US" },
  { code: "OH", state: " Ohio", country: "US" },
  { code: "OK", state: " Oklahoma", country: "US" },
  { code: "OR", state: " Oregon", country: "US" },
  { code: "PA", state: " Pennsylvania", country: "US" },
  { code: "RI", state: " Rhode Island", country: "US" },
  { code: "SC", state: " South Carolina", country: "US" },
  { code: "SD", state: " South Dakota", country: "US" },
  { code: "TN", state: " Tennessee", country: "US" },
  { code: "TX", state: " Texas", country: "US" },
  { code: "UT", state: " Utah", country: "US" },
  { code: "VT", state: " Vermont", country: "US" },
  { code: "VA", state: " Virginia", country: "US" },
  { code: "WA", state: " Washington", country: "US" },
  { code: "WV", state: " West Virginia", country: "US" },
  { code: "WI", state: " Wisconsin", country: "US" },
  { code: "WY", state: " Wyoming", country: "US" },
  { code: "AB", state: "Alberta", country: "CA" },
  { code: "BC", state: "BritishColumbia", country: "CA" },
  { code: "MB", state: "Manitoba", country: "CA" },
  { code: "NB", state: "New Brunswick", country: "CA" },
  { code: "NF", state: "Newfoundland", country: "CA" },
  { code: "NT", state: "NorthwestTerritories", country: "CA" },
  { code: "NS", state: "NovaScotia", country: "CA" },
  { code: "NU", state: "Nunavut", country: "CA" },
  { code: "ON", state: "Ontario", country: "CA" },
  { code: "PE", state: "Prince Edward Island", country: "CA" },
  { code: "QC", state: "Quebec", country: "CA" },
  { code: "SK", state: "Saskatchewan", country: "CA" },
  { code: "YT", state: "Yukon Territory", country: "CA" },
];
