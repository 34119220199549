import { Typography } from "@mui/material";
import { RootListViewStyled } from "./GenericPage.styles";
import { RAFFLES_COLUMNS } from "../../components/tables/columns";
import { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  adminActions,
  adminSelectors,
  useDispatch,
  useSelector,
} from "../../state";
// import { buildParams } from "../../lib/requestBuilder";
import { ToolBar } from "../../components";
import { RaffleForm } from "../../components/forms/RaffleForm";
import CustomModal from "../../components/modal/CustomModal";

export const RafflesPage = () => {
  const [pageSize, setPageSize] = useState(25);
  const [openModal, setOpenModal] = useState(false);

  // const [tableStateParams, setTableStateParams] = useState("");
  // const voicemailRowCount = useSelector(adminSelectors.voicemailRowCount);

  const dispatch = useDispatch();
  const raffles = useSelector(adminSelectors.raffles);
  const openModalClick = () => {
    setOpenModal(!openModal);
    dispatch(adminActions.setDefaultRaffle());
  };
  const openModalEdit = async (id) => {
    setOpenModal(!openModal);
    await dispatch(adminActions.getRaffle(id));
  };
  useEffect(() => {
    dispatch(adminActions.getRaffles());
  }, [dispatch]);

  return (
    <RootListViewStyled>
      <Typography variant="h3" mb={3}>
        Raffles
      </Typography>

      <DataGridPro
        rows={raffles.rows}
        columns={RAFFLES_COLUMNS}
        pagination
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        // rowCount={voicemailRowCount}
        // sortingMode="server"
        // filterMode="server"
        // paginationMode="server"
        // onStateChange={(s, _e, _d) => setTableStateParams(buildParams(s))}
        onRowClick={(v) => openModalEdit(v.id)}
        components={{
          Toolbar: ToolBar,
        }}
        componentsProps={{
          toolbar: {
            showQuickSearch: true,
            showAddButton: true,
            label: "Add Raffle",
            onClick: openModalClick,
          },
        }}
      />
      <CustomModal open={openModal} onClose={openModalClick}>
        <RaffleForm closeModal={openModalClick} />
      </CustomModal>
    </RootListViewStyled>
  );
};
