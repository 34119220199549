import React, { useEffect, useState } from "react";
import { Grid, Button, Card, CardContent } from "@mui/material";
import { useDispatch } from "../../state";
import { TextField } from "formik-mui";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { adminActions } from "../../state";
import { useParams } from "react-router";
import { RootStyled, GridStyled } from "./UserPage.styles";
import { MultiSelectField } from "../../components/inputs/InputField";

const validationSchema = yup.object({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const CreateUsersPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [editMode, setEditMode] = useState(false);
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    roles: [3],
  });

  useEffect(() => {
    (async () => {
      if (params.id) {
        const user: any = await dispatch(adminActions.getUser(params.id));
        if (user) {
          setEditMode(true);
          setInitialValues({ ...user, roles: user?.roles?.map((r) => r.id) });
        }
      }
    })();
  }, [dispatch, params.id]);

  const availableRoles = [
    // { id: 1, name: "super_admin" },
    { id: 2, name: "admin" },
    { id: 3, name: "employee" },
    // { id: 4, name: "customer" },
  ];
  const handleSubmit = (values, { setSubmitting }) => {
    if (editMode) {
      dispatch(adminActions.updateUser(params.id, values));
    } else {
      dispatch(adminActions.createUser(values));
    }
    setSubmitting(false);
  };
  // const form = useFormik({
  //   initialValues,
  //   validationSchema,
  //   async onSubmit(values) {
  //     //dispatch(adminActions.createUser(values));
  //   },
  // });

  return (
    <RootStyled>
      <Card>
        <CardContent sx={{ pt: 6 }}>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              errors,
              touched,
              // handleChange,
            }) => (
              <Form>
                <Grid container>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      id="first_name"
                      name="first_name"
                      label="First Name"
                      error={touched.first_name && Boolean(errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                    />
                  </GridStyled>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      name="last_name"
                      label="Last Name"
                      required
                      error={touched.last_name && Boolean(errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                    />
                  </GridStyled>
                </Grid>

                <Grid container>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      type="email"
                      id="email"
                      name="email"
                      label="Email address"
                      disabled={!!editMode}
                      error={touched.email && Boolean(errors.email)}
                    />
                  </GridStyled>
                  <GridStyled item>
                    <MultiSelectField
                      sx={{ mt: 3 }}
                      options={availableRoles.map((i) => ({
                        id: i.id,
                        label: i.name,
                      }))}
                      name="roles"
                      label="Roles"
                      initialValues={initialValues}
                    />
                  </GridStyled>
                </Grid>

                <Grid container justifyContent="flex-end">
                  <GridStyled
                    item
                    sx={{ display: "flex", justifyContent: "end" }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </GridStyled>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </RootStyled>
  );
};

export default CreateUsersPage;
