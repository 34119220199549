import {
  Box,
  Chip,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, Link as routerLink } from "react-router-dom";
import { adminActions, adminSelectors } from "../../state";
import { RootListViewStyled } from "./GenericPage.styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaceIcon from "@mui/icons-material/Place";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { RewardsTab } from "../../components/tabs/RewardsTab";
//import { RecordingsTab } from "../../components/tabs/RecordingsTab";
import { Status } from "../../components";
import { useMobile } from "../../themes";
import { addOpacityToHex, capitalizeAddress, formatPhone } from "../../lib";

export const ChildPage = () => {
  const dispatch = useDispatch();
  const currentChild = useSelector(adminSelectors.childInfo);
  const parent = useSelector(adminSelectors.customer);
  const [value, setValue] = useState<string | number>(0);

  const isMobile = useMobile();
  const { id } = useParams();
  const nav = useNavigate();
  const returnToChildrenPage = () => {
    nav("/admin/children");
  };
  const { palette } = useTheme();
  const color15Opacity = addOpacityToHex(palette.primary.main, 0.15);

  useEffect(() => {
    if (id) {
      dispatch(adminActions.getChildInfo("admin", id));
    }
  }, [dispatch]);
  useEffect(() => {
    if (currentChild.customer_id > 0)
      dispatch(adminActions.getCustomer(currentChild.customer_id));
  }, [currentChild.customer_id]);

  const handleChange = (event: SyntheticEvent, newValue: string | number) => {
    setValue(newValue);
  };
  const childAmounts = Array.isArray(currentChild.children_points)
    ? currentChild.children_points.map((cp) => cp.points)
    : [];
  const childTotal = childAmounts.reduce((pre, cur) => pre + cur, 0);
  const curPhones = currentChild.customer.customer_phones;
  return (
    <RootListViewStyled>
      <Grid alignItems={"center"} container spacing={1}>
        <Grid item xs={12} md={3}>
          <Typography variant="h3">
            <IconButton onClick={returnToChildrenPage}>
              <ArrowBackIcon />
            </IconButton>
            {`${currentChild.first_name} ${currentChild.last_name}`}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box width={100} display={"inline-flex"} ml={4}>
            <Typography>
              PIN
              <Box ml={1} component={"span"} fontWeight={"bold"}>
                {currentChild.pin}
              </Box>
            </Typography>
          </Box>
          <Status active={currentChild.active} />
        </Grid>
      </Grid>
      <Grid
        container={isMobile}
        item={!isMobile}
        xs={12}
        md={3}
        display={"flex"}
        margin={4}
        width={isMobile ? "100%" : "60%"}
        flexBasis={1}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        alignContent={"baseline"}
      >
        <Grid item xs={12}>
          <Box display="flex">
            <PhoneIcon color="secondary" />
            {curPhones?.length ? (
              <Box display="flex" flexDirection={"column"} sx={{ ml: 2 }}>
                {curPhones.map((p, idx) => (
                  <Typography key={idx}>
                    {p.label}{" "}
                    {p.phone_number?.length === 10
                      ? formatPhone(p.phone_number)
                      : formatPhone(p.area_code + p.phone_number)}
                  </Typography>
                ))}
              </Box>
            ) : null}
          </Box>
        </Grid>
        <Grid item xs={6} my={0}>
          <Box display="flex">
            <PlaceIcon color="secondary" />
            <Box
              display="flex"
              flexDirection={isMobile ? "row" : "column"}
              sx={{ ml: 2 }}
            >
              <Typography>
                {capitalizeAddress(`${currentChild.customer.address} , ${currentChild.customer.city}
                ${currentChild.customer.state} ${currentChild.customer.zip}`)}
              </Typography>
            </Box>
          </Box>
        </Grid>
        {parent.id > 0 && (
          <Grid item xs={6}>
            <Box
              display="flex"
              flexDirection={"column"}
              alignContent={"flex-start"}
              my={0}
              ml={isMobile ? 2 : 0}
            >
              <Typography fontWeight={"bold"}>Customer</Typography>
              <Box
                display="flex"
                alignItems={"center"}
                justifyItems={"flex-start"}
              >
                <Chip
                  size="small"
                  sx={{
                    borderRadius: 0,
                    backgroundColor: color15Opacity,
                    color: palette.primary.main,
                    fontWeight: "bold",
                    fontSize: 14,
                    mr: 2,
                    ml: 0,
                  }}
                  label={`${parent.user.first_name} ${parent.user.last_name}`}
                />

                <IconButton
                  color={"inherit"}
                  onClick={() => nav(`/admin/customers/${parent.id}/general`)}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>

      <Box
        sx={{
          width: 600,
          textAlign: "center",
          display: "flex",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          indicatorColor="primary"
        >
          <Tab
            component={routerLink}
            label={"Rewards"}
            to={`/admin/children/${id}/rewards`}
          />
          {/* { <Tab label="Recordings" />} */}
        </Tabs>
      </Box>
      <Divider />

      <RewardsTab
        value={`/admin/children/${id}/rewards`}
        totalEarned={childTotal}
        totalUsed={0}
      />
      {/*  <RecordingsTab value={value} />*/}
    </RootListViewStyled>
  );
};
