import { useMediaQuery, createTheme } from "@mui/material";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";

/** Material-ui default theme, to copy from where necessary. */
// const defaults = createMuiTheme();

// #region Example to copy shadows from default theme.
/**
 * Override box shadows[3]. Must provide all 25 array elements...
 * - https://github.com/mui-org/material-ui/issues/8780
 * - https://gist.github.com/phacks/6c3c3a5f395f6e9660ae132c237250a3
 */
// const boxShadow = "0 0 4px -4px rgba(0,0,0,0.12), 0 0 8px 0 rgba(0,0,0,0.16)";
// const shadows = [...defaults.shadows];
// shadows[3] = boxShadow;
// #endregion

/**
 * Default theme for the app based on the
 * [Materiaul UI default theme](https://material-ui.com/customization/default-theme/)
 * with example overrides.
 */
export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#9957A3",
      contrastText: "#ffffff",
      light: "rgba(153,87,163,0.09)",
      dark: "#5E2851",
    },
    secondary: { main: "#F7C05C", contrastText: "#000000", light: "#5D6995" },
    success: { main: "#128e56" },
    error: { main: "#bb2018" },
    warning: { main: "#f3a94f" },
    info: { main: "#f3a94f" },
    text: { disabled: "#8395b2" },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: 40,
          fontFamily: "Cera Pro",
          fontSize: 16,
          fontWeight: 500,
          letterSpacing: 0.2,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        // try all variations of "& . some only work with a space others without the &....
        root: {
          //everything toolbar grid and footer
          "&.MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-toolbarContainer": {
            //toolbar on top
            justifyContent: "right",
            size: "14px",
            marginBottom: "19px",
            border: "none",
          },
          ".MuiDataGrid-main": {
            //dataGrid with headers
            border: "1px solid #DDE3EB",
            borderBottom: "none",
            borderRadius: "8px 8px 0 0",
            cursor: "pointer",
          },
          "& .MuiDataGrid-columnHeaders": {
            //column headers
            fontFamily: "Roboto",
            fontWeight: "Bold",
            color: "#737373",
            font: "Roboto Bold",
            fontSize: "16px",
          },
          "& > .MuiDataGrid-virtualScrollerContent": {
            //data grid without headers
            fontFamily: "Roboto",
            font: "Roboto Bold",
            fontSize: "13px",
          },
          ".MuiDataGrid-footerContainer": {
            //footer
            border: "1px solid #DDE3EB",
            borderRadius: " 0 0 8px 8px",
          },
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        // try all variations of "& . some only work with a space others without the &....
        root: {
          "&.MuiPaper-elevation": {
            borderRadius: "8px",
            boxShadow: "none",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        // try all variations of "& . some only work with a space others without the &....
        root: {
          ".MuiDialog-paper": {
            borderRadius: "8px",
            boxShadow: "0 1px 12px 0 #DADFF6",
          },
          ".MuiDialogTitle-root": {
            border: "none",
            color: "#082B65",
            fontFamily: "Roboto",
            fontSize: "26px",
            fontWeight: "bold",
            backgroundColor: "#F3F6F9",
            paddingTop: "24px",
            paddingBottom: "24px",
          },
          ".MuiDialogContentText-root": {
            paddingTop: "32px",
            fontFamily: "Roboto",
            fontSize: "16px",
            color: "#000",
          },
          ".MuiTextField-root": {
            marginTop: "20px",
            marginBottom: "20px",
            borderRadius: "4px",
            color: "#082B65",
          },
          ".MuiInputLabel-root": {
            color: "#B4B8C8",
            "&.Mui-focused": {
              color: "#00175C",
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        // try all variations of "& . some only work with a space others without the &....
        root: {
          "&.MuiTextField-root": {
            marginTop: "20px",
            marginBottom: "20px",
            "&.mobile-root": { marginTop: "5px", marginBottom: "5px" },
            "&.remove-mt": { margin: "20px", marginTop: "0px" },
            borderRadius: "4px",
            color: "#B4B8C8",
          },
          // "& .MuiFormLabel-root": {
          //   color: "red",
          // },
          ".MuiInputLabel-root": {
            color: "#B4B8C8",
            "&.Mui-focused": {
              color: "#00175C",
            },
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: false,
        placement: "right",
      },
      styleOverrides: {
        tooltip: {
          fontFamily: "Roboto",
          backgroundColor: "#00175C",
          fontSize: "14px",
          letterSpacing: "-0.04px",
          lineHeight: "16px",
          boxShadow: "0 3px 4px 0 rgba(8,43,101,0.5)",
          borderRadius: "5px",
        },
        arrow: {
          "&:before": {
            backgroundColor: "#00175C",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        // try all variations of "& . some only work with a space others without the &....
        root: {
          "& .MuiButtonBase-root": {
            fontFamily: "Cera Pro",
          },
        },
      },
    },
  },

  typography: {
    h1: {
      fontFamily: "Cera Pro",
      fontSize: 58,
      color: "#00175C",
    },
    h2: {
      fontFamily: "Cera Pro",
      fontSize: 35,
      color: "#00175C",
      letterSpacing: 0.38,
    },
    h3: {
      fontFamily: "Cera Pro",
      fontSize: 26,
      fontWeight: "bold",
      color: "#00175C",
      letterSpacing: 0.28,
    },
    h4: {
      fontFamily: "Cera Pro",
      fontSize: 22,
      fontWeight: "bold",
      color: "#00175C",
      letterSpacing: 0.24,
    },
    h5: {
      fontFamily: "Cera Pro",
      fontSize: 20,
      fontWeight: "bold",
      color: "#00175C",
    },
    h6: {
      fontFamily: "Cera Pro",
      fontSize: 18,
      fontWeight: 600,
      color: "#00175C",
    },
    body1: {
      fontFamily: "Roboto",
      fontSize: 16,
      color: "#020F25",
    },
    body2: {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 500,
      color: "#020F25",
      letterSpacing: 0.15,
    },
    caption: {
      fontFamily: "Cera Pro",
      fontSize: 12,
      fontWeight: 600,
      color: "#5C738E",
      letterSpacing: 0.09,
    },
    subtitle1: {
      fontFamily: "Cera Pro",
      fontSize: 13,
      fontWeight: 500,
      color: "#000000",
    },
    subtitle2: {
      fontFamily: "Cera Pro",
      fontSize: 12,
      fontWeight: 500,
      color: "#767676",
      letterSpacing: 0.15,
    },
  },

  // enter image description here

  // #region Example global overrides for this theme:
  // overrides: {

  // Example to override "contained" variant of the Material-ui Button:
  // MuiButton: {
  //   contained: {
  //     boxShadow: "none",
  //     "&:hover": {
  //       boxShadow: "none",
  //     },
  //   },
  // },

  //   // Example to override the "contained" variant of Material-ui ButtonGroup:
  //   // MuiButtonGroup: {
  //   //   contained: {
  //   //     boxShadow: "none",
  //   //     border: "1px solid rgba(0,0,0,0.16)",
  //   //   },
  //   // },

  //   // Example to override the paper style of the Material-ui Menu:
  //   // MuiMenu: {
  //   //   paper: {
  //   //     boxShadow,
  //   //   },
  //   // },

  // },
  // #endregion

  // #region Example default props for any Material-ui components rendered with
  // this theme:
  // props: {
  //   MuiCard: {
  //     variant: "outlined",
  //   },
  // },
  // #endregion

  // Example to set custom shadows:
  // shadows,
});

/**
 * Returns `true` if the screen is sized for mobile. Depends on theme
 * breakpoints, otherwise this function would be defined in `lib/hooks.js`
 */
export function useMobile(size = "(max-width:600px)") {
  return useMediaQuery(size, {
    noSsr: true,
  });
}
export function useTablet(size = "(min-width: 600px) and (max-width:921px)") {
  return useMediaQuery(size, {
    noSsr: true,
  });
}
export function useSmallerHeight(size = "(max-height:860px)") {
  return useMediaQuery(size, {
    noSsr: true,
  });
}
export function useSmallerScreenLayout(size = "(max-width:1200px)") {
  return useMediaQuery(size, {
    noSsr: true,
  });
}
