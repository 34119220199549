import { GridState } from "@mui/x-data-grid-pro";
import { stringify } from "query-string";

type SortingModel = GridState["sorting"]["sortModel"][0];

interface QueryParams {
  sort?: SortingModel["sort"];
  field?: SortingModel["field"];
  filter?: string;
  range?: number[];
  q?: string;
}

export const buildParams = (state: GridState) => {
  const queryParams: QueryParams = {};
  if (state.sorting?.sortModel?.length) {
    queryParams.sort = state.sorting.sortModel[0].sort;
    queryParams.field = state.sorting.sortModel[0].field;
  }
  if (state.filter.filterModel.items.length) {
    queryParams.filter = JSON.stringify(
      state.filter.filterModel.items.map(
        ({ columnField, operatorValue, value }) => ({
          columnField,
          operatorValue,
          value,
        }),
      ),
    );
  }
  if (state.filter.filterModel.quickFilterValues?.length) {
    queryParams.q = state.filter.filterModel.quickFilterValues[0];
  }
  queryParams.range = [state.pagination.page, state.pagination.pageSize];
  return stringify(queryParams);
};
