import { CountrySelection } from "../../components/subscribe/CountrySelection";
import { CheckoutSuccess } from "../../components/subscribe/CheckoutSuccess";
import { EmailSent } from "../../components/subscribe/EmailSent";
// import { ProductSelection } from "../../components/subscribe/ProductsSection";
import { HomePage } from "./HomePage";
import { NotAuthorizedPage } from "./NotAuthorizedPage";
import { NotFoundPage } from "./NotFoundPage";
import { ManageLoop } from "../../components/subscribe/ManageLoop";
import { LoopAudioCustomerPage } from "./LoopAudioCustomerPage";
import { Stats } from "./StatsPage";
import { LoopDashboard } from "./LoopDashboard";
export const MainPages = {
  home: {
    anon: true,
    path: "/",
    title: "Home",
    type: "PAGE_HOME",
    view: HomePage,
  },
  countrySelection: {
    anon: true,
    path: "/country",
    title: "Country Selection",
    view: CountrySelection,
  },
  // products: {
  //   anon: true,
  //   path: "/products/:tab",
  //   title: "Products/:tab",
  //   view: ProductSelection,
  // },
  manageLoop: {
    anon: false,
    path: "/manage-loop",
    title: "Manage Loop",
    view: ManageLoop,
  },
  manageLoopSubscription: {
    anon: false,
    path: "/manage-loop/:subscription_id",
    title: "Manage Loop",
    view: ManageLoop,
  },
  loopDash: {
    anon: false,
    path: "/loop/dashboard",
    title: "Loop Dashboard",
    view: LoopDashboard,
  },
  loopAudioCustomer: {
    anon: false,
    path: "/loop/audio/:id",
    title: "Manage Loop",
    view: LoopAudioCustomerPage,
  },
  success: {
    anon: true,
    path: "/success/:subscription",
    title: "Success",
    view: CheckoutSuccess,
  },
  emailSent: {
    anon: false,
    path: "/email-sent",
    title: "Email-Sent",
    view: EmailSent,
  },
  stats: {
    anon: true,
    path: "/stats",
    title: "Subscriptions By Country",
    view: Stats,
  },
  notAuthorized: {
    anon: true,
    path: "unauthorized",
    title: "Not Authorized",
    type: "PAGE_NOT_AUTHORIZED",
    view: NotAuthorizedPage,
  },
  notFound: {
    anon: true,
    path: "*",
    title: "Page Not Found",
    type: "PAGE_NOT_FOUND",
    view: NotFoundPage,
  },
};
