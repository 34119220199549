import { Box } from "@mui/material";
import SirConf from "../assets/img/sir_conference.png";

export const CirConferenceCircle = () => (
  <Box
    sx={{
      margin: "40px auto",
      width: 260,
      height: 260,
      border: "10px solid rgba(255, 255, 255, .01)",
      backgroundColor: "rgba(153, 87, 163, .1)",
      borderRadius: "50%",
      backgroundImage: `url(${SirConf})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    }}
  />
);
