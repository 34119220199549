import React, { useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { uiSelectors, useSelector } from "../../state";
import Notification from "../../components/Notification";
import { Outlet } from "react-router-dom";
import { ImpersonateBanner } from "../../components/ImpersonateBanner";
import { ADMIN_BASE_URL, CUSTOMER_SITE_BASE_URL } from "../../config";

function _MainLayout() {
  const loading = useSelector(uiSelectors.loading);
  useEffect(() => {
    if (window.location.origin === ADMIN_BASE_URL) {
      window.location.href = CUSTOMER_SITE_BASE_URL;
    }
  }, [location]);

  return (
    <>
      <ImpersonateBanner />

      <Outlet />

      <Notification />
      {loading && (
        <Backdrop open={true}>
          <CircularProgress size={128} />
        </Backdrop>
      )}
    </>
  );
}

export const MainLayout = React.memo(_MainLayout);
