import { SubscribeLayout } from "../../layouts/main/SubscribeLayout";
import partybegins from "../../assets/img/partybegins.png";
import partybeginscircle from "../../assets/img/partybeginscircle.png";
import partybeginsbook from "../../assets/img/partybeginsbook.png";
import gift from "../../assets/img/gift.png";
import circle_book_club from "../../assets/img/circle_book_club.png";
import circle_merch from "../../assets/img/circle_merch.png";
import leaders_game from "../../assets/img/leaders_game.png";
import loop_phone from "../../assets/img/loop_phone.png";
import { useMobile, useSmallerScreenLayout, useTablet } from "../../themes";
import { FlowSuccess } from "./FlowSuccess";
import { Box, Button, Link, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { customerSelectors } from "../../state";
import { useParams } from "react-router-dom";
export const CheckoutSuccess = () => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isSmallerThanPc = isTablet || isMobile;
  const smallerW = useSmallerScreenLayout() && !isMobile && !isTablet;

  const { exist } = useSelector(customerSelectors.customer);
  const { palette } = useTheme();
  const { subscription } = useParams();
  const promEndDate = new Date("2023-04-22").getTime();
  const promoStartDate = new Date("2023-03-15").getTime();
  const today = new Date().getTime();
  const hasPromo =
    (subscription?.includes("loop") || subscription?.includes("circle")) &&
    today > promoStartDate &&
    today < promEndDate;

  const summerPromoDate = new Date("06/18/2023");
  const todayDate = new Date();
  const hasSummerPromo =
    subscription?.includes("circle") &&
    todayDate.toDateString() == summerPromoDate.toDateString();

  return (
    <SubscribeLayout
      sx={isMobile ? { margin: "0px", padding: "0px" } : {}}
      subHeader={false}
      showTabs={false}
    >
      <FlowSuccess
        img={
          subscription?.includes("loop")
            ? partybegins
            : subscription?.includes("circle")
            ? partybeginscircle
            : partybeginsbook
        }
        alt={"party-begins-img"}
        caption={"You were successfully subscribed!"}
        noPadding={isMobile}
      >
        {/* <Box
          gap={isMobile ? 2 : 4}
          padding={isMobile ? 1 : 2}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ outline: "1px solid #D7D9EC " }}
        >
          <Typography
            variant={isMobile ? "body1" : "h6"}
            fontWeight={"bold"}
            textAlign={"center"}
          >
            Your pin to manage subscriptions on the phone is
          </Typography>
          <Typography variant="h2" fontWeight={"bold"}>
            78232
          </Typography>
        </Box>
          */}
        {!exist && (
          <>
            <Typography
              variant={isMobile ? "h6" : "h4"}
              fontWeight={"bold"}
              textAlign={"center"}
            >
              We’ve sent you an email to finish setting up your account.
            </Typography>

            {subscription?.includes("loop") && (
              <Box
                sx={{
                  backgroundColor: palette.secondary.main,
                  p: 2,
                  width: "332px",
                  borderRadius: "58px",
                }}
                textAlign={"center"}
              >
                <Typography fontSize={20}>Save the number!</Typography>
                <Typography
                  fontSize={28}
                  fontWeight={"bold"}
                  variant={isMobile ? "h6" : "h4"}
                >
                  (848) 777-8888
                </Typography>
              </Box>
            )}
          </>
        )}
        {hasPromo && (
          <Box
            sx={{
              backgroundColor: "primary.light",
              paddingRight: "0px !important",
              paddingLeft: "0px !important",
            }}
            width={isSmallerThanPc || smallerW ? "100%" : "700px"}
            display={"flex"}
            borderRadius={"4px"}
            paddingTop="30px"
            paddingBottom="30px"
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              width={isSmallerThanPc || smallerW ? "100%" : "max-content"}
              flexWrap={"wrap"}
              alignItems={"center"}
            >
              <img
                alt={"gift-png"}
                src={gift}
                height={"40px"}
                style={{ paddingRight: "15px" }}
              />
              <Typography
                color="primary.dark"
                textAlign={"center"}
                fontSize={"20px"}
                fontWeight={"bold"}
              >
                You might qualify for an Afikoman Special FREE Gift!
              </Typography>
              <Box
                width="100%"
                border="2px solid rgba(153, 87, 163,.3)"
                borderRadius="4px"
                paddingX="15px"
                paddingY="30px"
                marginTop="20px"
                marginRight={isMobile ? "10px" : "30px"}
                marginLeft={isMobile ? "10px" : "30px"}
                display="flex"
                justifyContent="space-between"
                flexDirection={isMobile ? "column" : "row"}
              >
                <div
                  style={{
                    display: isMobile ? "flex" : "contents",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    textAlign="center"
                    paddingRight="15px"
                    width={isMobile ? "50%" : "25%"}
                  >
                    <Box height="105px">
                      <img alt={"leaders-png"} src={leaders_game} />
                    </Box>
                    <Typography fontSize="12px" fontWeight="500">
                      Leader’s Game Collector’s Edition
                    </Typography>
                    <Typography fontSize="9px" fontWeight="400">
                      Exclusive set of 54 metallic cards in an engraved lucite
                      case
                    </Typography>
                  </Box>
                  <Box
                    textAlign="center"
                    paddingRight="15px"
                    width={isMobile ? "50%" : "25%"}
                  >
                    <Box height="105px">
                      <img alt={"merch-png"} src={circle_merch} />
                    </Box>
                    <Typography fontSize="12px" fontWeight="500">
                      $15 credit towards Circle Merch
                    </Typography>
                  </Box>
                </div>
                <Box
                  display={isMobile ? "flex" : "contents"}
                  justifyContent="space-between"
                  paddingTop="20px"
                >
                  <Box
                    textAlign="center"
                    paddingRight="15px"
                    width={isMobile ? "50%" : "25%"}
                  >
                    <Box height="105px">
                      <img alt={"loop-png"} src={loop_phone} />
                    </Box>
                    <Typography fontSize="12px" fontWeight="500">
                      Voucher for 30 days free on The Loop
                    </Typography>
                  </Box>
                  <Box textAlign="center" width={isMobile ? "50%" : "25%"}>
                    <Box height="105px">
                      <img alt={"circle-book-png"} src={circle_book_club} />
                    </Box>
                    <Typography fontSize="12px" fontWeight="500">
                      Circle Book Club sneek peek: Receive May’s book free!
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box marginTop="30px" marginBottom="30px">
                <Button
                  sx={{ height: "50px", fontSize: "16px" }}
                  size="large"
                  variant="contained"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSegXCc-Sw6nRl7juNHTMGcliFgJv2UKbCx093LjcwJyfHKG8w/viewform?vc=0&c=0&w=1&flr=0"
                >
                  Redeem my free gift
                </Button>
              </Box>
              <Box marginRight="30px" marginLeft="30px">
                <Typography fontSize="12px" textAlign="center">
                  While supplies last. Prizes may vary slightly from picture.
                  The $15 redeemable coupon will entitle you to your choice of
                  gifts at{" "}
                  <Link href="https://circmag.com/product-category/shop/">
                    The Circle Shop
                  </Link>
                  . All prizes ship after Pesach. Promotion expires 4/21/2023;
                  prizes must be claimed by 4/28/2023. Promotion only available
                  for new US subscribers, or US subscribers renewing from an
                  annual subscription to a monthly subscription. International
                  subscribers can redeem voucher for 30 days free on The Loop.
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {hasSummerPromo && (
          <Box
            sx={{ backgroundColor: "primary.light" }}
            width={isSmallerThanPc || smallerW ? "100%" : "700px"}
            height={isSmallerThanPc || smallerW ? "100%" : "125px"}
            display={"flex"}
            alignItems={"center"}
            p={isMobile || smallerW ? 2 : 0}
            pl={2}
            justifyContent={"space-evenly"}
            flexDirection={isSmallerThanPc || smallerW ? "column" : "row"}
            borderRadius={"4px"}
          >
            <img alt={"gift-png"} src={gift} width={"63px"} height={"66px"} />

            <Box
              display={"flex"}
              justifyContent={"center"}
              width={isSmallerThanPc || smallerW ? "100%" : "max-content"}
              flexWrap={"wrap"}
            >
              <Typography
                color={"primary"}
                textAlign={"center"}
                fontSize={"20px"}
                fontWeight={"bold"}
              >
                Congrats! You are eligible for The Circle Summer Flash Sale.
              </Typography>
              <Typography
                textAlign={"center"}
                fontSize={"16px"}
                width={"400px"}
              >
                New subscribers enjoy two months free to The Circle. Renewals
                enjoy two weeks free to The Circle.
              </Typography>
            </Box>
          </Box>
        )}
      </FlowSuccess>
    </SubscribeLayout>
  );
};
