import {
  TextField,
  Box,
  Button,
  Typography,
  Grid,
  Divider,
  Autocomplete,
  AutocompleteChangeReason,
} from "@mui/material";

import { useState } from "react";
import { useMobile, useTablet } from "../../themes";
import { CountryButton, CountryButtonGroup } from "./CountrySelection.styles";
import { SubscribeLayout } from "../../layouts/main/SubscribeLayout";
import { CountryList } from "../../lib/countries";
import { Formik, Form } from "formik";
import { yup } from "../../lib";
import { InputField } from "../inputs/InputField";

export const CountrySelection = () => {
  const validationSchema = yup.object().shape(
    {
      location: yup.string(),
      zip: yup
        .string()

        .when("location", {
          is: (location: string) => {
            return (
              (location && location.trim().toUpperCase() === "US") ||
              location.trim().toUpperCase() === "CANADA"
            );
          },
          then: yup
            .string()
            .required("zip/postal code is required")
            .matches(
              /^(\d{5}-\d{4}|\d{5}|\d{9})$|^([a-zA-Z]\d[a-zA-Z]( )?\d[a-zA-Z]\d)$/,
              "zip/postal code is invalid",
            ),
          otherwise: yup.string().optional().nullable(),
        }),
    },
    [["location", "zip"]],
  );

  const initialValues = {
    location: "",
    zip: "",
  };

  const [inputValue, setInputValue] = useState("");
  const isTablet = useTablet();
  const isMobile = useMobile();
  const isSmallerThanPc = isTablet || isMobile;
  const countries = [
    { name: "Us", flag: "🇺🇸", countryCode: "US" },
    { name: "Canada", flag: "🇨🇦", countryCode: "CA" },
    { name: "Uk", flag: "🇬🇧", countryCode: "GB" },
    { name: "Israel", flag: "🇮🇱", countryCode: "IL" },
    { name: "Other", flag: "", countryCode: "" },
  ];

  // const dispatch = useDispatch();

  // const nav = useNavigate();
  // const { customer } = useSelector(customerSelectors.customer);

  // const handleSubmit = async ({ zip, location }) => {
  //   const data = {
  //     customer: {
  //       ...customer,
  //       ship_zip:
  //         /^(\d{5}-\d{4}|\d{5}|\d{9})$|^([a-zA-Z]\d[a-zA-Z]( )?\d[a-zA-Z]\d)$/.test(
  //           zip,
  //         )
  //           ? zip.trim()
  //           : undefined,
  //       billing_zip:
  //         /^(\d{5}-\d{4}|\d{5}|\d{9})$|^([a-zA-Z]\d[a-zA-Z]( )?\d[a-zA-Z]\d)$/.test(
  //           zip,
  //         )
  //           ? zip.trim()
  //           : undefined,
  //       country:
  //         location.length === 2
  //           ? location
  //           : countries
  //             .filter((c) => c.name.toUpperCase() === location)[0]
  //             .countryCode.trim() ?? "",
  //     },
  //   };
  //   dispatch(customerActions.setCustomerInfo(data));

  //   const returnedVal = dispatch(
  //     customerActions.setProductsAvailable(
  //       data.customer.billing_zip ?? "",
  //       data.customer.country === "GB" ? "UK" : data.customer.country,
  //     ),
  //   );

  //   if (await returnedVal) {
  //     nav({
  //       pathname: "/products/selection",
  //     });
  //   }
  // };
  return (
    <SubscribeLayout
      subHeader={true}
      showTabs={false}
      sx={{
        display: isSmallerThanPc ? "inherit" : "flex",
        alignItems: isSmallerThanPc ? "center" : "flex-start",
        ml: isSmallerThanPc ? 0 : 4,
      }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        // onSubmit={handleSubmit}
        onSubmit={() => console.error("Country page has been disabled")}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Grid item display={"flex"} flexDirection={"column"} gap={2}>
              <Typography fontWeight={"medium"} fontSize={20}>
                Select your country to continue
              </Typography>

              <CountryButtonGroup
                value={values.location}
                exclusive
                aria-label="Country option"
                sx={{
                  maxWidth: !isSmallerThanPc ? "calc(100vw - 480px)" : "100%",
                  width: "auto",
                  mr: 4,
                }}
              >
                {countries.map((c) => {
                  return (
                    <CountryButton
                      key={c.name}
                      value={c.name.toUpperCase()}
                      onClick={() => {
                        setFieldValue("location", c.name.toUpperCase());
                      }}
                    >
                      {c.flag} {c.name}
                    </CountryButton>
                  );
                })}
              </CountryButtonGroup>
              <Grid item>
                {(values.location === "US" || values.location === "CANADA") && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    flexDirection={"column"}
                    width={isMobile ? "100%" : "70%"}
                    my={isMobile ? 1 : 2}
                  >
                    <Typography
                      fontWeight={"medium"}
                      fontSize={isMobile ? 18 : 20}
                    >
                      Please enter your
                      {values.location === "CANADA"
                        ? " postal "
                        : " ZIP "} code{" "}
                    </Typography>
                    <Typography fontSize={14}>
                      This is where your subscription will be delivered to
                    </Typography>
                    <Box width={isSmallerThanPc ? "100%" : "233px"}>
                      <InputField
                        label={
                          values.location === "CANADA" ? " Postal " : " ZIP "
                        }
                        name="zip"
                      />
                    </Box>
                  </Box>
                )}
                {!["US", "CANADA", "ISRAEL", "UK", ""].includes(
                  values.location,
                ) && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    flexDirection={"column"}
                    width={isMobile ? "100%" : "70%"}
                    my={isMobile ? 1 : 2}
                  >
                    <Typography
                      fontWeight={"medium"}
                      fontSize={isMobile ? 18 : 20}
                    >
                      Please select your country
                    </Typography>
                    <Autocomplete
                      disablePortal
                      id="country"
                      options={CountryList}
                      onChange={(
                        event: any,
                        newValue: any,
                        reason: AutocompleteChangeReason,
                      ) => {
                        let _newValue = newValue;
                        if (reason === "clear") {
                          _newValue = { label: "", value: "" };
                        }

                        setFieldValue("location", _newValue.value);
                      }}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Country" />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                    />
                  </Box>
                )}
              </Grid>

              <Divider sx={{ my: isSmallerThanPc ? 0 : 2 }} />
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                mb={isSmallerThanPc ? 1 : 2}
              >
                <Button variant="contained" type="submit">
                  Continue
                </Button>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
    </SubscribeLayout>
  );
};
