import { Button, Typography, Box } from "@mui/material";
import { SubscribeLayout } from "../../layouts/main/SubscribeLayout";
import { useMobile, useTablet } from "../../themes";
import whiteloopbkgrd from "../../assets/img/whiteloopbackground.jpg";

export const HomePage = () => {
  const isTablet = useTablet();
  const isMobile = useMobile();
  const isSmallerThanPc = isTablet || isMobile;
  return (
    <SubscribeLayout
      showTabs={false}
      subHeader={true}
      sx={{
        alignItems: "center",
        py: isSmallerThanPc ? 2 : 0,
        justifyContent: "center",
        display: isMobile ? "initial" : "flex",
        height: isSmallerThanPc ? "max-content" : "100vh",
        width: isMobile ? "auto" : "100%",
        background: `url("${isSmallerThanPc ? "" : whiteloopbkgrd}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
      }}
    >
      <Box borderRadius={isSmallerThanPc ? "5px" : 0} pt={2}>


        <Box
          sx={{ backgroundColor: "#FFF" }}
          width={isMobile ? "95%" : "60%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          py={isMobile ? 4 : 10}
          my={isMobile ? 20 : 0}
          px={isMobile ? 4 : 8}
          border={isMobile ? "" : "1px solid #D7D9EC"}
          gap={isMobile ? 2 : 4}
        >
          {isMobile && (
            <Typography
              variant={"h3"}
              fontSize={26}
              fontWeight={"bold"}
              textAlign={"center"}
              color={"#4E2541"}
            >
              Welcome!
            </Typography>
          )}
          <Typography
            variant={isMobile ? "h4" : "h2"}
            fontSize={isMobile ? 15 : 20}
            fontWeight={isMobile ? "normal" : "bold"}
            textAlign={"center"}
          >
            What do you need to do today?
          </Typography>
          <Box width={isMobile ? "100%" : "90%"} textAlign={"center"} >
            <Button
              href="https://subscriptions.circmag.com"
              variant="contained"
              fullWidth={isSmallerThanPc}
              sx={{
                width: isMobile ? "100%" : isTablet ? "50%" : "10vw",
                m: 1
              }}

            >
              Checkout
            </Button>
            <Button
              href="auth/login"
              variant="contained"
              fullWidth={isSmallerThanPc}
              sx={{
                width: isMobile ? "100%" : isTablet ? "50%" : "15vw",
                m: 1
              }}
            >
              Manage My Loop Account
            </Button>

          </Box>
        </Box>
      </Box>
    </SubscribeLayout>
  );
};
