import { Dialog, Box, IconButton } from "@mui/material";
import React from "react";
import { CloseIcon } from "..";

export const ModalJSONReportView = (props: any) => {
  const handleClose = () => {
    props.setShowDialog(false);
  };
  return (
    <Dialog
      open={props.showDialog}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <IconButton
        style={{ position: "absolute", top: 10, right: 10 }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <Box style={{ padding: 20 }}>
        <pre>{JSON.stringify(props.value, null, 2)}</pre>
      </Box>
    </Dialog>
  );
};
