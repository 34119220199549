import AdminPages from ".";
import { ArrowBackIcon, MenuForm } from "../../components";
import { Grid, IconButton, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { RootListViewStyled } from "./GenericPage.styles";

export const MenusCreateOrEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <RootListViewStyled>
      <Grid
        container
        spacing={12}
        direction="row"
        justifyContent="space-between"
        paddingBottom={3}
      >
        <Grid item>
          <Typography variant="h3">
            <IconButton
              color="primary"
              onClick={() => navigate(AdminPages.menus.path)}
            >
              <ArrowBackIcon />
            </IconButton>
            {id ? "Edit" : "Add new"} menu
          </Typography>
        </Grid>
      </Grid>
      <MenuForm />
    </RootListViewStyled>
  );
};
