import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CountCard, ToolBar } from "../../components";
import { CUSTOMER_COLUMNS } from "../../components/tables/columns";
import { adminActions, adminSelectors } from "../../state";
import { RootListViewStyled } from "./GenericPage.styles";
import { useMobile } from "../../themes"; 
import { debounce, formatNumber } from "../../lib";
import { buildParams } from "../../lib/requestBuilder";
// import { buildParams } from "../../lib/requestBuilder";

export const CustomersPage = () => {
  const dispatch = useDispatch();
  const customers = useSelector(adminSelectors.customers);
  const customersRowCount = useSelector(adminSelectors.customersRowCount);
  const customersStats = useSelector(adminSelectors.customersStats);
  const [pageSize, setPageSize] = useState(25);
  const [tableStateParams, setTableStateParams] = useState("");
  const isMobile = useMobile();
  const apiRef = useGridApiRef();

  useEffect(() => {
    if (tableStateParams) {
      dispatch(adminActions.getCustomers(tableStateParams));
    }
  }, [dispatch, tableStateParams]);

  const handleQuickSearch = (e) =>
    apiRef.current.setQuickFilterValues([e.target.value]);

  return (
    <RootListViewStyled>
      <Grid alignItems={"center"} container spacing={4}>
        <Grid item xs={12} md={3}>
          <Typography variant="h3">Customers</Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            fullWidth
            placeholder="Search for customer"
            onChange={debounce(handleQuickSearch, 300)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={4}></Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          my: 4,
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Box
          sx={{
            width: isMobile ? "100%" : 600,
            height: 100,
            textAlign: "center",
            display: "flex",
            gap: 4,
            my: 4,
          }}
        >
          <CountCard>
            <Typography variant="h4">
              {formatNumber(customersStats.activeCustomers)}
            </Typography>
            <Typography>Active customers</Typography>
          </CountCard>
          <CountCard>
            <Typography variant="h4">
              {formatNumber(customersStats.activeChildren)}
            </Typography>
            <Typography>Active children</Typography>
          </CountCard>
        </Box>
        <Box>
          <Button
            onClick={() => dispatch(adminActions.downloadUnmappedCustomers())}
            sx={{ p: 3, pb: 5, pt: 5 }}
            variant="contained"
          >
            Missing Recordings
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => dispatch(adminActions.downloadPhoneCSV())}
            sx={{ p: 3, pb: 5, pt: 5 }}
            variant="contained"
          >
            Download Phone
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => dispatch(adminActions.syncFamilyRecordings())}
            sx={{ p: 3, pb: 5, pt: 5 }}
            variant="contained"
          >
            Sync recordings
          </Button>
        </Box>
      </Box>
      <DataGridPro
        autoHeight
        apiRef={apiRef}
        rowCount={customersRowCount}
        rows={customers}
        columns={CUSTOMER_COLUMNS}
        pagination
        rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
        pageSize={pageSize}

        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          Toolbar: ToolBar,
        }}
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        onStateChange={(s, _e, _d) => setTableStateParams(buildParams(s))}
      />
    </RootListViewStyled>
  );
};
