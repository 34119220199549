import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { fontFamily } from "../StatsPage.styles";
import messageImg from "../../../assets/stats/message.png";

export const wrapper: SxProps<Theme> = {
  position: "relative",
  margin: "auto 63px 28px 0",
  height: `${(740 / 1080) * 100}%`,
  zIndex: 1,
  display: "flex",
  justifyContent: "flex-end",
};

export const outline: SxProps<Theme> = {
  position: "relative",
  height: "100%",
  boxSizing: "border-box",
  border: "3px solid #E9BF04",
  borderRadius: "30px",
  display: "flex",
};

export const progress = (value: number): SxProps<Theme> => {
  return {
    position: "relative",
    height: `calc(${value}% - 10px)`,
    width: "46px",
    borderRadius: "23px",
    margin: "5px",
    boxSizing: "border-box",
    alignSelf: "flex-end",
    background: "linear-gradient(180deg, #FDF5AA 0%, #E9BF04 100%)",
  };
};

export const level = (theme: Theme): SxProps<Theme> => {
  return {
    position: "absolute",
    right: "100px",
    color: theme.palette.primary.contrastText,
    fontFamily,
    fontSize: "26px",
    fontWeight: "bold",
    letterSpacing: "-0.08px",
    lineHeight: "33px",
    textAlign: "right",
  };
};

export const message = (value: number): SxProps<Theme> => {
  return {
    position: "absolute",
    right: "100px",
    bottom: `calc(${value}% - 10px)`,
    boxSizing: "border-box",
    height: "133px",
    width: "128px",
    backgroundImage: `url(${messageImg})`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
};

export const amount: SxProps<Theme> = {
  color: "#E9BF04",
  fontFamily,
  fontSize: "35px",
  fontWeight: "bold",
  letterSpacing: "-0.11px",
  lineHeight: "45px",
  textAlign: "center",
};

export const text = (theme: Theme): SxProps<Theme> => {
  return {
    width: "90px",
    color: theme.palette.primary.contrastText,
    fontFamily,
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "-0.05px",
    lineHeight: "20px",
    textAlign: "center",
  };
};
