import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: UIState = {
  loading: false,
  notification: undefined, //{message: "hello", variant: "success"},
  notificationModal: undefined,
  menuOpen: false,
  view: {},
  chargebeeConfig: undefined,
};

export const ui = {
  ...createSlice({
    name: "ui",
    initialState,
    reducers: {
      hideNotification(state) {
        state.notification = undefined;
      },
      hideNotificationModal(state) {
        state.notificationModal = undefined;
      },
      setLoading(state, { payload }: PayloadAction<boolean>) {
        state.loading = payload;
      },
      setView(state, { payload }: PayloadAction<Partial<UIViewState>>) {
        state.view = {
          ...state.view,
          ...payload,
        };
      },
      showNotification(state, { payload }: PayloadAction<UINotification>) {
        if (!payload.duration) {
          payload.duration = 4000;
        }
        state.notification = payload;
      },
      showNotificationModal(
        state,
        { payload }: PayloadAction<UINotificationModal>,
      ) {
        state.notificationModal = payload;
      },
      setMenuOpen(state, { payload }: PayloadAction<boolean>) {
        state.menuOpen = payload;
      },
      setChargebeeConfig(state, { payload }: PayloadAction<ChargebeeConfig>) {
        state.chargebeeConfig = payload;
      },
    },
  }),
  persist: false,
};

export interface UINotification {
  message: string;
  variant?: "error" | "warning" | "info" | "success";
  duration?: number;
}
export interface UINotificationModal {
  title: string;
  message: string;
  bulletpoints?: string[];
}

export interface ChargebeeConfig {
  public_key: string;
  site: string;
  domain: string;
}
export interface UIState {
  loading: boolean;
  notification?: UINotification;
  notificationModal;
  view: UIViewState;
  menuOpen: boolean;
  chargebeeConfig?: ChargebeeConfig;
}

export type UIViewType = boolean | string | Record<string, any> | undefined;

export interface UIViewState {
  // vehicleSpecsDialog?: UIViewType;
}
