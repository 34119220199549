import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { adminSelectors } from "../../state";
import { CountCard } from "../CountCard";
import { TabPanel } from "./TabPanel";
import CustomModal from "../modal/CustomModal";
import { formatNumber } from "../../lib";
import { useMobile } from "../../themes";
import { PointsForm } from "../forms/PointsForm";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { ToolBar } from "../tables";
import { CHILDREN_POINTS } from "../tables/columns";
interface childProps {
  totalEarned: number;
  totalUsed: number;
  value: string;
}
export const RewardsTab = ({ totalEarned, totalUsed, value }: childProps) => {
  const [openPoints, setOpenPoints] = useState(false);
  // const [openRedemption, setOpenRedemption] = useState(false);
  const currentChild = useSelector(adminSelectors.childInfo);
  const openPointsClick = () => setOpenPoints(!openPoints);
  // const openRedClick = () => setOpenRedemption(!openRedemption);
  const isMobile = useMobile();

  return (
    <TabPanel value={value} index={0}>
      <Box
        sx={{
          width: isMobile ? "100%" : "80%",
          height: 100,
          textAlign: "center",
          justifyContent: "space-between",
          display: "flex",
          gap: 4,
          my: 4,
        }}
      >
        <CountCard sx={{ marginTop: 0 }}>
          <Typography variant="h4" color={"primary"}>
            {formatNumber(totalEarned)}
          </Typography>
          <Typography>Total Earned</Typography>
        </CountCard>
        <CountCard sx={{ marginTop: 0 }}>
          <Typography variant="h4" color={"primary"}>
            {formatNumber(totalUsed)}
          </Typography>
          <Typography>Total Used</Typography>
        </CountCard>
        <CountCard sx={{ marginTop: 0 }}>
          <Typography color="success.main" variant="h4">
            {formatNumber(totalEarned - totalUsed)}
          </Typography>
          <Typography>Balance</Typography>
        </CountCard>
      </Box>

      <DataGridPro
        rows={
          Array.isArray(currentChild.children_points)
            ? currentChild.children_points
            : []
        }
        pagination
        components={{
          Toolbar: ToolBar,
        }}
        componentsProps={{
          toolbar: {
            showQuickSearch: true,
            showAddButton: true,
            label: "Give Points",
            onClick: openPointsClick,
          },
        }}
        columns={CHILDREN_POINTS}
      />

      {/**
       * TODO:
       * FIGURE OUT POINT REDEMPTIONS
       */}
      {/* {<GenericTable
        title="Redemptions"
        loopRows={currentChild.children_points}
        button={true}
        buttonTitle={"New Redemption"}
        buttonClick={openRedClick}
      />} */}
      <CustomModal open={openPoints} onClose={openPointsClick}>
        <PointsForm closeModal={openPointsClick} />
      </CustomModal>
      {/* <CustomModal open={openRedemption} onClose={openRedClick}>
        <Typography id="modal-modal-title" variant="h3" component="h2">
          Redemption
        </Typography>

        <TextField fullWidth size="small" placeholder="Prize" />
        <TextField fullWidth size="small" placeholder="Amount of points" />
        <Divider />
        <Stack
          sx={{
            mt: 2,
          }}
          display={"flex"}
          direction={"row"}
          justifyContent={"flex-end"}
          spacing={2}
        >
          <Button color="primary" variant="contained">
            cancel
          </Button>{" "}
          <Button color="secondary" variant="contained">
            save
          </Button>
        </Stack>
      </CustomModal> */}
    </TabPanel>
  );
};
