import { Modal, Box } from "@mui/material";
import { ReactNode } from "react";
import { useTablet, useMobile } from "../../themes";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  sx?: any;
}

export default function CustomModal({
  open,
  onClose,
  children,
  sx,
}: ModalProps) {
  const isTablet = useTablet();
  const isMobile = useMobile();
  const isSmallerThanPc = isTablet || isMobile;
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isSmallerThanPc ? "90%" : "40%",
          backgroundColor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          ...sx,
        }}
      >
        {children}
      </Box>
    </Modal>
  );
}
