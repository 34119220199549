import { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors } from "../../state";
import { Link } from "react-router-dom";
import AdminPages from ".";
import { Button, Typography } from "@mui/material";
import { AddIcon, ToolBar } from "../../components";
import { ActionBox, RootListViewStyled } from "./GenericPage.styles";
import { useMobile } from "../../themes";
import { USERS_COLUMNS } from "../../components/tables/columns";

export const UsersPage = () => {
  const dispatch = useDispatch();
  const users = useSelector(adminSelectors.users);
  const [pageSize, setPageSize] = useState(25);
  const isMobile = useMobile();
  useEffect(() => {
    dispatch(adminActions.getUsers());
  }, [dispatch]);

  return (
    <RootListViewStyled>
      <Typography variant="h3">Users</Typography>
      <ActionBox>
        <Link
          style={{ width: isMobile ? "100%" : undefined }}
          to={AdminPages.createUsers.path}
        >
          <Button fullWidth variant="outlined" color="primary">
            <AddIcon /> Add User
          </Button>
        </Link>
      </ActionBox>
      <DataGridPro
        rows={users}
        columns={USERS_COLUMNS}
        pagination
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          Toolbar: ToolBar,
        }}
      />
    </RootListViewStyled>
  );
};
