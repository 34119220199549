import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik, Form, FormikProps } from "formik";
import { InputField } from "../inputs/InputField";
import { yup } from "../../lib";
import {
  adminActions,
  adminSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DraggableCard } from "../DraggableCard";
import { arrayMoveImmutable } from "array-move";

const validationSchema = yup.object({
  name: yup.string().required("  Name is required"),
  // serial_id: yup.number().required("Internal serial id is required"),
});

interface SeriesVmBox {
  id: number;
  voicemail_box_id: number;
  series_id: number;
  sort: number;
  voicemail_box: {
    name: string;
    external_name: null | string;
  };
}

interface FormikValues {
  name: string;
  active: boolean;
  series_vm_boxes: SeriesVmBox[];
  current_vm_boxes: {
    id?: number;
    name: string;
    voicemail_box_id?: number;
    sort: number;
  }[];
}

type InitialValues = FormikValues | undefined;
const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
  marginTop: 20,
  height: 56,
  "& .MuiButtonBase-root.Mui-selected": {
    background: theme.palette.primary.main,
  },
  "& .MuiButtonBase-root.Mui-selected:hover": {
    background: theme.palette.primary.main,
  },
  "& .Mui-selected>.MuiTypography-root": {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

export const SeriesManageForm = () => {
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<any> | null>(null);

  const [initialValues, setInitialValues] = useState<InitialValues>({
    name: "",
    active: true,
    series_vm_boxes: [],
    current_vm_boxes: [],
  });
  const params = useParams();

  const vmBoxes = useSelector(adminSelectors.voicemailBoxes);

  useEffect(() => {
    dispatch(
      adminActions.getVoicemailBoxes("field=id&range=0&range=3000&sort=desc"),
    );
  }, [dispatch]);
  useEffect(() => {
    (async () => {
      if (params?.id) {
        const s = (await dispatch(
          adminActions.getSeriesById(+params.id),
        )) as unknown as InitialValues;
        if (s) {
          s.current_vm_boxes = s.series_vm_boxes
            .sort((a, b) => (a.sort < b.sort ? -1 : 1))
            .map((b) => ({
              id: b.id,
              voicemail_box_id: b.voicemail_box_id,
              name: b.voicemail_box.external_name || b.voicemail_box.name,
              sort: b.sort,
            }));
        }
        setInitialValues(s);
      }
    })();
  }, [dispatch]);

  const handleSubmit = async (v, { setSubmitting }) => {
    const _current_vm_boxes = v.current_vm_boxes.map((cvb, idx) => ({
      ...cvb,
      sort: idx,
    }));
    const val = { ...v, current_vm_boxes: _current_vm_boxes };
    if (params.id) {
      dispatch(adminActions.updateSeries(+params.id, val));
    } else {
      dispatch(adminActions.createSeries(val));
    }
    setSubmitting(false);
  };

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    const form = formRef.current;
    const r = formRef.current?.values?.current_vm_boxes ?? [];
    form?.setFieldValue(
      "current_vm_boxes",
      arrayMoveImmutable(r, dragIndex, hoverIndex),
    );
  }, []);

  if (!initialValues) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {({
        isSubmitting,
        values,
        setFieldValue,
      }: {
        isSubmitting: boolean;
        values: FormikValues;
        setFieldValue: any;
      }) => (
        <Form>
          <Grid sx={{}} alignItems={"center"} container spacing={4}>
            <Grid
              sx={{ width: "100%" }}
              container
              item
              xs={12}
              md={12}
              spacing={4}
            >
              <Grid item xs={3}>
                <InputField label={"Name"} name={"name"} />
              </Grid>
              <Grid item xs={3}>
                <ToggleButtonGroupStyled
                  fullWidth
                  value={values.active}
                  exclusive
                  onChange={(e, v) => {
                    if (v == undefined) return;
                    setFieldValue("active", v);
                  }}
                >
                  <ToggleButton value={true}>
                    <Typography>ACTIVE</Typography>
                  </ToggleButton>
                  <ToggleButton value={false}>
                    <Typography>INACTIVE</Typography>
                  </ToggleButton>
                </ToggleButtonGroupStyled>
              </Grid>
            </Grid>
            <Grid sx={{ width: "100%" }} item xs={12} md={12}>
              {!!vmBoxes.length && (
                <Autocomplete
                  disableCloseOnSelect
                  multiple
                  filterSelectedOptions
                  options={vmBoxes.map((vmb) => ({
                    id: undefined,
                    voicemail_box_id: vmb.id,
                    name: vmb.external_name || vmb.name,
                    sort: 0,
                  }))}
                  value={values.current_vm_boxes}
                  isOptionEqualToValue={(option, value) =>
                    option.voicemail_box_id === value.voicemail_box_id
                  }
                  onChange={(e, v) => {
                    setFieldValue("current_vm_boxes", v);
                  }}
                  getOptionLabel={(p) => {
                    return `Box ${p.voicemail_box_id} - ${p.name}`;
                  }}
                  renderInput={(params) => {
                    return <TextField {...params} label="Mailboxes" />;
                  }}
                />
              )}
              {!!vmBoxes?.length && (
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel sx={{ ml: -2, fontSize: 20, fontWeight: 500 }}>
                    Series orders
                  </InputLabel>
                  <Box
                    sx={{
                      border: "1px solid #E4E7EB",
                      padding: 2,
                      mt: 6,
                      borderRadius: 2,
                    }}
                  >
                    <DndProvider backend={HTML5Backend}>
                      {values.current_vm_boxes.map((r, idx: number) => (
                        <Grid key={idx} container spacing={2}>
                          <Grid item md={1}>
                            <Typography> Track : {idx + 1}</Typography>
                          </Grid>
                          <Grid item md={11}>
                            <DraggableCard
                              index={idx}
                              id={r.id}
                              text={r.name}
                              moveCard={moveCard}
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </DndProvider>
                  </Box>
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Button
            sx={{ mt: 3, ml: 0 }}
            color="primary"
            variant="contained"
            type="submit"
            disabled={isSubmitting}
          >
            Save
          </Button>
        </Form>
      )}
    </Formik>
  );
};
