/**
 * @file This file is used to normalize environment variables and provide
 * intellisense/autocomplete for them. Import your settings from this file
 * instead of directly from `process.env`.
 */

/** Common environment names. */
const Environments = {
  development: "development",
  production: "production",
};

/** Name of the current environment. */
const NODE_ENV = process.env.NODE_ENV || Environments.development;
/** True if the app is in development mode. */
const __DEV__ = NODE_ENV === Environments.development;
/**
 * True if the app is in production mode.
 * NOTE: We don't use Environments.production to test for this
 * because Create-React-App uses "production" for every other non-development
 * environment.
 */
const __PROD__ =
  window.location.hostname === "subscribe.circmag.com" ||
  window.location.hostname === "admin.circmag.com";
const __STAGING__ =
  window.location.hostname === "the-loop-staging.bitbean.dev" ||
  window.location.hostname === "subscribe-test.circmag.com";

const REACT_APP_BASE_API_URL = __PROD__
  ? "https://subscribe.circmag.com/"
  : __STAGING__
  ? "https://the-loop-staging.bitbean.dev/"
  : "";

const ADMIN_BASE_URL = __PROD__
  ? "https://admin.circmag.com"
  : __STAGING__
  ? "https://the-loop-staging.bitbean.dev"
  : "http://localhost:3305";

const CUSTOMER_SITE_BASE_URL = __PROD__
  ? "https://subscribe.circmag.com"
  : __STAGING__
  ? "https://subscribe-test.circmag.com"
  : "http://loop.local:3305";

const COLLAPSIBLE_DRAWER = process.env.COLLAPSIBLE_DRAWER || true;
/** Base URL of the API. */
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
/** URL to the company website. */
const REACT_APP_COMPANY_SITE_URL = process.env.REACT_APP_COMPANY_SITE_URL;
/** DSN URL for the error telemetry API. */
const REACT_APP_ERRORS_DSN = process.env.REACT_APP_ERRORS_DSN;
/** True to report errors during development. */
const REACT_APP_ERRORS_DEV = process.env.REACT_APP_ERRORS_DEV === "true";
/** Key to store redux state under localStorage. */
const REACT_APP_PERSIST_KEY = process.env.REACT_APP_PERSIST_KEY;
/** Site title string. */
const REACT_APP_SITE_TITLE = process.env.REACT_APP_SITE_TITLE;
/** Package version string from the package.json file. */
const REACT_APP_VERSION = process.env.REACT_APP_VERSION;
const REACT_APP_MUI_LICENSE = process.env.REACT_APP_MUI_LICENSE;
const CHARGEBEE_KEY = "test_MLXbzbP1m9m5WssEMDeCivG3oTxHnzjw";
export {
  __DEV__,
  __PROD__,
  NODE_ENV,
  REACT_APP_API_URL,
  REACT_APP_COMPANY_SITE_URL,
  REACT_APP_ERRORS_DSN,
  REACT_APP_ERRORS_DEV,
  REACT_APP_PERSIST_KEY,
  REACT_APP_SITE_TITLE,
  REACT_APP_VERSION,
  COLLAPSIBLE_DRAWER,
  REACT_APP_MUI_LICENSE,
  CHARGEBEE_KEY,
  REACT_APP_BASE_API_URL,
  ADMIN_BASE_URL,
  CUSTOMER_SITE_BASE_URL,
};
