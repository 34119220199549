import { useEffect, useState } from "react";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors } from "../../state";
import AdminPages from ".";
import { ToolBar } from "../../components";
import { RootListViewStyled } from "./GenericPage.styles";
import { SERIAL_COLUMNS } from "../../components/tables/columns";
import { generatePath, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { useLocation } from "../../lib";
import { AddSerialForm } from "../../components/forms/AddSerialForm";
import CustomModal from "../../components/modal/CustomModal";
//import { useMobile } from "../../themes";

export const SerialsPage = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  //const isMobile = useMobile();
  const serials = useSelector(adminSelectors.serials);
  const apiRef = useGridApiRef();
  const location = useLocation();
  const [pageSize, setPageSize] = useState(
    Number(location.query.pageSize ?? 25),
  );
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(adminActions.getSerials());
  }, [dispatch]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (pageSize) {
      params.append("pageSize", pageSize.toString());
    } else {
      params.delete("pageSize");
    }
    nav({ search: params.toString() });
  }, [pageSize]);

  const openModalClick = () => {
    setOpenModal(!openModal);
  };


  return (
    <RootListViewStyled>

      <Typography variant="h3" mb={3}>Serials</Typography>

      <DataGridPro
        autoHeight
        apiRef={apiRef}
        rows={serials}
        columns={SERIAL_COLUMNS}
        pagination
        onRowClick={(row) =>
          nav(
            generatePath(AdminPages.serialsEdit.path, {
              id: row.id.toString(),
            }),
          )
        }
        rowsPerPageOptions={[10, 25, 50, 100, 200]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          Toolbar: ToolBar,
        }}
        componentsProps={{
          toolbar: {
            showQuickSearch: true,
            showAddButton: true,
            label: "Add Serial",
            onClick: openModalClick,
          },
        }}
      />
      <CustomModal open={openModal} onClose={openModalClick}>
        <AddSerialForm closeModal={openModalClick} />
      </CustomModal>
    </RootListViewStyled>
  );
};
