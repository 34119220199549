import { useDropzone } from "react-dropzone";
import { Paper } from "@mui/material";
import { useEffect } from "react";

export const PaperDropzone = ({ filesCB }) => {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone();
  const { ref, ...rootProps } = getRootProps();
  useEffect(() => {
    filesCB(acceptedFiles);
  }, [acceptedFiles]);
  const files = acceptedFiles.map((file: any) => (
    <li key={file?.path}>
      {file?.path} - {file.size} bytes
    </li>
  ));
  return (
    <>
      <Paper
        sx={{
          height: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        ref={ref}
        {...rootProps}
      >
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </Paper>
      <ul>{files}</ul>
    </>
  );
};
