import axios from "axios";
import { differenceInDays } from "date-fns";
import { AppThunk, ChildrenPoints, Recording } from "..";
import { Navigation } from "../../lib";
import AdminPages from "../../pages/admin";
import { authActions, authClient, customerActions, uiActions } from "../states";
import { admin } from "./state";

const { actions } = admin;

export const adminActions = {
  ...actions,
  /** USERS START */
  getUsers(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/users`);
      if (status === 200) {
        dispatch(actions.setUsers(data));
      } else {
        dispatch(uiActions.showError("Get users failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getUser(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/users/` + id);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get user"));
        return false;
      } else {
        return data;
      }
    };
  },
  createUser(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(`/users`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("user saved"));
        dispatch(adminActions.getUsers());
        Navigation.go(AdminPages.users.path);
      } else {
        dispatch(uiActions.showError("failed to save user"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateUser(id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(`/users/${id}`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("user updated"));
        dispatch(adminActions.getUsers());
        Navigation.go(AdminPages.users.path);
      } else {
        dispatch(uiActions.showError("failed to save user"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteUser(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(`/users/${id}`);
      if (status === 204) {
        dispatch(uiActions.showSuccess("user deleted"));
        dispatch(adminActions.getUsers());
      } else {
        dispatch(uiActions.showError("failed to delete user"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateProfile(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(`/profile`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("Profile updated"));
        dispatch(authActions.getProfile());
        Navigation.go(AdminPages.dashboard.path);
      } else {
        dispatch(uiActions.showError("Profile update failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  /** USERS END */
  /** MENU START */
  getMenus(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/menus`);
      if (status === 200) {
        dispatch(actions.setMenus(data));
      } else {
        dispatch(uiActions.showError("Get menus failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  saveMenu(values, goBack = true): AppThunk {
    return async (dispatch, getState) => {
      if (values.app_type_id_external === 13 && values.app_type_id < 1) {
        dispatch(
          uiActions.showError("Menu_playback was selected without a serial "),
        );
      } else {
        dispatch(uiActions.setLoading(true));
        let apiCall;
        if (values.id) {
          apiCall = authClient.put(`/menus/${values.id}`, values);
        } else {
          apiCall = authClient.post(`/menus`, values);
        }

        const { status, data } = await apiCall;
        if (status === 200 || status === 204) {
          dispatch(uiActions.showSuccess("menu saved"));
          if (goBack) {
            dispatch(adminActions.getMenus());
            Navigation.go(AdminPages.menus.path);
          } else if (!values.id) {
            await dispatch(adminActions.getMenus());
            const savedMenu = getState().admin?.menus?.rows.find(
              (r) => r.internal_name === values.internal_name,
            );
            if (savedMenu && savedMenu.id) {
              Navigation.go(`${AdminPages.menus.path}/${savedMenu.id}`);
            } else {
              dispatch(uiActions.showError("save and stay failed"));
              Navigation.go(AdminPages.menus.path);
            }
          } else {
            dispatch(adminActions.getMenus());
          }
        } else if (status === 400) {
          const {
            data: { items } = { items: undefined },
            message,
            code,
          } = data || {};

          if (code === 1 || code === 2) {
            dispatch(
              uiActions.showNotificationModal({
                title: "Cannot save menu",
                message,
                bulletpoints: items,
              }),
            );
          } else {
            dispatch(uiActions.showError(data.message));
          }
        } else {
          dispatch(uiActions.showError("save menu failed"));
        }
        dispatch(uiActions.setLoading(false));
      }
    };
  },
  deleteMenu(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.delete(`/menus/${id}`);
      if (status === 204) {
        dispatch(uiActions.showSuccess("menu deleted"));
        dispatch(adminActions.getMenus());
      } else if (status === 400) {
        const {
          data: { items } = { items: undefined },
          message,
          code,
        } = data || {};

        if (code === 2) {
          dispatch(
            uiActions.showNotificationModal({
              title: "Cannot delete menu",
              message,
              bulletpoints: items,
            }),
          );
        }
      } else {
        dispatch(uiActions.showError("failed to delete menu"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getAppTypes(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/app_types`);
      if (status === 200) {
        dispatch(actions.setAppTypes(data));
      } else {
        dispatch(uiActions.showError("Get app types failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  saveAppType(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { id, ...rest } = values;

      let response;
      if (!id) {
        response = await authClient.post(`/app_types`, values);
      } else {
        response = await authClient.put(`/app_types/${id}`, rest);
      }
      if (response.status === 204) {
        dispatch(uiActions.showSuccess("app type saved"));
        dispatch(adminActions.getAppTypes());
      } else {
        dispatch(uiActions.showError("failed to save app type"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  /** MENU END */
  /** CUSTOMERS START */
  getCustomers(params: string): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/customers?${params}`);
      if (status === 200) {
        dispatch(actions.setCustomers(data));
        dispatch(adminActions.getCustomerStats());
      } else {
        dispatch(uiActions.showError("Get customers failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getCustomer(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/customer/${id}`);
      if (status === 200) {
        dispatch(actions.setCustomer(data));
      } else {
        dispatch(uiActions.showError("getCustomer failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getCustomerVoicemails(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/customer/${id}/voicemails?vm_box=99`,
      );
      if (status === 200) {
        dispatch(actions.setCustomerVoicemails(data.rows));
      } else {
        dispatch(uiActions.showError("Voicemails for customer failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getCustomerNotes(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/customer_notes/${id}`);
      if (status === 200) {
        dispatch(actions.setCustomerNotes(data));
      } else {
        dispatch(uiActions.showError("getCustomerNotes failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteCustomer(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(`/customer/${id}`);
      if (status === 204) {
        dispatch(uiActions.showSuccess("customer deleted"));
        dispatch(adminActions.getCustomers(""));
        Navigation.go(AdminPages.customers.path);
      } else {
        dispatch(uiActions.showError("failed to delete customer"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createPhoneNumber(values, mode): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const url =
        mode === "admin"
          ? `/customers/${Number(values.customer_id)}/phone`
          : "/customers/phones/mine";
      const { status } = await authClient.post(url, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("phone added"));
        if (mode === "admin") {
          dispatch(adminActions.getCustomer(values.customer_id));
        } else {
          dispatch(customerActions.getAllInfoOnLogin());
          // const { data, status } = await authClient.get(
          //   `/customers/phones/mine`,
          // );
          // if (status === 200) {
          //   dispatch(
          //     customerActions.setCustomer({
          //       customer_phones: [...data.rows],
          //     }),
          //   );
          // }
        }
      } else {
        dispatch(uiActions.showError("failed to added phone"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getCustomerPhone(id: number, mode: "customer" | "admin"): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const url =
        mode === "admin"
          ? `/customers/${Number(id)}/phone`
          : `/customers/phones/mine/${Number(id)}`;
      const { data, status } = await authClient.get(url);
      if (status === 200) {
        dispatch(actions.setCustomerPhone({ ...data }));
      } else {
        dispatch(uiActions.showError("failed to get customer phone"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateCustomerPhone(id, values, mode): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const url =
        mode === "admin"
          ? `/customers/${Number(id)}/phone`
          : `/customers/phones/mine/${id}`;
      const { status } = await authClient.put(url, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("phone updated"));
        if (mode === "admin") {
          dispatch(adminActions.getCustomer(values.customer_id));
        } else {
          dispatch(customerActions.getAllInfoOnLogin());
          // const { data, status } = await authClient.get(
          //   `/customers/phones/mine`,
          // );
          // if (status === 200) {
          //   dispatch(
          //     customerActions.setCustomer({
          //       customer_phones: [...data.rows],
          //     }),
          //   );
          // }
        }
        dispatch(adminActions.setDefaultCustomerPhone());
      } else {
        dispatch(uiActions.showError("failed to update phone"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteCustomerPhone(values, mode): AppThunk {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      const url =
        mode === "admin"
          ? `/customers/${Number(values.id)}/phone`
          : `/customers/phones/mine/${values.id}`;
      const { status } = await authClient.delete(url);
      if (status === 204) {
        if (mode === "admin") {
          dispatch(adminActions.getCustomer(values.customer_id));
        } else {
          const phones = getState().customer.customer.customer_phones;
          if (phones) {
            dispatch(
              customerActions.setCustomer({
                customer_phones: [
                  ...phones.filter(
                    (phone) => phone?.phone_number !== values.phone_number,
                  ),
                ],
              }),
            );
          }
        }
        dispatch(customerActions.getAllInfoOnLogin());
        dispatch(uiActions.showSuccess("customer phone deleted"));
      } else {
        dispatch(uiActions.showError("failed to delete customer phone"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  downloadPhoneCSV(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.download(
        `/customers/phones/loop`,
        "loop_phone.csv",
      );
      if (status !== 200) {
        uiActions.showError("Failed to download phone list");
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  downloadUnmappedCustomers(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.download(
        `/customers/rec-unmapped`,
        "loop_phone.csv",
      );
      if (status !== 200) {
        uiActions.showError("Failed to download phone list");
      }
      dispatch(uiActions.setLoading(false));
    };
  },

  createNote(customer_id: number | string, note: string): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(`/notes`, {
        customer_id,
        note,
      });
      if (status === 204) {
        dispatch(uiActions.showSuccess("note added"));

        dispatch(adminActions.getCustomer(customer_id));
        dispatch(adminActions.getCustomerNotes(customer_id));
      } else {
        dispatch(uiActions.showError("failed to add note"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateNote(note_id: number | string, note: string): AppThunk {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(`/notes/${Number(note_id)}`, {
        id: note_id,
        note,
      });
      if (status === 204) {
        dispatch(uiActions.showSuccess("note updated"));
        dispatch(adminActions.getCustomerNotes(getState().admin.customer.id));
      } else {
        dispatch(uiActions.showError("failed to add note"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteNote(id): AppThunk {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(`/notes/${Number(id)}`);
      if (status === 204) {
        dispatch(uiActions.showSuccess("note deleted"));
        dispatch(adminActions.getCustomerNotes(getState().admin.customer.id));
      } else {
        dispatch(uiActions.showError("failed to delete note"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getCustomerStats(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/customers/stats`);
      if (status === 200) {
        dispatch(actions.setStats(data));
        dispatch(uiActions.setLoading(false));
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("getCustomerStats failed"));
      }
    };
  },

  updateChargebeeCustomer(userId: number, formData): AppThunk {
    return async (dispatch, getState) => {
      const children = getState().customer.customer.customer_children;

      const data = {
        ...formData,
        customer_children: children,
      };
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(
        `/customer/update-chargebee/${Number(userId)}`,
        data,
      );
      if (status === 204) {
        const formUser = data.user;
        dispatch(
          adminActions.mergeIntoCustomer({
            first_name: formUser.first_name,
            last_name: formUser.last_name,
          }),
        );
        dispatch(uiActions.showSuccess("chargebee customer updated"));
      } else {
        dispatch(uiActions.showError("failed to update cb customer"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },

  /** CUSTOMERS END */
  /** CHILDREN START */
  getChildren(params: string): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/children?${params}`);
      if (status === 200) {
        dispatch(actions.setChildren(data));
      } else {
        dispatch(uiActions.showError("Get Children failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createChild(values, mode): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const url = mode === "admin" ? "/children" : "/customer/children/mine";
      const { status, data } = await authClient.post(url, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("child added"));
        if (mode === "admin") {
          dispatch(adminActions.getCustomer(values.customer_id));
        } else {
          dispatch(customerActions.getAllInfoOnLogin());
          // const { data, status } = await authClient.get(
          //   "/customer/children/mine",
          // );
          // if (status === 200) {
          //   dispatch(
          //     customerActions.setCustomer({
          //       customer_children: [...data.rows],
          //     }),
          //   );
          // }
        }
      } else {
        dispatch(
          uiActions.showError(
            status === 500 && data.message === "SequelizeUniqueConstraintError"
              ? "Failed to create, There is a child with same pin"
              : "failed to add child",
          ),
        );
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getChildInfo(mode, id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      let url;
      if (mode === "admin" && id) {
        url = `/children/${id}`;
      } else if (mode === "customer" && id) {
        url = `/children/mine/${id}`;
      } else {
        console.error({ mode, id });
        throw new Error("Invalid call to getChildInfo");
      }
      const { data, status } = await authClient.get(url);
      if (status === 200) {
        dispatch(actions.setChildInfo(data));
      } else {
        dispatch(uiActions.showError("getChildInfo failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateChild(id, values, mode): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const url = mode === "admin" ? `/children/${id}` : `/children/mine/${id}`;
      const { status, data } = await authClient.put(url, values);
      dispatch(adminActions.setDefaultChildInfo());
      if (status === 204) {
        dispatch(uiActions.showSuccess("child updated"));
        if (mode === "admin") {
          dispatch(adminActions.getCustomer(values.customer_id));
        } else {
          dispatch(customerActions.getAllInfoOnLogin());

          // const { data, status } = await authClient.get(
          //   "/customer/children/mine",
          // );
          // if (status === 200) {
          //   dispatch(
          //     customerActions.setCustomer({
          //       customer_children: [...data.rows],
          //     }),
          //   );
          // }
        }
        dispatch(actions.setDefaultChildInfo());
      } else {
        dispatch(
          uiActions.showError(
            status === 500 && data.message === "SequelizeUniqueConstraintError"
              ? "Failed to update, There is a child with same pin"
              : "Failed to update child",
          ),
        );
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteChild(childId, customerId, mode): AppThunk {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      const url =
        mode === "admin" ? `/children/${childId}` : `/children/mine/${childId}`;
      const { status } = await authClient.delete(url);
      if (status === 204) {
        if (mode === "admin") {
          dispatch(adminActions.getCustomer(customerId));
        } else {
          const customerChildren =
            getState().customer.customer.customer_children;
          if (customerChildren) {
            dispatch(
              customerActions.setCustomer({
                customer_children: [
                  ...customerChildren.filter((child) => child?.id !== childId),
                ],
              }),
            );
          }
        }
        dispatch(customerActions.getAllInfoOnLogin());
        dispatch(uiActions.showSuccess("child deleted"));
      } else {
        dispatch(uiActions.showError("failed to delete child"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  givePoints(id, values: ChildrenPoints): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(`/points/${id}`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("points saved"));
        Navigation.go(
          AdminPages.child.path.replace(":id/:tab", `${id}/rewards`),
        );
        dispatch(adminActions.getChildInfo("admin", id));
      } else {
        dispatch(uiActions.showError("failed to save points"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getPoints(params: string): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/points?${params}`);
      if (status === 200) {
        dispatch(actions.setPoints(data));
      } else {
        dispatch(uiActions.showError("Get Points failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  /** CHILDREN END */
  /** RECORDINGS START */
  syncChildRecordings(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.put(`/children/audio-sync`, {});
      if (status === 200) {
        dispatch(
          uiActions.showMessage("Successfully synced - " + data?.result?.count),
        );
      } else {
        dispatch(uiActions.showError("sync failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  syncFamilyRecordings(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.put(
        `/children/family-audio-sync`,
        {},
      );
      if (status === 200) {
        dispatch(
          uiActions.showMessage("Successfully synced - " + data?.result?.count),
        );
      } else {
        dispatch(uiActions.showError("sync failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getRecordings(params: string): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/recording?${params}`);
      if (status === 200) {
        dispatch(actions.setRecordings(data));
      } else {
        dispatch(uiActions.showError("Get recordings failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getRecording(id: number): AppThunk<Promise<Recording | false>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/recording/${id}`);
      if (status === 200) {
        dispatch(uiActions.setLoading(false));
        return data;
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Get recording failed"));
        return false;
      }
    };
  },
  getRecordingsLocal(params: string): AppThunk<Promise<Recording[]>> {
    return async (dispatch) => {
      const { data, status } = await authClient.get(`/recording?${params}`);
      if (status === 200) {
        return data.rows;
      } else {
        dispatch(uiActions.showError("Get recordings failed"));
        return [];
      }
    };
  },
  getSerials(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/serials`);
      if (status === 200) {
        dispatch(actions.setSerials(data));
      } else {
        dispatch(uiActions.showError("Get serials failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createSerial(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.post(`/serials`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("serial added"));
        dispatch(adminActions.getSerials());
        Navigation.go(AdminPages.serials.path);
      } else if (status === 409) {
        dispatch(uiActions.showError(data.message));
      } else {
        dispatch(uiActions.showError("failed to added serial"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getSerialById(id: number): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/serial/` + id);
      if (status === 200) {
        dispatch(actions.setSerial(data));
        dispatch(uiActions.setLoading(false));
        return data;
      } else {
        dispatch(uiActions.showError("Get serials failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteSerial(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.delete(`/serial/${id}`);
      if (status === 204) {
        dispatch(uiActions.showSuccess("serial deleted"));
        dispatch(adminActions.getSerials());
      } else if (status === 400) {
        dispatch(
          uiActions.showNotificationModal({
            title: "Cannot delete serial",
            message: data?.message,
            bulletpoints: data?.data?.items ?? {},
          }),
        );
      } else {
        dispatch(uiActions.showError("failed to delete serial"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getSerialRecordings(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/serial-recordings`);
      if (status === 200) {
        dispatch(actions.setSerialRecordings(data));
      } else {
        dispatch(uiActions.showError("Get serial recordings failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getSerialRecordingsBySerial(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/serial-recording/` + id);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(
          uiActions.showError("failed to get serial recording by serial"),
        );
        return false;
      } else {
        await dispatch(actions.setSerialRecordings(data));

        return data;
      }
    };
  },
  createSerialRecording(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(`/serial-recordings`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("serial-recording created"));
        dispatch(adminActions.getSerialRecordings());
        Navigation.go(AdminPages.serials.path);
      } else {
        dispatch(uiActions.showError("failed to create serial "));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateSerialRecording(id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(
        `/serial-recording/${id}`,
        values,
      );
      if (status === 204) {
        dispatch(uiActions.showSuccess("serial-recording updated"));
        dispatch(adminActions.getSerialRecordings());
        Navigation.go(AdminPages.serials.path);
      } else {
        dispatch(uiActions.showError("failed to save serial-recording"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteSerialRecording(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(`/serial-recording/${id}`);
      if (status === 204) {
        dispatch(uiActions.showSuccess("serial-recording deleted"));
        dispatch(adminActions.getSerialRecordings());
      } else {
        dispatch(uiActions.showError("failed to delete serial-recording"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getRecordingTypes(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/recording-types`);
      if (status === 200) {
        dispatch(actions.setRecordingTypes(data));
      } else {
        dispatch(uiActions.showError("Get recordings types failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateRecording(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { id, ...body } = values;
      const { status } = await authClient.put(`/recording/${id}`, body);
      if (status === 204) {
        dispatch(uiActions.showSuccess("recording updated"));
      } else {
        dispatch(uiActions.showError("failed to update recording"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteRecording(id, tableStateParams) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.delete(`/recording/${id}`);
      if (status === 204) {
        await dispatch(uiActions.showSuccess("recording deleted"));
        dispatch(adminActions.getRecordings(tableStateParams));
      } else if (status === 400) {
        dispatch(
          uiActions.showNotificationModal({
            title: "Cannot delete recording",
            message: data?.message,
            bulletpoints: data?.data?.items ?? {},
          }),
        );
      } else {
        dispatch(uiActions.showError("failed to delete recording"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  saveRecording(v, isBulkUpload): AppThunk {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      const { token } = getState().auth;
      const formData = new FormData();
      formData.append("file", v.file[0]);
      let url = "";
      if (isBulkUpload) {
        url = `/api/V1/recording/bulk-upload?recording_type_id=${v.recording_type_id}&name=${v.name}&label=${v.label}`;
      } else {
        url = `/api/V1/recording/api?recording_type_id=${v.recording_type_id}&name=${v.name}&label=${v.label}`;
      }
      try {
        const { status } = await axios.post(url, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        if (status === 200) {
          dispatch(adminActions.getRecordings("range=0&range=5000"));
          Navigation.go(AdminPages.recording.path);
        } else {
          dispatch(uiActions.showError("Save recording failed"));
        }
      } catch (error) {
        dispatch(uiActions.showError("Save recording failed"));
      }

      dispatch(uiActions.setLoading(false));
    };
  },
  saveRecordingUrl(v, isBulkUpload): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      let url = "";
      if (isBulkUpload) {
        url = `/recording/download-url-bulk`;
      } else {
        url = `/recording/download-url`;
      }
      const { status } = await authClient.post(url, v);
      if (status === 200) {
        Navigation.go(AdminPages.recording.path);
      } else {
        dispatch(uiActions.showError("Save recording failed"));
      }

      dispatch(uiActions.setLoading(false));
    };
  },
  bulkDeleteRecordings(recording_ids, tableStateParams) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.put(
        `/recording-bulk-delete`,
        {
          recording_ids
        },
      );
      dispatch(uiActions.setLoading(false));
      if (status === 204) {
        await dispatch(uiActions.showSuccess("recording deleted"));
        dispatch(adminActions.getRecordings(tableStateParams));
      } else if (status === 400) {
        await dispatch(
          uiActions.showNotificationModal({
            title: "Cannot delete recordings",
            message: data?.message,
            bulletpoints: data?.data?.items ?? [],
          }),
        );
        dispatch(adminActions.getRecordings(tableStateParams));
      } else {
        dispatch(uiActions.showError("failed to delete recordings"));
      }
    };
  },
  /** RECORDINGS END */
  /** LIVE SHOWS START */
  getLiveShows(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/live_shows`);
      if (status === 200) {
        dispatch(actions.setLiveShows(data));
      } else {
        dispatch(uiActions.showError("Get live shows failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getLiveShow(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/live_shows/` + id);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get live show"));
        return false;
      } else {
        return data;
      }
    };
  },
  createLiveShow(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(`/live_shows`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("live show saved"));
        dispatch(adminActions.getLiveShows());
        Navigation.go(AdminPages.liveShow.path);
      } else {
        dispatch(uiActions.showError("failed to save live show"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateLiveShow(id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(`/live_shows/${id}`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("live shows updated"));
        dispatch(adminActions.getLiveShows());
        Navigation.go(AdminPages.liveShow.path);
      } else {
        dispatch(uiActions.showError("failed to save live shows"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteLiveShow(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(`/live_shows/${id}`);
      if (status === 204) {
        dispatch(uiActions.showSuccess("live show deleted"));
        dispatch(adminActions.getLiveShows());
      } else {
        dispatch(uiActions.showError("failed to delete live show"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  /** LIVE SHOW END */
  /** VOICEMAILS START  */
  getVoicemails(params: string): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/voicemail?${params}`);
      if (status === 200) {
        dispatch(actions.setVoiceMails(data));
      } else {
        dispatch(uiActions.showError("Get voicemails failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  duplicateVoicemail(id, mailbox_id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(`/duplicate-voicemail`, {
        voicemail_id: id,
        new_mailbox: mailbox_id,
      });
      if (status === 204) {
        dispatch(uiActions.showSuccess("voicemail duplicated"));
        dispatch(adminActions.getVoicemails(""));
      } else {
        dispatch(uiActions.showError("failed to duplicate Voicemail"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  voicemailsToRecording(voicemail_ids, recording_type_id, prefix): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.post(
        `/voicemail/to-recordings`,
        {
          voicemail_ids,
          recording_type_id,
          prefix,
        },
      );
      dispatch(uiActions.setLoading(false));
      if (status === 204) {
        dispatch(uiActions.showSuccess("voicemails saved as recordings"));
      } else if (status === 500 && data?.message) {
        dispatch(
          uiActions.showError(
            "failed to save voicemail as recordings " + data.message,
          ),
        );
      } else {
        dispatch(uiActions.showError("failed to save voicemail as recordings"));
      }
    };
  },
  getVoicemailBoxes(params: string, showLoader = true): AppThunk {
    return async (dispatch) => {
      showLoader && dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/voicemail-boxes?${params}`,
      );
      if (status === 200) {
        dispatch(actions.setVoiceMailBoxes(data));
      } else {
        dispatch(uiActions.showError("Get voicemails boxes failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createVoicemailBox(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(`/voicemail-boxes`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("voicemail box added"));
        dispatch(adminActions.getVoicemailBoxes(""));
        Navigation.go(AdminPages.voicemailBoxes.path);
      } else {
        dispatch(uiActions.showError("failed to added Voicemail Box"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getVoicemailBox(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/voicemail-boxes/` + id);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get voicemail box"));
        return false;
      } else {
        dispatch(actions.setVoicemailBox(data));
        return data;
      }
    };
  },
  updateVoicemailBox(id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(`/voicemail-boxes/${id}`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("voicemail box updated"));
        dispatch(adminActions.getVoicemailBoxes(""));
        Navigation.go(AdminPages.voicemailBoxes.path);
      } else {
        dispatch(uiActions.showError("failed to update voicemail box"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateVoicemail(id, values, params): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(`/voicemail/${id}`, values);
      if (status === 204) {
        dispatch(adminActions.getVoicemails(params));
      } else {
        dispatch(uiActions.showError("failed to update voicemail"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteVoicemailBox(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(`/voicemail-boxes/${id}`);
      if (status === 204) {
        dispatch(uiActions.showSuccess("voicemail box deleted"));
        dispatch(adminActions.getVoicemailBoxes(""));
      } else {
        dispatch(uiActions.showError("failed to delete voicemail box"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  purgeVoicemailBox(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.delete(
        `/voicemail/voicemail-boxes/${id}`,
      );
      if (status === 204) {
        dispatch(uiActions.showSuccess("voicemail box purged"));
        dispatch(adminActions.getVoicemailBoxes(""));
      } else {
        dispatch(uiActions.showError(data.message || "failed to purge voicemail box"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  /** VOICEMAILS END */
  /** GAMES */
  getGames(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/games`);
      if (status === 200) {
        dispatch(actions.setGames(data));
      } else {
        dispatch(uiActions.showError("Get games failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getGame(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/games/` + id);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get game"));
        return false;
      } else {
        return data;
      }
    };
  },
  createGame(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(`/games`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("games saved"));
        dispatch(adminActions.getGames());
        Navigation.go(AdminPages.games.path);
      } else {
        dispatch(uiActions.showError("failed to save game"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateGame(id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(`/games/${id}`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("Game updated"));
        dispatch(adminActions.getGames());
        Navigation.go(AdminPages.games.path);
      } else {
        dispatch(uiActions.showError("failed to save game"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getGameRules(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/game-rules`);
      if (status === 200) {
        dispatch(actions.setGameRules(data));
      } else {
        dispatch(uiActions.showError("Get game rules failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getGameVersions(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/game/versions`);
      if (status === 200) {
        dispatch(actions.setGameVersions(data));
      } else {
        dispatch(uiActions.showError("Get game versions failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  bumpGameVersions(id: number): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(`/games/${id}/bump-version`, {});
      if (status === 204) {
        dispatch(adminActions.getGameVersions());
      } else {
        dispatch(uiActions.showError("bump game versions failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getGameRulesByGameId(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/game-rules/` + id);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get game rules"));
        return false;
      } else {
        return data;
      }
    };
  },
  createGameRules(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(`/game-rules`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("games rule saved"));
        dispatch(adminActions.getGameRules());
        Navigation.go(AdminPages.game_rules.path);
      } else {
        dispatch(uiActions.showError("failed to save game"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateGameRules(id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(`/game-rules/${id}`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("Game rules updated"));
        dispatch(adminActions.getGameRules());
        Navigation.go(AdminPages.game_rules.path);
      } else {
        dispatch(uiActions.showError("failed to save game rules"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  /** GAMES END */
  /** STATS */
  getStats(startDate: Date, endDate: Date): AppThunk {
    return async (dispatch) => {
      const diff = differenceInDays(endDate, startDate);
      if (diff > 7) {
        dispatch(
          uiActions.showError(
            "Maximum date range is 7 days. Please adjust range.",
          ),
        );
        return;
      }
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/stats?start_date=${encodeURIComponent(
          startDate.toISOString(),
        )}&end_date=${encodeURIComponent(endDate.toISOString())}`,
      );
      if (status === 200) {
        dispatch(
          actions.setStats({
            ...data.stats,
          }),
        );
      } else {
        dispatch(uiActions.showError("Get Stats failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getExtensionStats(
    startDate: Date,
    endDate: Date,
    location: string,
  ): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/extension-stats?start_date=${encodeURIComponent(
          startDate.toISOString(),
        )}&end_date=${encodeURIComponent(
          endDate.toISOString(),
        )}&location=${location}`,
      );
      if (status === 200) {
        dispatch(actions.setMenuStats(data.menuStats));
      } else {
        dispatch(uiActions.showError("Get Stats failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  /** STATS END */
  /** ALIASES */
  getAliases(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/aliases`);
      if (status === 200) {
        dispatch(actions.setAliases(data));
      } else {
        dispatch(uiActions.showError("Get aliases failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createAlias(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.post(`/aliases`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("alias added"));
        dispatch(adminActions.getAliases());
        Navigation.go(AdminPages.alias.path);
      } else if (status === 409) {
        dispatch(uiActions.showError("Alias Not Added." + data.message));
      } else {
        dispatch(uiActions.showError("failed to added alias"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getAlias(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/aliases/` + id);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get alias"));
        return false;
      } else {
        dispatch(actions.setAlias(data));
        return data;
      }
    };
  },
  updateAlias(id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.put(`/aliases/${id}`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("alias updated"));
        dispatch(adminActions.getAliases());
        Navigation.go(AdminPages.alias.path);
      } else if (status === 409) {
        dispatch(uiActions.showError("Alias Not Updated. " + data.message));
      } else {
        dispatch(uiActions.showError("failed to update alias"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteAlias(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(`/aliases/${id}`);
      if (status === 204) {
        dispatch(uiActions.showSuccess("Alias deleted"));
        dispatch(adminActions.getAliases());
      } else {
        dispatch(uiActions.showError("failed to delete alias"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  /** ALIASES END */
  /**REPORTS */
  getReports(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/reports`);
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get reports"));
      } else {
        dispatch(actions.setReports(data));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getReportById(id: number): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/reports/${id}`);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get reports"));
        return { data: [], name: "Report failed" };
      } else {
        dispatch(actions.setReports(data));
        return data;
      }
    };
  },
  /**REPORTS END */
  /**SERIES START */
  getSeries(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/series`);
      if (status === 200) {
        dispatch(actions.setSeries(data));
      } else {
        dispatch(uiActions.showError("Get series failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getSeriesById(id: number): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/series/${id}`);
      dispatch(uiActions.setLoading(false));
      if (status === 200) {
        return data;
      } else {
        dispatch(uiActions.showError("Get series by id failed"));
        return undefined;
      }
    };
  },
  createSeries(values: Record<string, any>): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(`/series`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("series saved"));
        dispatch(adminActions.getSeries());
        Navigation.go(AdminPages.series.path);
      } else {
        dispatch(uiActions.showError("failed to save series"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateSeries(id: number, values: Record<string, any>): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(`/series/${id}`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("series updated"));
        dispatch(adminActions.getSeries());
        Navigation.go(AdminPages.series.path);
      } else {
        dispatch(uiActions.showError("failed to save series"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  /** SERIES END */
  /** RAFFLES START */
  getRaffles(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/raffles`);
      if (status === 200) {
        dispatch(actions.setRaffles(data));
      } else {
        dispatch(uiActions.showError("Get raffles failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createRaffle(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.post(`/raffles`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("raffle added"));
        dispatch(adminActions.getRaffles());
        Navigation.go(AdminPages.raffles.path);
      } else if (status === 409) {
        dispatch(uiActions.showError(data.message));
      } else {
        dispatch(uiActions.showError("failed to added raffle"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getRaffle(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/raffles/` + id);
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to get raffle"));
        return false;
      } else {
        dispatch(actions.setRaffle(data));
        return data;
      }
    };
  },
  updateRaffle(id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(`/raffles/${id}`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("raffle updated"));
        dispatch(adminActions.getRaffles());
        Navigation.go(AdminPages.raffles.path);
      } else {
        dispatch(uiActions.showError("failed to update raffle"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteRaffle(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.delete(`/raffles/${id}`);
      if (status === 204) {
        dispatch(uiActions.showSuccess("Raffle deleted"));
        dispatch(adminActions.getRaffles());
      } else {
        dispatch(uiActions.showError("failed to delete raffle"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  /** RAFFLES END */
  /** ADMIN EMAILS START */
  accountEmail(values: { subscription_id: number }): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(`/subscription/account-email`, values);

      if (status === 200) {
        dispatch(uiActions.showSuccess("Email Sent"));

      } else {
        dispatch(uiActions.showError("Failed To Send Email"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  /** ADMIN EMAILS END */
};
