import { Box, Grid, Typography } from "@mui/material";
import AdminPages from ".";
import { AddButton, ToolBar } from "../../components";
import { RootListViewStyled } from "./GenericPage.styles";
import { LIVE_SHOW_COLUMNS } from "../../components/tables/columns";
import { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  adminActions,
  adminSelectors,
  useDispatch,
  useSelector,
} from "../../state";
export const LivesShowsPage = () => {
  const [pageSize, setPageSize] = useState(25);
  const dispatch = useDispatch();
  const shows = useSelector(adminSelectors.liveShows);

  useEffect(() => {
    dispatch(adminActions.getLiveShows());
  }, [dispatch]);
 
  return (
    <RootListViewStyled>
      <Grid alignItems={"center"} container spacing={4}>
        <Grid item xs={12} md={3}>
          <Typography variant="h3">Live Shows</Typography>
        </Grid>

        <Grid item xs={12} md={9} display="flex" justifyContent="flex-end">
          <Box sx={{ width: "15%" }}>
            <AddButton label={"Show"} to={AdminPages.liveShowCreate.path} />
          </Box>
        </Grid>
      </Grid>
      <DataGridPro
        rows={shows}
        columns={LIVE_SHOW_COLUMNS}
        disableSelectionOnClick
        pagination
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          Toolbar: ToolBar,
        }}
      />
    </RootListViewStyled>
  );
};
