import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { REACT_APP_PERSIST_KEY } from "../config";
import { admin } from "./admin/state";
import { auth } from "./auth/state";
import { customer } from "./customer/state";
import { ui } from "./ui/state";
import { stats } from "./stats/state";

let persistor;

// Need this to solve issue with redux-persist
// https://github.com/reduxjs/redux-toolkit/pull/175
const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});
const states = [auth, ui, admin, customer, stats];
const statesPrepped = states.reduce(
  (prev, curr) => {
    prev.reducers[curr.name] = curr.reducer;
    if (curr.persist) {
      prev.whitelist.push(curr.name);
    }

    return prev;
  },
  { reducers: {}, whitelist: [] as string[] },
);

export const rootReducer = combineReducers(statesPrepped.reducers);
const persistConfig = {
  devTools: process.env.NODE_ENV !== "production",
  key: REACT_APP_PERSIST_KEY || "app-persist",
  storage,
  whitelist: statesPrepped.whitelist,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// if (process.env.NODE_ENV === "development" ) {
//   module.hot.accept("./state/rootReducer", () => {
//     const newRootReducer = persistReducer(
//       persistConfig,
//       require("./state").rootReducer,
//     );
//     store.replaceReducer(newRootReducer);
//   });
// }

export const store = configureStore({
  reducer: persistedReducer,
  middleware: customizedMiddleware,
});

export default () => {
  function activatingStore(resolve, _reject) {
    function persistStoreCompleted() {
      resolve(store);
    }
    persistor = persistStore(store, null, persistStoreCompleted);
    // crossTabSync(store, persistConfig);
  }
  const hydrated = new Promise(activatingStore);
  return { store, persistor, hydrated };
};
