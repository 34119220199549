import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDispatch, adminActions } from "../../state";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { ArrowBackIcon, ModalJSONReportView, ToolBar } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { RootListViewStyled } from "./GenericPage.styles";
import { datagridColType } from "../../lib";

function JSONPopup(props) {
  const [showDialog, setShowDialog] = useState(false);
  return (
    <>
      <Button
        color="secondary"
        variant="outlined"
        onClick={() => setShowDialog(true)}
      >
        Show Data
      </Button>
      <ModalJSONReportView
        {...props}
        showDialog={showDialog}
        setShowDialog={setShowDialog}
      />
    </>
  );
}

export default function ReportView() {
  const params = useParams();
  const [pageTitle, setPageTitle] = useState("");
  const [noRowsReason, setNoRowsReason] = useState("");
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = React.useState(25);
  const [reportData, setReportData] = useState<any>([]);
  const [columns, setColumns] = useState<any>([
    { field: "id", headerName: "ID", width: 90 },
  ]);
  const nav = useNavigate();
  const returnToReportsPage = () => {
    nav("/admin/reports");
  };

  useEffect(() => {
    (async () => {
      if (!params.id) {
        return;
      }
      const res: any = await dispatch(adminActions.getReportById(+params.id));
      const { data, name } = res;
      setPageTitle("Report Data | " + name);
      if (!data.length) return;
      const columns = Object.keys(data[0]).map((c) => {
        const type = datagridColType(data[0][c]);
        return {
          field: [c],
          headerName: [c],
          width: 120,
          type: type !== "object" && type,
          sortable: type !== "object",
          filterable: type !== "object",
          valueGetter: ({ value }) => {
            return type === "dateTime" ? new Date(value) : value;
          },
          renderCell: (props) => {
            return typeof props.value === "object" && !!props.value ? (
              <JSONPopup {...props} />
            ) : props["field"][0] === "child_id" && !!props.value ? (
              <a
                target={"_blank"}
                href={`/admin/children/${props.value}/rewards`}
                rel="noreferrer"
              >
                {props.value}
              </a>
            ) : props["field"][0] === "customer_id" && !!props.value ? (
              <a
                target={"_blank"}
                href={`/admin/customers/${props.value}/general`}
                rel="noreferrer"
              >
                {props.value}
              </a>
            ) : (
              <span>{props.value}</span>
            );
          },
        };
      });
      setColumns(columns);
      // check all id's are unique
      const set = new Set(data.map((d) => d.id));
      if (set.size === data.length) setReportData(data);
      else {
        setReportData([]);
        setNoRowsReason("It's possible that there are duplicate id's ");
      }
    })();
  }, [dispatch, params.id]);

  return (
    <RootListViewStyled>
      <Typography variant="h3" mb={3}>
        <IconButton onClick={returnToReportsPage}>
          <ArrowBackIcon />
        </IconButton>
        {pageTitle}
      </Typography>
      {reportData.length ? (
        <DataGridPro
          autoHeight
          rows={reportData}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          components={{
            Toolbar: ToolBar,
          }}
        />
      ) : (
        <Box display={"flex"} justifyContent={"center"}>
          <Typography fontSize={24} fontWeight={"bold"} color={"forestgreen"}>
            {noRowsReason.trim() !== ""
              ? noRowsReason
              : "There no rows to report"}
          </Typography>
        </Box>
      )}
    </RootListViewStyled>
  );
}
