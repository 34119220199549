import { Box } from "@mui/material";
import { useLocation } from "../../lib";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: string | number;
  sx?: any;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, sx, index } = props;
  const location = useLocation();
  return (
    <Box
      role="tabpanel"
      hidden={value !== location.pathname}
      height="100%"
      width="100%"
      padding={0}
      marginTop={2}
      sx={{ ...sx }}
    >
      {(value === location.pathname || Number(value) === index) && (
        <>{children}</>
      )}
    </Box>
  );
};
