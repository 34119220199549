import { Grid, Typography } from "@mui/material";
import { ReactNode } from "react";
import { useMobile } from "../../themes";
interface Props {
  img: string;
  alt: string;
  caption: string;
  children: ReactNode;
  noPadding?: boolean;
}
export const FlowSuccess = ({
  img,
  children,
  alt,
  caption,
  noPadding = false,
}: Props) => {
  const isMobile = useMobile();
  return (
    <>
      <Grid
        container
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={isMobile ? 1 : 4}
        width={"100%"}
        gridTemplateRows={"2fr 1fr"}
        gridTemplateColumns={"2fr 1fr"}
        sx={{ backgroundColor: "#FFF", p: noPadding ? "0px" : 2 }}
      >
        <Grid
          item
          gap={isMobile ? 4 : 6}
          xs={10}
          md={8}
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img width={isMobile ? "100%" : "350px"} alt={alt} src={img} />
          <Typography
            variant={isMobile ? "h4" : "h2"}
            fontWeight={"bold"}
            textAlign={"center"}
          >
            {caption}
          </Typography>
        </Grid>
        <Grid
          item
          xs={10}
          gap={isMobile ? 2 : 4}
          padding={noPadding ? "0px" : isMobile ? 1 : 2}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {children}
        </Grid>
      </Grid>
    </>
  );
};
