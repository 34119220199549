import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { ActionBox } from "../../pages/admin/GenericPage.styles";
import { AddButton } from "../buttons/AddButton";
import { SplitButton } from "../buttons/SplitButton";

export const ToolBar = ({
  showQuickSearch = false,
  showSavePref = false,
  showAddButton = false,
  showSelected = false,
  amountSelected = "",
  actionOptions = [],
  onSelectAction = (_i) => {},
  actionsDisabled = false,
  actionsTooltip = "",
  label = "",
  to = "",
  onClick,
}) => {
  return (
    <GridToolbarContainer>
      <Grid
        container
        spacing={2}
        alignItems={"end"}
        justifyContent={"space-between"}
      >
        <Grid
          container
          spacing={2}
          alignItems={"center"}
          justifyContent={"flex-start"}
          item
          sm={6}
        >
          {" "}
          {showQuickSearch && (
            <Grid item>
              <GridToolbarQuickFilter
                variant={"outlined"}
                size={"small"}
                sx={{
                  marginBottom: "0px !important",
                  marginTop: "0px !important",
                }}
              />
            </Grid>
          )}
          {showSelected && (
            <Grid item>
              <Typography> {amountSelected} items selected</Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          spacing={2}
          alignItems={"end"}
          justifyContent={"flex-end"}
          item
          sm={6}
        >
          {showAddButton && (
            <Grid item>
              <ActionBox sx={{ position: "inherit" }}>
                <AddButton
                  label={label}
                  to={to}
                  onClick={onClick}
                  variant="text"
                ></AddButton>
              </ActionBox>
            </Grid>
          )}
          <Grid item>
            <GridToolbarExport />
          </Grid>

          {showSavePref && (
            <Grid item>
              <Button
              // onClick={() => {
              //   console.log("Clicked");
              //   console.log(apiRef.current.exportState());
              // }}
              >
                Save Current State
              </Button>
            </Grid>
          )}
          {actionOptions.length > 0 && (
            <Grid item>
              <Tooltip title={actionsTooltip}>
                <Box>
                  <SplitButton
                    options={actionOptions}
                    onSelect={(index) => {
                      onSelectAction(index);
                    }}
                    disabled={actionsDisabled}
                    label="Actions"
                  />
                </Box>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};
