import {
  Grid,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { yup } from "../../lib";
import {
  adminActions,
  adminSelectors,
  Alias,
} from "../../state";
import { InputField } from "../inputs/InputField";
interface Props {
  closeModal: () => void;

}
export const AliasForm = ({ closeModal }: Props) => {

  const [editMode, setEditMode] = useState(false);

  const validationSchema = yup.object({
    original_name: yup.string().required('original name is required'),
    alias: yup.string().required("Alias is required"),
  });

  const _initialValues = {
    original_name: "",
    alias: ""
  } as Partial<Alias>;

  const [initialValues, setInitialValues] = useState(_initialValues);

  const dispatch = useDispatch();
  const alias = useSelector(adminSelectors.alias);
  useEffect(() => {
    (async () => {

      if (alias.id) {
        setInitialValues({ ...alias });
        setEditMode(true);
      } else {
        setEditMode(false);
      }
    })();
  }, [dispatch, alias]);

  const handleSubmit = (values, { setSubmitting }) => {
    if (editMode) {
      dispatch(adminActions.updateAlias(alias.id, values));
    } else {
      dispatch(adminActions.createAlias(values));
    }
    setSubmitting(false);
    closeModal();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting}) => (
        <Form

        >
          <Grid
            sx={{
              width: "100%",
              border: "1px solid #E4E7EB",
              borderRadius: 2,
              m: 1,
              mt: 2,
              p: 2,
            }}
            display={"flex"}
            flexDirection="column"
            container
          >
            <Typography variant="h6">
              {editMode ? "Edit Alias" : "Add Alias"}
            </Typography>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <InputField label={"Original Name"} name={"original_name"} />
            </Grid>

            <Grid item xs={12}>
              <InputField label={"Alias"} name={"alias"} />
            </Grid>
          </Grid>
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <Button
              sx={{ mt: 3, ml: 3, mr: 0 }}
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Save Alias
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
