import { Typography, Link } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { authActions, authSelectors } from "../state";

export const ImpersonateBanner = () => {
  const dispatch = useDispatch();
  const impersonating = useSelector(authSelectors.impersonating);
  const { first_name, last_name } = useSelector(authSelectors.userInfo);

  const handleExitImpersonate = async () => {
    await dispatch(authActions.removeImpersonate());
  };
  return (
    <>
      {impersonating && (
        <Typography
          sx={{
            width: "100vw",
            position: "fixed",
            top: 0,
            textAlign: "center",
            p: 1,
            backgroundColor: "info.main",
            zIndex: 9999999,
          }}
          variant="h5"
        >
          Logged in as {first_name} {last_name}
          <Link
            onClick={handleExitImpersonate}
            style={{ marginLeft: 20, cursor: "pointer" }}
          >
            EXIT
          </Link>
        </Typography>
      )}
    </>
  );
};
