import React from "react";
import { Box, Button, Typography, Link } from "@mui/material";
import { useLocation, RouterLink } from "../../lib";
import { ContainerStyle } from "./Auth.styles";
import { authActions, useAction } from "../../state";
import { useMobile } from "../../themes";

export const RecoverySentPage = React.memo(
  /**
   *
   */
  function RecoverySentPage() {
    const location = useLocation();
    const recoverPassword = useAction(authActions.recoverPassword);
    const verifyAccount = useAction(authActions.verifyAccount);
    const resendForgotEmail = (_e) => {
      recoverPassword({ email: location.query.email });
    };
    const resendVerifyEmail = (_e) => {
      verifyAccount({ email: location.query.email });
    };
    const isMobile = useMobile();
    return (
      <ContainerStyle>
        <Box>
          <Typography
            variant="h3"
            gutterBottom
            textAlign={isMobile ? "center" : "justify"}
            pt={isMobile ? 4 : 0}
          >
            Email sent
          </Typography>
          <Typography variant="body1" gutterBottom>
            If {location.query.email || "email provided"} is a valid user's
            email, an email has been sent to {location.query.verify_account ? "verify your account" : "reset your password"} .
          </Typography>
          <div>
            <Button
              variant="contained"
              type="button"
              fullWidth
              onClick={location.query.verify_account ? resendVerifyEmail : resendForgotEmail}
              sx={{
                mt: 5,
                mb: 2,
              }}
            >
              Resend email
            </Button>
          </div>
          <Typography variant="body2">
            If it doesn't arrive soon check your spam folder
          </Typography>
          <Box mt={2}>
            <Link component={RouterLink} to={`/auth/login`}>
              <Typography variant="body2"> Back to login </Typography>
            </Link>
          </Box>
        </Box>
      </ContainerStyle>
    );
  },
);
