import { createSelector } from "reselect";
import { AppState } from "../types";
export interface StatInfo {
  amount: number | undefined;
  text: string;
}

const _state = (state: AppState) => state.stats;

const _allStats = createSelector(_state, (state) => {
  return {
    book: [
      {
        amount: state.stats.book.find((t) => t.country === "ALL")?.total,
        text: "Active customers",
      },
    ],
    circle: [
      {
        amount: state.stats.circle.find((t) => t.country === "ALL")?.total,
        text: "Active customers",
      },
    ],
    loop: [
      {
        amount: state.stats.loop.find((t) => t.country === "ALL")?.total,
        text: "Active customers",
      },
    ],
  };
});

export const statsSelectors = {
  stats(state: AppState) {
    return state.stats;
  },
  allStats: _allStats,
};
