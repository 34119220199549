import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { SubscribeLayout } from "../../layouts/main/SubscribeLayout";
import { CustomerGeneralTab } from "../tabs/CustomerGeneralTab";
import { useMobile } from "../../themes";
import { useSelector } from "react-redux";
import { customerSelectors } from "../../state";
import { MainPages } from "../../pages/main";
export const ManageLoop = () => {
  const nav = useNavigate();
  const isMobile = useMobile();
  const params = useParams();
  const loopSubscriptions = useSelector(customerSelectors.loopSubscriptions);
  const last_name =
    loopSubscriptions.find((s) => s.id == params.subscription_id)
      ?.shipping_last_name ?? "";

  return (
    <>
      <SubscribeLayout
        title={`Manage ${last_name} Family’s Loop account`}
        backAction={() => nav(MainPages.loopDash.path)}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          width={"100%"}
          alignItems={"center"}
          height={"100%"}
          gap={isMobile ? 1 : 4}
        >
          <CustomerGeneralTab
            mode="customer"
            value={`/manage-loop/${params.subscription_id}`}
          />
        </Box>
      </SubscribeLayout>
    </>
  );
};
