import { Button, Box } from "@mui/material";
import { AddIcon } from "../icons";
import { useNavigate, To } from "react-router-dom";

interface Props {
  label: string;
  to?: To;
  onClick?: () => void;
  variant?: "text" | "outlined" | "contained" | undefined;
}

export const AddButton = ({
  label,
  onClick: _onClick,
  to,
  variant: _variant = "contained",
}: Props) => {
  if (!_onClick && !to) {
    throw new Error("You must send a to or onClick method");
  }
  const navigate = useNavigate();

  const onClick = to ? () => navigate(to) : _onClick;
  return (
    <Button onClick={onClick} fullWidth color="primary" variant={_variant}>
      <AddIcon />
      <Box component={"span"} sx={{ ml: 1}}>
        {label}
      </Box>
    </Button>
  );
};
