import {
  Box,
  Button,
  Divider,
  Grid,
  Portal,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import { Autocomplete, InputField } from "../inputs/InputField";
import { parsePhone, yup } from "../../lib";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { customerSelectors, adminActions, adminSelectors } from "../../state";
import { useMobile } from "../../themes";
import { CountryCodes } from "../../lib/CountryCodes";
import { useEffect, useRef, useState } from "react";
import { states } from "../../lib/States";
import { CountryList } from "../../lib/countries";
import { CustomerGeneralTab } from "../tabs/CustomerGeneralTab";

const validationSchema = yup.object({
  first_name: yup
    .string()
    .matches(
      /^[A-Za-z ]*$/,
      "If your name contains numbers please contact customer service",
    ),
  last_name: yup
    .string()
    .matches(
      /^[A-Za-z ]*$/,
      "If your name contains numbers please contact customer service",
    ),
  country_code: yup.string().required("Country Code Required"),
  phone: yup
    .string()
    .matches(/^[0-9]*$/, "A phone number should only include numbers")
    .min(7, "phone number should be at least 7 digits long"),
  email: yup.string(),
  billing_country: yup
    .string()
    .matches(/^[A-Za-z ]*$/, "Country should only include letters")
    .required("Country is Required"),
  billing_address_one: yup.string(),
  billing_address_two: yup.string(),
  billing_city: yup
    .string()
    .matches(/^[A-Za-z ]*$/, "City should only include letters"),
  billing_state: yup
    .string()
    .matches(/^[A-Za-z ]*^.{2,2}$/, "State should only include letters"),
  billing_zip: yup.string(),
  billing_first_name: yup
    .string()
    .matches(
      /^[A-Za-z ]*$/,
      "If your name contains numbers please contact customer service",
    ),
  billing_last_name: yup
    .string()
    .matches(
      /^[A-Za-z ]*$/,
      "If your name contains numbers please contact customer service",
    ),
  entryStatus: yup.string(),
});

export const CustomerForm = () => {
  const adminCustomer = useSelector(adminSelectors.customer);
  const customerVoicemails = useSelector(adminSelectors.customerVoicemails);

  const _initialValues = {
    first_name: "",
    last_name: "",
    country_code: "",
    phone: "",
    email: "",
    billing_address_one: "",
    billing_address_two: "",
    billing_city: "",
    billing_state: "",
    billing_zip: "",
    billing_first_name: "",
    billing_last_name: "",
    billing_country: "",
    entryStatus: "incomplete",
  };

  const [initialValues, setInitialValues] = useState(_initialValues);
  const [clicked, setClicked] = useState(false);

  const isMobile = useMobile();

  useEffect(() => {
    const customerPhone = adminCustomer.customer_phones.find(
      (cp) => cp.number_type === "customer",
    );
    const firstPhone = adminCustomer.customer_phones[0];
    const countryCode =
      customerPhone?.country_code || firstPhone?.country_code || "";
    const country_code =
      countryCode !== "" && countryCode.substring(0, 1) !== "+"
        ? `+${countryCode}`
        : countryCode;
    setInitialValues({
      first_name: adminCustomer.first_name || "",
      last_name: adminCustomer.last_name || "",
      country_code,
      phone: customerPhone?.phone_number || firstPhone?.phone_number || "",
      email: adminCustomer.user?.email || "",
      billing_address_one: adminCustomer.address || "",
      billing_address_two: adminCustomer.address_2 || "",
      billing_city: adminCustomer.city || "",
      billing_state: adminCustomer.state ?? "",
      billing_zip: adminCustomer.zip || "",
      billing_first_name: adminCustomer.first_name || "",
      billing_last_name: adminCustomer.last_name || "",
      billing_country: adminCustomer.country || "",
      entryStatus: adminCustomer.data_entry_status ?? "incomplete",
    });
  }, [adminCustomer]);

  const { country } = adminCustomer;
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { userId, customerId } = useParams();

  const _customerInfo = useSelector(customerSelectors.customer);
  const [customerInfo, setCustomerInfo] = useState(_customerInfo);
  const countries = [
    { label: "United States", value: "US" },
    { label: "Canada", value: "CA" },
    { label: "United Kingdom", value: "GB" },
    { label: "Israel", value: "IL" },
    ...CountryList,
  ];
  useEffect(() => {
    if (clicked) {
      setCustomerInfo(() => _customerInfo);
    }
  }, [clicked]);
  useEffect(() => {
    dispatch(adminActions.getCustomer(customerId));
    dispatch(adminActions.getCustomerVoicemails(customerId));
  }, [dispatch]);

  const portalRef = useRef(null);
  const handleSubmit = (v) => {
    setClicked(() => true);
    const {
      email,
      first_name,
      last_name,
      phone,
      country_code,
      billing_zip,
      billing_state,
      entryStatus,
      billing_country,
      ...rest
    } = v;
    const country =
      CountryCodes.find((c) => c.country_code === country_code)?.country ?? "";
    const data = {
      customer_children: customerInfo.customer.customer_children,
      customer_phones: customerInfo.customer.customer_phones,
      user: {
        email,
        first_name,
        last_name,
        phone: parsePhone(phone, country),
      },
      billing_country,
      billing_zip,
      billing_state,
      data_entry_status: entryStatus,
      id: Number(customerId),
      country_code,
      ...rest,
    };

    dispatch(adminActions.updateChargebeeCustomer(Number(userId), data));

    if (data) {
      nav({
        pathname: `/admin/customers/${adminCustomer.id}/general`,
      });
    }
  };
  const getFile = (cidName: string) =>
    customerVoicemails.find((v) => v.cid_name === cidName)?.full_url;

  return (
    <>
      {
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form
              onBlur={() =>
                setFieldValue(
                  "entryStatus",
                  !values.entryStatus ? "incomplete" : values.entryStatus,
                )
              }
            >
              <Grid
                container
                width={"100%"}
                display={"flex"}
                alignItems={"center"}
              >
                <Grid item xs={12}>
                  <Typography id="customer-details-title" variant="h3">
                    Customer Details
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display={"flex"}
                  flexDirection={isMobile ? "column" : "row"}
                  gap={isMobile ? 0 : 8}
                >
                  <InputField label="First name" name="first_name" />
                  <InputField label="Last name" name="last_name" />
                </Grid>{" "}
                {getFile("FamilyName") && (
                  <Grid
                    item
                    xs={3}
                    display={"flex"}
                    flexDirection={isMobile ? "column" : "row"}
                    ml={2}
                  >
                    <audio
                      preload="none"
                      controls
                      src={getFile("FamilyName")}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography variant="h3">Contact</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  flexDirection={isMobile ? "column" : "row"}
                  gap={isMobile ? 0 : 8}
                >
                  {" "}
                  <Grid item xs={6}>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      flexDirection={isMobile ? "column" : "row"}
                      gap={isMobile ? 0 : 8}
                    >
                      <Autocomplete
                        label={"Country Code"}
                        options={CountryCodes}
                        render={(option) => {
                          return ` ${option?.flag} ${option.label} ${option.country_code}`;
                        }}
                        optionLabel={(option) => option?.flag}
                        equal={(option, value) =>
                          option.country_code === value ||
                          option.country_code === "+" + value
                        }
                        fieldName="country_code"
                        optionValue="country_code"
                      />
                      <InputField
                        label="phone"
                        name="phone"
                        checkForAutoComplete={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display={"flex"}
                  flexDirection={isMobile ? "column" : "row"}
                  gap={isMobile ? 0 : 8}
                >
                  <InputField label="email" name="email" type="email" />
                </Grid>{" "}
                {getFile("Email") && (
                  <Grid
                    item
                    xs={3}
                    display={"flex"}
                    flexDirection={isMobile ? "column" : "row"}
                    ml={2}
                  >
                    <audio controls src={getFile("Email")} />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography variant="h3">Billing Address</Typography>{" "}
                </Grid>
                <Grid
                  item
                  xs={6}
                  display={"flex"}
                  flexDirection={isMobile ? "column" : "row"}
                  gap={isMobile ? 0 : 8}
                >
                  <InputField label="First name" name={"billing_first_name"} />
                  <InputField label="Last name" name={"billing_last_name"} />
                </Grid>
                {getFile("Address") && (
                  <Grid
                    item
                    xs={3}
                    display={"flex"}
                    flexDirection={isMobile ? "column" : "row"}
                    ml={2}
                  >
                    <audio preload="none" controls src={getFile("Address")} />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Grid
                    item
                    xs={6}
                    display={"flex"}
                    flexDirection={isMobile ? "column" : "row"}
                    gap={isMobile ? 0 : 8}
                  >
                    <InputField
                      label="Address line 1"
                      name="billing_address_one"
                    />
                    <InputField
                      label="Address line 2"
                      name="billing_address_two"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    item
                    xs={6}
                    display={"flex"}
                    flexDirection={isMobile ? "column" : "row"}
                    gap={isMobile ? 0 : 8}
                  >
                    <InputField
                      label="City"
                      name="billing_city"
                      checkForAutoComplete={true}
                    />

                    <Autocomplete
                      label={"Country"}
                      options={countries}
                      render={(option) => {
                        return `${option.value}`;
                      }}
                      optionLabel={(option) => option?.value?.toUpperCase()}
                      equal={(option, value) =>
                        option.value === value?.toUpperCase()
                      }
                      fieldName={"billing_country"}
                      optionValue={"value"}
                    />

                    <Autocomplete
                      label={"State"}
                      options={states
                        .filter(
                          (c) =>
                            c.country?.toUpperCase() ===
                            values["billing_country"]?.toUpperCase(),
                        )
                        .map((s) => {
                          return {
                            code: s?.code ? s.code.toUpperCase() : "",
                          };
                        })}
                      render={(option) => {
                        return `${option.code.toUpperCase()}`;
                      }}
                      optionLabel={(option) => option.code?.toUpperCase() ?? ""}
                      equal={(option, value) => option.code === value.code}
                      fieldName={"billing_state"}
                      optionValue={"code"}
                    />
                    <InputField
                      label={`${country === "CA" ? " Postal " : " Zip "} code`}
                      name="billing_zip"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Box ref={portalRef} />
                </Grid>
                <Portal container={portalRef.current}>
                  {/*this will need another look , this is assuming admins don't mess up*/}
                  {/* <SubscriberInfoForm
                    mode="admin"
                    clicked={clicked}
                    inAdminSection={true}
                    formIsValid={true}
                    setFormIsValid={() => true}
                  /> */}
                  <CustomerGeneralTab
                    value={`/admin/customer/${userId}/${customerId}`}
                    mode="admin"
                  />
                </Portal>
                <Grid
                  display={"flex"}
                  flexDirection={"column"}
                  item
                  xs={6}
                  gap={2}
                >
                  {[1, 2, 3, 4].map((num) => {
                    const child = `ChildName${num}`;
                    if (getFile(child)) {
                      return (
                        <Grid item xs={12} ml={2} key={num}>
                          <audio preload="none" controls src={getFile(child)} />
                        </Grid>
                      );
                    }
                  })}{" "}
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Grid
                    item
                    xs={6}
                    gap={2}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Typography variant="h3">Data Entry Status</Typography>
                    <ToggleButtonGroup
                      color="primary"
                      sx={{ width: isMobile ? "100%" : "50%", height: "100%" }}
                      value={values.entryStatus}
                      defaultValue={values.entryStatus}
                      exclusive={true}
                      onChange={(_e, v) => {
                        !!v && setFieldValue("entryStatus", v);
                      }}
                      aria-label="entryStatus"
                    >
                      <ToggleButton
                        value={"complete"}
                        sx={{ width: "50%", height: "50%" }}
                      >
                        Complete
                      </ToggleButton>
                      <ToggleButton
                        value={"incomplete"}
                        sx={{ width: "50%", height: "50%" }}
                      >
                        Incomplete
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  width={"100%"}
                  alignItems={"flex-end"}
                >
                  <Divider
                    sx={{
                      width: "100%",
                    }}
                  />
                  <Button
                    sx={{ my: 2, ml: 0 }}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      }
    </>
  );
};
