import { FC } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import {
  wrapper,
  outline,
  progress,
  level,
  message,
  amount,
  text,
} from "./Gauge.styles";
interface Props {
  value: number;
}

const Gauge: FC<Props> = ({ value }) => {
  const theme = useTheme();

  return (
    <Box sx={wrapper}>
      <Typography sx={level(theme)}>100%</Typography>
      <Typography sx={level(theme)} style={{ bottom: 0 }}>
        0%
      </Typography>

      <Box sx={message(value)}>
        <Typography sx={amount}>{value}%</Typography>
        <Typography sx={text(theme)}>of goal reached</Typography>
      </Box>

      <Box sx={outline}>
        <Box sx={progress(value)} />
      </Box>
    </Box>
  );
};

export default Gauge;
