import React from "react";
import {
  Box,
  //
  Button,
  TextField,
  Typography,
} from "@mui/material";
//Assets
// Local
import { Form, Navigation, useFormik, useLocation, yup } from "../../lib";
import { authActions, useAction } from "../../state";
import AuthPages from ".";
import { ContainerStyle, FormStyle, TextFlexStyle } from "./Auth.styles";
const validationSchema = yup.object({
  password1: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  password2: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const ConfirmAccountPage = React.memo(
  /**
   *
   */
  function ConfirmAccountPage() {
    const location = useLocation();
 const resetPassword = useAction(authActions.resetPassword);


 const frm = useFormik({
   initialValues: {
     password1: "",
     password2: "",
   },
   validationSchema,
   async onSubmit(values) {
     await resetPassword({
       newPassword: values.password1,
       token: location.query.token,
     });
     Navigation.replace(AuthPages.login);
   },
 });

    return (
      <>
        <ContainerStyle>
          <Box>
            <Typography variant="h3" gutterBottom>
              Create a new password
            </Typography>
            <Typography variant="body1">
              Create a new password for your account.
            </Typography>
            <FormStyle>
              <Form form={frm}>
                <TextFlexStyle>
                  <TextField
                    fullWidth
                    id="password1"
                    name="password1"
                    label="New password"
                    type="password"
                    autoComplete="new-password"
                    value={frm.values.password1}
                    onChange={frm.handleChange}
                    error={
                      frm.touched.password1 && Boolean(frm.errors.password1)
                    }
                    helperText={frm.touched.password1 && frm.errors.password1}
                  />
                </TextFlexStyle>
                <TextFlexStyle>
                  <TextField
                    fullWidth
                    id="password2"
                    name="password2"
                    label="Repeat new password"
                    type="password"
                    autoComplete="reenter-new-password"
                    value={frm.values.password2}
                    onChange={frm.handleChange}
                    error={
                      frm.touched.password2 && Boolean(frm.errors.password2)
                    }
                    helperText={frm.touched.password2 && frm.errors.password2}
                  />
                </TextFlexStyle>

                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  disabled={frm.isSubmitting}
                >
                  SignUp
                </Button>
              </Form>
            </FormStyle>
          </Box>
        </ContainerStyle>
      </>
    );
  },
);
