import { Typography } from "@mui/material";
import { RootListViewStyled } from "./GenericPage.styles";
import { CHILDREN_POINTS_PAGE } from "../../components/tables/columns";
import { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  adminActions,
  adminSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { ToolBar } from "../../components";
import { AliasForm } from "../../components/forms/AliasForm";
import CustomModal from "../../components/modal/CustomModal";
import { buildParams } from "../../lib/requestBuilder";

export const PointsPage = () => {
  const [pageSize, setPageSize] = useState(25);
  const [openModal, setOpenModal] = useState(false);
  const [tableStateParams, setTableStateParams] = useState("");

  const dispatch = useDispatch();
  const points = useSelector(adminSelectors.points);
  const openModalClick = () => {
    setOpenModal(!openModal);
    dispatch(adminActions.setDefaultAlias());
  };
  const openModalEdit = async (id) => {
    setOpenModal(!openModal);
    await dispatch(adminActions.getAlias(id));
  };
  useEffect(() => {
    dispatch(adminActions.getPoints(tableStateParams));
  }, [dispatch, tableStateParams]);

  return (
    <RootListViewStyled>
      <Typography variant="h3" mb={3}>
        Points
      </Typography>

      <DataGridPro
        rows={points.rows}
        columns={CHILDREN_POINTS_PAGE}
        pagination
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowCount={points.count}
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        onStateChange={(s, _e, _d) => setTableStateParams(buildParams(s))}
        onRowClick={(v) => openModalEdit(v.id)}
        components={{
          Toolbar: ToolBar,
        }}
        componentsProps={{
          toolbar: {
            showQuickSearch: true,
            showAddButton: false,
          },
        }}
      />
      <CustomModal open={openModal} onClose={openModalClick}>
        <AliasForm closeModal={openModalClick} />
      </CustomModal>
    </RootListViewStyled>
  );
};
