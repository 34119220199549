import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminPages from ".";
import { CountCard, ToolBar } from "../../components";
import { CHILDREN_COLUMNS } from "../../components/tables/columns";
import { debounce, formatNumber } from "../../lib";
import { buildParams } from "../../lib/requestBuilder";
import { adminActions, adminSelectors } from "../../state";
import { useMobile } from "../../themes";
import { RootListViewStyled } from "./GenericPage.styles";
export const ChildrenPage = () => {
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const apiRef = useGridApiRef();
  const children = useSelector(adminSelectors.children);
  const childrenStats = useSelector(adminSelectors.customersStats);
  const [pageSize, setPageSize] = useState(25);
  const [tableStateParams, setTableStateParams] = useState("");
  const childrenRowCount = useSelector(adminSelectors.childrenRowCount);

  useEffect(() => {
    if (tableStateParams) {
      dispatch(adminActions.getChildren(tableStateParams));
    }
    dispatch(adminActions.getCustomerStats());
  }, [dispatch, tableStateParams]);
  const nav = useNavigate();
  const rowClicked = (row) => {
    nav(
      AdminPages.child.path
        .replace(":id", row.id + "")
        .replace(":tab", "rewards"),
    );
  };
  const handleQuickSearch = (e) =>
    apiRef.current.setQuickFilterValues([e.target.value]);

  return (
    <RootListViewStyled>
      <Grid alignItems={"center"} container spacing={4}>
        <Grid item xs={12} md={3}>
          <Typography variant="h3" fontWeight={"bold"}>
            Children
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            fullWidth
            placeholder="Search for children"
            onChange={debounce(handleQuickSearch, 300)}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          my: 4,
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Box
          sx={{
            width: isMobile ? "100%" : 600,
            height: 100,
            textAlign: "center",
            display: "flex",
            gap: 4,
            my: 4,
          }}
        >
          <CountCard>
            <Typography variant="h4">
              {formatNumber(childrenStats.activeCustomers)}
            </Typography>
            <Typography>Active customers</Typography>
          </CountCard>
          <CountCard>
            <Typography variant="h4">
              {formatNumber(childrenStats.activeChildren)}
            </Typography>
            <Typography>Active children</Typography>
          </CountCard>
        </Box>
        <Box>
          <Button
            onClick={() => dispatch(adminActions.syncChildRecordings())}
            sx={{ p: 4 }}
            variant="contained"
          >
            Sync recordings
          </Button>
        </Box>
      </Box>
      <DataGridPro
        autoHeight
        apiRef={apiRef}
        rows={children}
        columns={CHILDREN_COLUMNS}
        pagination
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onRowClick={rowClicked}
        components={{
          Toolbar: ToolBar,
        }}
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        onStateChange={(s, _e, _d) => setTableStateParams(buildParams(s))}
        rowCount={childrenRowCount}
      />
    </RootListViewStyled>
  );
};
