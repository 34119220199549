import {
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import { InputField } from "../inputs/InputField";
import { yup } from "../../lib";
import { adminActions, useDispatch } from "../../state";

const validationSchema = yup.object({
  name: yup.string().required("name is required"),
});
type Prop = { closeModal: () => void };

export const AddSerialForm = ({ closeModal }: Prop) => {
  const dispatch = useDispatch();
  const initialValues = {
    name: "",
  };

  const handleSubmit = (values) => {
    dispatch(adminActions.createSerial(values));
    closeModal();
  };


  return (
    <>
      <Typography id="modal-modal-title" variant="h3" component="h2">
        Add New Serial
      </Typography>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid
              sx={{
                width: "100%",
                border: "1px solid #E4E7EB",
                borderRadius: 2,
                m: 1,
                mt: 2,
                p: 2,
              }}
              display={"flex"}
              flexDirection="column"
              container
            >

              <Grid item xs={12}>
                <InputField label={"Name"} name={"name"} />
              </Grid>
            </Grid>
            <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
              <Button
                sx={{ mt: 3, ml: 3, mr: 0 }}
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
