import { Box, Dialog, Divider, IconButton } from "@mui/material";
import { DialogContent } from "@mui/material";
import { Button } from "@mui/material";
import { DialogContentText, useTheme } from "@mui/material";
import { DialogActions } from "@mui/material";
import React, { ReactNode } from "react";
import { Delete } from "@mui/icons-material";
interface ConfirmDialogProps {
  handleConfirm: () => void;
  description?: string;
  color?:
    | "inherit"
    | "error"
    | "default"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning";
  confirmText?: string;
  icon?: ReactNode;
}

export function ConfirmDeleteDialog(props: ConfirmDialogProps) {
  const [open, setOpen] = React.useState(false);
  const { palette } = useTheme();
  const handleConfirm = () => {
    props.handleConfirm();
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={() => {
          setOpen(true);
        }}
        color={props.color ?? "error"}
        size="small"
      >
        {props.icon ?? <Delete />}
      </IconButton>
      <Dialog open={open} sx={{ "& .MuiDialog-paper": { boxShadow: "none" } }}>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            fontWeight={"medium"}
          >
            <Box component={"span"} fontSize={20}>
              {props.description ?? "Are you sure you want to delete this?"}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider sx={{ display: "flex", alignSelf: "center", width: "90%" }} />
        <DialogActions sx={{ px: 4, py: 2 }}>
          <Button
            variant="outlined"
            color="inherit"
            sx={{
              border: "none",
              "&:hover": {
                backgroundColor: "#E4E7F5",
              },
              backgroundColor: "#E4E7F5",
              color: palette.secondary.contrastText,
            }}
            onClick={() => {
              setOpen(false);
            }}
            autoFocus
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            sx={{
              backgroundColor: palette.primary.main,
              color: palette.primary.contrastText,
            }}
          >
            {props.confirmText ?? "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
