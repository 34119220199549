import React, { useEffect, useState } from "react";
import { Grid, Button, Card, CardContent } from "@mui/material";
import { authSelectors, useDispatch, useSelector } from "../../state";
import { TextField } from "formik-mui";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { adminActions } from "../../state";
import { RootStyled, GridStyled } from "./UserPage.styles";

const validationSchema = yup.object({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const ProfilePage = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(authSelectors.userInfo);
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    roles: [],
  });

  useEffect(() => {
    const { first_name, last_name, email } = userInfo;
    setInitialValues({ ...initialValues, first_name, last_name, email });
  }, [userInfo]);

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(adminActions.updateProfile(values));
    setSubmitting(false);
  };

  return (
    <RootStyled>
      <Card>
        <CardContent sx={{ pt: 6 }}>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form>
                <Grid container>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      id="first_name"
                      name="first_name"
                      label="First Name"
                      error={touched.first_name && Boolean(errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                    />
                  </GridStyled>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      name="last_name"
                      label="Last Name"
                      required
                      error={touched.last_name && Boolean(errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                    />
                  </GridStyled>
                </Grid>

                <Grid container>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      type="email"
                      name="email"
                      label="Email address"
                      disabled={true}
                    />
                  </GridStyled>
                </Grid>

                <Grid container justifyContent="flex-end">
                  <GridStyled
                    item
                    sx={{ display: "flex", justifyContent: "end" }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </GridStyled>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </RootStyled>
  );
};

export default ProfilePage;
