import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CardInfo,
  CustomerSubscribingInfo,
  ProductInfo,
  ProductType,
  Subscriptions,
  CBSubscription,
} from "../types";

export interface CustomerState {
  customer: Partial<CustomerSubscribingInfo>;
  exist?: boolean;
  active?: boolean;
  has_password?: boolean;
  product_selected: ProductType[];
  return_customer_products?: ProductType[];
  product_available: ProductInfo[];
  customer_cards: CardInfo[];
  subscriptions: Subscriptions[];
  provider_customer_id: string;
  cb_customer_subscription?: CBSubscription[];
  renewing?: {
    isRenewing?: boolean;
    plan_name?: string;
    plan_id?: string;
  };
}
const defaultState = {
  customer: {
    customer_children: [],
    customer_phones: [],
  },
  exist: false,
  active: false,
  has_password: false,
  product_selected: [],
  return_customer_products: [],
  product_available: [],
  customer_cards: [],
  subscriptions: [],
  provider_customer_id: "",
  renewing: { isRenewing: false },
};
const initialState: CustomerState = defaultState;

export const customer = {
  ...createSlice({
    name: "customer",
    initialState,
    reducers: {
      setCustomerInfo(
        state,
        { payload }: PayloadAction<Partial<CustomerState>>,
      ) {
        const customerState = {
          ...state,
          ...payload,
          customer: { ...state.customer, ...payload.customer },
          product_available:
            payload.product_available ?? state.product_available ?? [],
        };
        return customerState;
      },
      setCustomer(
        state,
        { payload }: PayloadAction<CustomerState["customer"]>,
      ) {
        const _customer = { ...state.customer, ...payload };
        state.customer = _customer;
      },
      setProductSelected(
        state,
        { payload }: PayloadAction<CustomerState["product_selected"]>,
      ) {
        state.product_selected = payload;
      },
      setSubscriptionInfo(state, { payload }: PayloadAction<any>) {
        state.subscriptions = payload.subscriptions;
        state.provider_customer_id =
          payload.provider_customer?.provider_customer_id;
      },
      setReturnCustomerProducts(
        state,
        { payload }: PayloadAction<CustomerState["return_customer_products"]>,
      ) {
        state.return_customer_products = payload;
      },
      setProductAvailable(
        state,
        { payload }: PayloadAction<CustomerState["product_available"]>,
      ) {
        state.product_available = payload;
      },
      setCustomerCards(
        state,
        { payload }: PayloadAction<CustomerState["customer_cards"]>,
      ) {
        state.customer_cards = payload;
      },
      setRenewing(
        state,
        { payload }: PayloadAction<CustomerState["renewing"]>,
      ) {
        state.renewing = payload;
      },
      setCBCustomerSubscription(
        state,
        { payload }: PayloadAction<CustomerState["cb_customer_subscription"]>,
      ) {
        state.cb_customer_subscription = payload;
      },
      setDefaultState() {
        return defaultState;
      },
    },
  }),
  //TODO: decide if this is worth it
  persist: true,
};
