import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Store from "./state/store";
import { authActions } from "./state";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { REACT_APP_MUI_LICENSE } from "./config";
import { defaultTheme } from "./themes";
import { App } from "./App";
import * as Sentry from "@sentry/react";
import {
  ErrorSentryFallBack,
  init,
  setUser,
} from "./components/errors/ErrorSentry";
import { PersistGate } from "redux-persist/integration/react";
LicenseInfo.setLicenseKey(REACT_APP_MUI_LICENSE!);
const { store, persistor, hydrated } = Store();

async function main() {
  await hydrated;
  const dispatch = store.dispatch;
  dispatch(authActions.load({}));
  init();
  const state: any = store.getState();
  if (state.auth?.user) {
    setUser({ email: state.auth?.user?.email, id: state.auth?.user?.id });
  }
  // ReactGA.initialize("UA-190162613-1");
  // setTimeout(() => {
  //   const location = window.location;
  //   const page = `${location.pathname}${location.search}${location.hash}`;
  //   ReactGA.set({ page });
  //   ReactGA.pageview(page);
  // }, 2000);
  render();
  registerServiceWorker();
}

function registerServiceWorker() {
  // Example to implement register service worker:
  // serviceWorker.register(serviceWorkerConfig);
}
function render() {
  const container = document.getElementById("root");
  const root = createRoot(container!);
  root.render(
    <ThemeProvider theme={defaultTheme}>
      <Sentry.ErrorBoundary fallback={ErrorSentryFallBack} showDialog>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <CssBaseline />
              <App />
            </PersistGate>
          </Provider>
        </LocalizationProvider>
      </Sentry.ErrorBoundary>
    </ThemeProvider>,
  );
}

main();
