import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from "axios";
import qs from "query-string";
import { KEY_PREFIX } from "redux-persist";
import {
  CUSTOMER_SITE_BASE_URL,
  REACT_APP_API_URL,
  REACT_APP_PERSIST_KEY,
} from "../../config";
import { auth, AuthState } from "./state";
import {
  getQueryParam,
  isAdminOrEmployee,
  Navigation,
  timeoutAsync,
} from "../../lib";
import { AppThunk, Roles } from "../types";
import { uiActions } from "../ui/actions";
import { customerActions } from "../states";
const { actions } = auth;
import { store } from "../../state";
import { MainPages } from "../../pages/main";
//import React from "react";
/** Connection used to make authorized, authenticated API calls. */
export let apiClient: AxiosInstance;
interface AuthResponse {
  user?: { id: number; roles: Roles[]; email: string };
  token?: string;
  id?: number;
}

export const authActions = {
  ...actions,
  /** @param {any} [authResponseData] Response data to load. Optional. */
  load(authResponseData: AuthResponse): AppThunk {
    let authStateFromResponse: AuthState;
    if (authResponseData) {
      const { user, token } = authResponseData;
      if (user) {
        authStateFromResponse = {
          userId: user.id,
          roles: user.roles,
          token,
          userName: user.email,
        };
      }
    }
    return async (dispatch, getState) => {
      let authState: AuthState;
      if (authStateFromResponse) {
        authState = authStateFromResponse;
        dispatch(actions.setAuthState(authState));
      } else {
        authState = getState().auth;
      }
      createApiClient(authState.token);
    };
  },
  login(values: {
    email: string;
    password: string;
  }): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      try {
        const {
          data: { user, token },
        } = await axios.post(`/auth/login`, values, {
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        });

        const authState: AuthState = {
          userId: user.id,
          roles: user.roles,
          token,
          userName: user.email,
          user,
        };
        createApiClient(authState.token);
        dispatch(actions.setAuthState(authState));
        if (isAdminOrEmployee(user.roles)) {
          Navigation.replace(getQueryParam("after") || "/admin");
        } else if (user.roles.includes("customer")) {
          Navigation.go(MainPages.loopDash.path);
        }
        dispatch(uiActions.setLoading(false));
        return true;
      } catch (e) {
        let message: string = "Oops something went wrong";

        if (axios.isAxiosError(e)) {
          const code = e.response?.data.code;
          message =
            code === 403
              ? "The email or password you entered is incorrect. Please try again."
              : code === 405
                ? "Too many login attempts. Please try later"
                : "Oops something went wrong";
        }
        dispatch(uiActions.showError(message));
        dispatch(uiActions.setLoading(false));
        return false;
      }
    };
  },
  loginWithToken(token: string) {
    return async (dispatch) => {
      if (!token) {
        return;
      }
      await logout(dispatch);
      createApiClient(token);
      //Do this in case there already in an instance
      apiClient.defaults.headers["Authorization"] = `Bearer ${token}`;
      dispatch(authActions.getProfile());
      dispatch(actions.setImpersonateFlag(true));
      Navigation.go(MainPages.loopDash.path);
    };
  },
  getProfile(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const {
        status,
        data: { user, token },
      } = await apiClient.get(`/profile`);
      if (status === 200) {
        const authState: AuthState = {
          userId: user.id,
          roles: user.roles,
          token,
          userName: user.email,
          user,
        };
        dispatch(actions.setAuthState(authState));
      } else {
        dispatch(uiActions.showError("failed to get user"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  logout(): AppThunk {
    return async (dispatch) => {
      logout(dispatch);
    };
  },
  recoverPassword(values: { email: string }): AppThunk {
    return async (dispatch) => {
      await Promise.resolve();
      await logout(dispatch);
      await axios.post(`/auth/password/forgot`, values, {
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      });
    };
  },
  verifyAccount(values: { email: string }): any {
    return async (dispatch) => {
      try {
        await Promise.resolve();
        await logout(dispatch);
        await axios.post(`/auth/email/verify`, values, {
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        });
        return true;
      } catch (e) {
        let message: any = "Oops something went wrong";

        if (axios.isAxiosError(e)) {
          const code = e.response?.data.code;
          message =
            code === 405
              ? "<span>An account with this email already exists. <a href = '/auth/login' > Sign in </a> instead</span >"
              : "Oops something went wrong";
        }
        dispatch(uiActions.showError(message));
        return false;
      }

    };
  },
  resetPassword(values: { newPassword: string; token: string }): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      await Promise.resolve();
      try {
        await axios.put(`/auth/password/reset`, values, {
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        });
        dispatch(uiActions.showSuccess("Password successfully set"));
        setTimeout(async () => {
          await logout(dispatch);
          dispatch(uiActions.setLoading(false));
        }, 500);
      } catch (e) {
        dispatch(uiActions.showError("Oops Something went wrong"));
      }
    };
  },
  confirmAccount(values: {
    email: string;
    newPassword: string;
    token: string;
  }): AppThunk {
    return async (dispatch) => {
      await axios.put(`/auth/confirm-account`, values, {
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      });
      dispatch(uiActions.showMessage("confirmAccount"));
      // dispatch(actions.setAuthState(undefined));
    };
  },
  removeImpersonate(): AppThunk {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      const oldAuth = getState().auth.oldAuth;
      if (oldAuth?.user) {
        const user = oldAuth?.user;
        const authState: AuthState = {
          userId: user.id,
          roles: oldAuth.roles,
          token: oldAuth.token,
          userName: user.email,
          user,
        };
        createApiClient(authState.token);
        dispatch(actions.setImpersonate(undefined));
        dispatch(uiActions.setLoading(false));
      } else {
        dispatch(actions.clearAuth());
        dispatch(customerActions.setDefaultState());
        window.close();
      }
    };
  },
  impersonate(userId?: number): AppThunk {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      const oldAuth = getState().auth.oldAuth;
      if (!userId && oldAuth?.user) {
        const user = oldAuth?.user;
        const authState: AuthState = {
          userId: user.id,
          roles: oldAuth.roles,
          token: oldAuth.token,
          userName: user.email,
          user,
        };
        createApiClient(authState.token);
        dispatch(actions.setImpersonate(undefined));
        dispatch(uiActions.setLoading(false));
        setTimeout(() => {
          Navigation.go("/admin");
        }, 500);
        return;
      }
      const {
        status,
        data: { user, token },
      } = await apiClient.get(`/impersonate/user/${userId}`);
      if (status === 200) {
        const authState: AuthState = {
          userId: user.id,
          roles: user.roles,
          token,
          userName: user.email,
          user,
        };
        if (user.roles.includes("customer")) {
          // window.open(
          //   `${CUSTOMER_SITE_BASE_URL}/products/selection?impersonate_token=${token}`,
          // );
          window.open(
            `${CUSTOMER_SITE_BASE_URL}/${MainPages.loopDash.path}?impersonate_token=${token}`,
          );
        } else {
          createApiClient(authState.token);
          dispatch(actions.setImpersonate(authState));
        }
        dispatch(uiActions.setLoading(false));
      } else {
        dispatch(uiActions.showError("failed to impersonate user"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
};

/** Client for making authenticated API calls. */
export const authClient = {
  delete(url: string, config?: AxiosRequestConfig) {
    return handleAuthResponse((apiClient) => apiClient.delete(url, config));
  },
  download(url: string, fileName: string, config?: AxiosRequestConfig) {
    return handleAuthResponse((apiClient) =>
      apiClient
        .get(url, { method: "GET", responseType: "blob", ...config })
        .then((response) => {
          if (response.status !== 200) {
            console.error("Error downloading file");
            return response;
          }
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
          return response;
        }),
    );
  },
  get<T = any>(url: string, config?: AxiosRequestConfig) {
    return handleAuthResponse((apiClient) => apiClient.get<T>(url, config));
  },
  post<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return handleAuthResponse((apiClient) =>
      apiClient.post<T>(url, data, config),
    );
  },
  put<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return handleAuthResponse((apiClient) =>
      apiClient.put<T>(url, data, config),
    );
  },
};

function createApiClient(token?: string) {
  const config: AxiosRequestConfig = {
    baseURL: REACT_APP_API_URL,
    headers: {},
  };
  if (!config.headers) {
    throw new Error("Invalid Api Config");
  }
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    console.warn("create API client called - Missing auth token");
  }
  config.headers["Content-Type"] = "application/json";
  apiClient = axios.create(config);
  apiClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.error(error?.request?.responseURL, {
        status: error?.response?.status ?? 500,
        data: error?.response?.data ?? {},
      });
      return {
        status: error?.response?.status ?? 500,
        data: error?.response?.data ?? {},
      };
    },
  );
}
let refreshWait = 100;
/**
 * @param promise
 */
async function handleAuthResponse<T = any>(
  promise: (apiClient: AxiosInstance) => Promise<AxiosResponse<T>>,
  retrying = false,
): Promise<AxiosResponse<T>> {
  let error: AxiosError<T> | undefined;
  const promiseWithCatch = promise(apiClient).catch((err) => {
    return err.response;
  });
  const res = await promiseWithCatch;
  if (error) {
    console.error({ error, res });
  }
  if (res.status && res.status === 401) {
    const dispatch: any = store.dispatch;
    if (!retrying) {
      refreshWait += refreshWait + 200;
      console.warn("Calling refresh token", refreshWait);
      if (refreshWait > 30000) {
        console.error("refreshWait too high");
        await logout(dispatch);
        window.location.href = "/auth/logout";
      }
      const response = await authClient.get("/auth/refresh-token");
      if (response.status === 200) {
        await dispatch(authActions.load(response.data));
        await timeoutAsync(refreshWait);
        return handleAuthResponse(promise, true);
      } else {
        // if refresh token is also expired
        await logout(dispatch);
      }
    } else {
      await logout(dispatch);
      redirectToLogin();
    }
  }
  return res;
}
export function redirectToLogin() {
  //  window.localStorage.removeItem(`${KEY_PREFIX}${REACT_APP_PERSIST_KEY}`);
  setTimeout(() => {
    window.location.href =
      "/auth/login?after=" +
      encodeURIComponent(window.location.pathname + window.location.search);
  }, 1000);
}

async function logout(dispatch) {
  // NOTE: We could do  window.localStorage.clear(); but other JS might be
  // using localStorage, so just remove the key that our Redux app saves.
  window.localStorage.removeItem(`${KEY_PREFIX}${REACT_APP_PERSIST_KEY}`);
  await authClient.get("/auth/logout").catch(console.error);
  dispatch(actions.setAuthState(undefined));
  dispatch(customerActions.setDefaultState());
}

/**
 * Serializes URL params correctly for `express-openapi-validator`. See:
 * - https://github.com/axios/axios/issues/678#issuecomment-634632500
 * - https://github.com/axios/axios/blob/8a8c534a609cefb10824dec2f6a4b3ca1aa99171/lib/helpers/buildURL.js
 * - https://github.com/axios/axios/blob/59ab559386273a185be18857a12ab0305b753e50/lib/utils.js#L177
 *
 * @param params The query params.
 */
function serializeParams(params: Record<string, any>) {
  if (params instanceof URLSearchParams) {
    return params.toString();
  }
  const formattedParams = {};
  const keys = Object.keys(params);
  const { length } = keys;
  for (let i = 0; i < length; i++) {
    const key = keys[i];
    let value = params[key];
    if (value === null || value === undefined) {
      continue;
    }
    if (Object.prototype.toString.call(value) === "[object Date]") {
      // Format Dates...
      value = value.toISOString();
    } else if (value !== null && typeof value === "object") {
      // Format objects and arrays...
      value = JSON.stringify(value);
    }
    formattedParams[key] = value;
  }
  // URLSearchParams does not handle arrays...
  // return new URLSearchParams(formattedParams).toString();
  return qs.stringify(formattedParams);
}
axios.defaults.paramsSerializer = serializeParams;

// #region Types

/** Return value for API call wrappers. */
export type ApiCall<T = any> = AppThunk<Promise<AxiosResponse<T>>>;

// #endregion
