import * as React from "react";
import AdminPages from "../../pages/admin";
import { useLocation } from "react-router-dom";
import {
  Box,
  Menu as MUIMenu,
  MenuItem,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ProfileMenu from "./ProfileMenu";
import { authSelectors, Page, useSelector } from "../../state";
import { history } from "../../lib/routing/Navigation";
import { DescriptionIcon } from "../../components";
import { useMobile } from "../../themes";

const Icon = (props) => {
  const { icon } = props;
  return React.createElement(icon);
};

const adminPage = (key: string): Page => {
  return AdminPages[key];
};

const highlightMenuItem = (menuUrl, url) => {
  if (menuUrl === "/admin") {
    return url === menuUrl;
  }
  return url.includes(menuUrl);
};

export default function Menu() {
  const location = useLocation();
  const roles = useSelector(authSelectors.roles);
  const isMobile = useMobile();

  const [activeMenu, setActiveMenu] = React.useState<
    undefined | Record<string, boolean>
  >();
  const [anchorEls, setAnchorEls] = React.useState<null | Record<
    string,
    HTMLElement
  >>(null);
  const handleMouseEnter = (
    event: React.MouseEvent<HTMLElement>,
    activeMenuName: string,
  ) => {
    const _menuActive = { ...activeMenu, [activeMenuName]: true };
    if (event) {
      setActiveMenu(_menuActive);
      setAnchorEls({ ...anchorEls, [activeMenuName]: event.currentTarget });
    }
  };
  const handleClose = (
    e?: React.MouseEvent<HTMLElement> | null,
    activeMenuName?: string,
  ) => {
    if (activeMenuName) {
      setActiveMenu({ ...activeMenu, [activeMenuName]: false });
    } else {
      setActiveMenu(undefined);
    }
  };

  const checkRoles = (key: string): boolean => {
    if (roles.includes("super_admin")) {
      return true;
    }

    if (adminPage(key).allowedRoles === undefined) {
      //No Roles on page allow everyone
      return true;
    }
    if (!roles.length) {
      return false;
    }
    return !!adminPage(key).allowedRoles?.filter((r) => roles.includes(r))
      .length;
  };

  const subMenusByParent = Object.keys(AdminPages).reduce((prev, curr) => {
    const currPage = adminPage(curr);
    if (currPage.subMenuOf) {
      if (!Array.isArray(prev[currPage.subMenuOf])) {
        prev[currPage.subMenuOf] = [];
      }
      prev[currPage.subMenuOf].push(curr);
    }
    return prev;
  }, {});

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box>
          <List>
            {Object.keys(AdminPages)
              .filter((key) => adminPage(key).menuItemOrder)
              .filter((key) => (isMobile ? true : !adminPage(key).subMenuOf))
              .filter(checkRoles)
              .sort(
                (a, b) =>
                  (adminPage(a).menuItemOrder ?? 0) -
                  (adminPage(b)?.menuItemOrder ?? 0),
              )
              .map((p, index) => {
                const page: Page = AdminPages[p];
                return (
                  <Box key={index}>
                    <ListItem
                      onClick={() => history.push(page.path)}
                      button
                      onMouseEnter={(e) => handleMouseEnter(e, p)}
                      // onMouseLeave={(e) => handleClose(e, p)}
                    >
                      <ListItemIcon
                        sx={{
                          color: highlightMenuItem(page.path, location.pathname)
                            ? "primary.main"
                            : undefined,
                        }}
                      >
                        <Icon icon={page.icon ?? DescriptionIcon} />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          sx={{
                            color: highlightMenuItem(
                              page.path,
                              location.pathname,
                            )
                              ? "primary.main"
                              : undefined,
                            fontWeight: highlightMenuItem(
                              page.path,
                              location.pathname,
                            )
                              ? "bold"
                              : undefined,
                          }}
                        >
                          {page.title}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    {!isMobile &&
                      !!subMenusByParent[p] &&
                      !!p &&
                      activeMenu &&
                      activeMenu[p] === true &&
                      !!anchorEls && (
                        <MUIMenu
                          id={`sub-menu-${p}`}
                          anchorEl={anchorEls[p]}
                          open={true}
                          onClose={() => handleClose(null, p)}
                          anchorOrigin={{
                            vertical: "center",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "center",
                            horizontal: "right",
                          }}
                          disableRestoreFocus
                          MenuListProps={{ onMouseLeave: handleClose }}
                        >
                          <MenuItem
                            sx={{ p: 1, minWidth: 180 }}
                            onClick={() => {
                              handleClose(null, p);
                              history.push(adminPage(p).path);
                            }}
                          >
                            {adminPage(p).title}
                          </MenuItem>
                          {subMenusByParent[p].map(
                            (page: string, idx: number) => (
                              <MenuItem
                                sx={{ p: 1, minWidth: 180 }}
                                key={idx}
                                onClick={() => {
                                  handleClose(null, p);
                                  history.push(adminPage(page).path);
                                }}
                              >
                                {adminPage(page).title}
                              </MenuItem>
                            ),
                          )}
                        </MUIMenu>
                      )}
                  </Box>
                );
              })}
          </List>
        </Box>
        <Box>
          <List>
            <ProfileMenu />
          </List>
        </Box>
      </Box>
    </>
  );
}
