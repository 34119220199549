import { isProductType, Navigation, timeoutAsync } from "../../lib";
import { authClient, authActions, uiActions } from "../states";
import { AppThunk, ProductType } from "../types";
import { customer, CustomerState } from "./state";

const { actions } = customer;

interface PortalSession {
  id: string;
  token: string;
  access_url: string;
  status: string;
  created_at: number;
  expires_at: number;
  object: string;
  customer_id: string;
  redirect_url: string;
  linked_customers: Linkedcustomer[];
}

interface Linkedcustomer {
  object: string;
  customer_id: string;
  email: string;
  has_billing_address: boolean;
  has_payment_method: boolean;
  has_active_subscription: boolean;
}

export const customerActions = {
  ...actions,
  checkEmailExists(email: string): AppThunk {
    return async (dispatch, getState) => {
      if (!email) return;
      const { auth } = getState();
      dispatch(uiActions.setLoading(true));

      const { status, data } = await authClient.get(
        `/customer/exists/${email}?impersonating=${!!auth?.impersonating}`,
      );
      if (status === 200) {
        const _data = {
          customer: { user: { email }, active: data.customerActive },
          exist: data.customerExists,
          has_password: data.customerHasPassword,
        } as Partial<CustomerState>;

        dispatch(actions.setCustomerInfo(_data));
        dispatch(uiActions.setLoading(false));

        const nav = Navigation.go;
        const location = Navigation.getLocation();
        if (location === "/") {
          //dispatch(actions.setDefaultState());
          //dispatch(authActions.logout());
          !data.customerExists
            ? nav("/country")
            : data.customerHasPassword
            ? nav("/auth/login")
            : nav("/email-sent");
        }
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Site is down. Please try later"));
      }
    };
  },
  createCustomerSubscriptions({ token, vaultToken, newCard }): AppThunk {
    return async (dispatch, getState) => {
      if (!token) {
        dispatch(
          uiActions.showError(
            "Sorry, there was an error processing your order. Page will refresh in 5 seconds.",
          ),
        );
        setTimeout(() => {
          location.reload();
        }, 5000);
        return;
      }
      const {
        customer: {
          customer,
          product_selected,
          cb_customer_subscription,
          renewing,
        },
        auth: { token: JWTToken, impersonating },
      } = getState();
      dispatch(uiActions.setLoading(true));
      const payload = {
        customer,
        product_selected,
        token,
        vaultToken,
        newCard,
        renewing,
      };
      const url = JWTToken
        ? `/customer/existing/subscribe`
        : `/customer/new/subscribe`;
      if (
        product_selected.includes("loop") &&
        !customer.customer_phones?.filter((c) => c?.phone_number !== "")
          .length &&
        !customer.customer_children?.filter(
          (c) => c?.first_name !== "" && c?.pin !== "",
        ).length
      ) {
        dispatch(
          uiActions.showError(
            "Please enter a valid phone number and at least one child form",
          ),
        );
      } else {
        const renewingCustomer: typeof payload.customer = {};

        const renewingSub =
          cb_customer_subscription &&
          cb_customer_subscription.find(
            (c) =>
              renewing?.plan_name &&
              c.subscription.plan_name.includes(renewing.plan_name) &&
              c.subscription.plan_name.includes("Yearly"),
          );

        if (renewingSub && renewing && renewing.isRenewing) {
          const ship = renewingSub?.subscription.shipping_address;
          const bil = renewingSub.subscription.billing_address;
          renewingCustomer.ship_address_one =
            customer.ship_address_one ||
            ship.line1 ||
            customer.billing_address_one ||
            bil.line1;
          renewingCustomer.ship_address_two =
            customer.ship_address_two || ship.line2;
          renewingCustomer.ship_city =
            customer.ship_city ||
            ship.city ||
            customer.billing_city ||
            bil.city;
          renewingCustomer.ship_state =
            customer.ship_state ||
            ship.state_code ||
            customer.billing_state ||
            bil.state_code;
          renewingCustomer.ship_zip =
            customer.ship_zip || ship.zip || customer.billing_zip || bil.zip;

          renewingCustomer.billing_address_one =
            customer.billing_address_one || bil.line1;
          customer.ship_address_one || ship.line1;
          renewingCustomer.billing_city =
            customer.billing_city ||
            bil.city ||
            customer.ship_city ||
            ship.city;
          renewingCustomer.billing_state =
            customer.billing_state ||
            bil.state_code ||
            customer.ship_state ||
            ship.state_code;

          renewingCustomer.billing_zip =
            customer.billing_zip || bil.zip || customer.ship_zip || ship.zip;

          renewingCustomer.billing_country =
            customer.billing_country ||
            bil.country ||
            customer.country ||
            ship.country;

          renewingCustomer.same_address = false;
          renewingCustomer.billing_first_name =
            customer.billing_first_name ??
            cb_customer_subscription[0].subscription.first_name;
          renewingCustomer.billing_last_name =
            customer.billing_last_name ??
            cb_customer_subscription[0].subscription.last_name;
        }

        const { status, data } = await authClient.post(url, {
          ...payload,
          customer: {
            ...payload.customer,
            ...renewingCustomer,
            user: { ...customer.user },
          },
        });
        if (status === 204) {
          if (customer.user?.email) {
            await authClient.get(
              `/customer/exists/${
                customer.user.email
              }?impersonating=${!!impersonating}`,
            );
          }
          Navigation.go(`/success/${product_selected.join("-")}`);
          dispatch(customerActions.setDefaultState());
          dispatch(authActions.logout());
        } else if (status === 400 && data.message) {
          dispatch(uiActions.showError(data.message));
        } else if (status === 409) {
          dispatch(uiActions.showError(data.message));
        } else if (status === 403) {
          dispatch(
            uiActions.showError(
              "Your session has expired. Please login again.",
            ),
          );
          await timeoutAsync(5000);
          Navigation.go(`/`);
        } else if (status === 500 && renewing?.isRenewing) {
          dispatch(uiActions.setLoading(false));
          dispatch(
            uiActions.showError(
              "Unable to complete renewal - Please contact support",
            ),
          );
          dispatch(customerActions.setDefaultState());
        } else {
          dispatch(uiActions.showError("Subscription Could Not Be Created"));
        }
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getCustomerSubscriptions(id?: number | undefined): AppThunk {
    return async (dispatch, getState) => {
      const auth = getState().auth;
      if (!auth.roles?.includes("customer") && !id) {
        return;
      }
      dispatch(uiActions.setLoading(true));

      const url = id ? `/subscriptions/${id}` : "/subscriptions/mine";
      const { data, status } = await authClient.get(url);

      if (status !== 200) {
        dispatch(uiActions.showError("failed to get subscriptions"));
        dispatch(uiActions.setLoading(false));
        return false;
      } else {
        const products = Object.keys(data)
          .filter((p) => data[p])
          .map((product) => product.replace("_active", "")) as ProductType[];
        dispatch(actions.setReturnCustomerProducts(products));
        dispatch(uiActions.setLoading(false));
        return data;
      }
    };
  },
  getCustomerCards(id?: number | undefined, plan_id = ""): AppThunk {
    return async (dispatch) => {
      const url = id
        ? `/customer/cards/${id}`
        : `/customer/cards?plan_id=${plan_id}`;
      const { data, status } = await authClient.get(url);
      if (status === 200) {
        dispatch(actions.setCustomerCards(data));
        return data;
      } else {
        return false;
      }
    };
  },
  getCBSubscriptions(): AppThunk {
    return async (dispatch) => {
      const { data, status } = await authClient.get(
        "customer/chargebee-subscription/mine",
      );
      if (status === 200) {
        dispatch(actions.setCBCustomerSubscription(data));
      } else {
        //TODO: figure out how to deal with customers that got logged out
        //This is also hit by new customers
        //Navigation.go("/");
      }
    };
  },
  getAllInfoOnLogin(): AppThunk {
    return async (dispatch, getState) => {
      const auth = getState().auth;
      if (!auth.roles?.includes("customer")) {
        return;
      }
      // dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/customer/user/mine`);
      if (status === 200) {
        const _data = {
          id: data.id,
          country: data.country,
          ship_address_one: data.address,
          ship_address_two: data.address_2,
          ship_city: data.city,
          ship_state: data.state,
          ship_zip: data.zip,
          ship_first_name: data.first_name,
          ship_last_name: data.last_name,
          same_address: data.same_address,
          user: { ...data.user },
          customer_phones: [...data.customer_phones],
          customer_children: [...data.customer_children],
        };
        await dispatch(customerActions.checkEmailExists(data.user.email));
        dispatch(actions.setSubscriptionInfo(data));
        await dispatch(customerActions.setCustomer(_data));
        // await dispatch(
        //   customerActions.setProductsAvailable(data.zip, data.country),
        // );
        // dispatch(uiActions.setLoading(false));
      } else {
        dispatch(uiActions.showError("failed to get user info"));
        // dispatch(uiActions.setLoading(false));
      }
    };
  },
  setProductsAvailable(zip: string, country: string): AppThunk {
    return async (dispatch) => {
      const _zip = zip?.length ? zip : "";
      const _country = country === "GB" ? "UK" : country;
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(
        `/products?zip=${_zip}&country=${_country}`,
      );
      dispatch(uiActions.setLoading(false));
      const blankCountry = country.toString().trim() === "";
      if (status !== 200 || blankCountry) {
        dispatch(
          uiActions.showError(
            `failed to get available products for country: ${
              blankCountry ? "Please select a country" : country
            } ${
              _zip
                ? ` zip:${_zip}`
                : blankCountry
                ? ""
                : "zip: Please enter a valid zip"
            }`,
          ),
        );
        return false;
      } else {
        dispatch(actions.setProductAvailable(data));
        return data;
      }
    };
  },
  setRenewing(renew: boolean, plan?: string, planId?: string): AppThunk {
    return async (dispatch) => {
      dispatch(
        actions.setRenewing({
          isRenewing: renew,
          plan_name: plan,
          plan_id: planId,
        }),
      );
      const planType = plan && plan.split("_")[0].split("-")[0];
      if (planType && isProductType(planType)) {
        dispatch(actions.setProductSelected([planType]));
      }
    };
  },
  openPortal(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status }: { data: PortalSession; status: number } =
        await authClient.get(`/portal_sessions`);
      dispatch(uiActions.setLoading(false));

      if (status !== 200) {
        dispatch(uiActions.showError("Failed to create portal session"));
      } else {
        window.open(data.access_url, "_blank");
      }
    };
  },

  validatePhoneNumber(phone?: string, country_code?: string): AppThunk {
    return async () => {
      if (!phone || !country_code) {
        return {};
      }
      const { data, status } = await authClient.get(
        `/customers/phones/validate?phone=${phone}&country_code=${country_code}`,
      );
      if (status !== 200) {
        return {};
      }
      return data;
    };
  },

  //** LOOP STUDIO START */
  getKidsTracks(childId: number): AppThunk {
    return async (dispatch, getState) => {
      const auth = getState().auth;
      if (!auth.roles?.includes("customer")) {
        return;
      }
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/series-kids/${childId}`);
      dispatch(uiActions.setLoading(false));
      if (status === 200) {
        return data;
      } else if (status === 404) {
        return undefined;
      } else {
        dispatch(uiActions.showError("failed to child's tracks"));
        return undefined;
      }
    };
  },
  downloadTrack(id: number, fileName: string): AppThunk {
    return async (dispatch, getState) => {
      const auth = getState().auth;
      if (!auth.roles?.includes("customer")) {
        return;
      }
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.download(
        `/voicemail/download/${id}`,
        fileName,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        dispatch(uiActions.showError("failed to downloadTrack"));
      }
    };
  },

  //** LOOP STUDIO END */
};
