import {
  Grid,
  Typography,
  IconButton,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors, VoicemailBox } from "../../state";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
interface Props {
  closeModal: () => void;
  id: number;
  mode: string;
}
export const VoicemailActionsForm = ({ closeModal, id, mode }: Props) => {
  const defaultVmBox = {
    id: undefined,
    name: "",
  } as Partial<VoicemailBox>;
  const [vmBox, setVmBox] = useState(defaultVmBox);
  const [inputValue, setInputValue] = useState("");
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const vmBoxes = useSelector(adminSelectors.voicemailBoxes);
  useEffect(() => {
    if (inputValue) {
      const vmb = vmBoxes.find((v) => v.name === inputValue);
      if (vmb) {
        setVmBox({ ...vmb });
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setVmBox(defaultVmBox);
      setDisabled(true);
    }
  }, [dispatch, inputValue]);

  const handleClick = () => {
    if (mode === "Duplicate") {
      dispatch(adminActions.duplicateVoicemail(id, vmBox.id));
    } else if (mode === "Send") {
      dispatch(
        adminActions.updateVoicemail(
          id,
          { voicemail_box_id: vmBox.id, status: "new" },
          "",
        ),
      );
    } else {
      throw new Error("Invalid mode");
    }
    closeModal();
  };
  return (
    <Grid
      sx={{
        width: "100%",
        backgroundColor: "background.paper",
      }}
      flexDirection="column"
      container
    >
      <Typography
        variant="h6"
        sx={{ ml: 4.75, mt: 2 }}
      >{`${mode} Voicemail ${id} To:`}</Typography>
      <Grid
        sx={{
          width: "100%",
          justifyContent: "space-evenly",
          mb: 3,
          ml: 1,
        }}
        container
      >
        <Autocomplete
          disablePortal
          id="mail-box"
          options={vmBoxes.map((v) => v.name)}
          sx={{ width: 200, m: -1.25 }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => <TextField {...params} label="Mailbox" />}
        />
        <IconButton
          color="primary"
          type="submit"
          onClick={handleClick}
          disabled={disabled}
          sx={{ p: 0 }}
        >
          <ForwardToInboxIcon fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
  );
};
