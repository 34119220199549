import React from "react";
import { Field } from "formik";
import { TextField } from "formik-mui";
import {
  //
  Button,
  Box,
  Link,
  Typography,
} from "@mui/material";
import { Form, RouterLink, useFormik, yup } from "../../lib";
import { authActions, useAction } from "../../state";
import {
  ContainerStyle,
  FormStyle,
  TextFlexStyle,
  CenterElementStyle,
} from "./Auth.styles";
const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const LoginAfterResetPage = React.memo(
  /**
   *
   */
  function LoginPage() {
    const login = useAction(authActions.login);
    const frm = useFormik({
      initialValues: {
        username: "",
        password: "",
      },
      validationSchema,
      async onSubmit(values) {
        const success = await login(values);
        if (!success) {
          frm.setFieldValue("password", "");
        }
      },
    });

    return (
      <ContainerStyle>
        <Box>
          <Box>
            <Typography variant="h3" gutterBottom>
              Password Updated
            </Typography>
            <Typography variant="body1">
              Your password has been successfully updated. Sign in to continue.
            </Typography>
          </Box>
          <FormStyle>
            <Form form={frm}>
              <TextFlexStyle>
                <Field
                  component={TextField}
                  autoFocus
                  fullWidth
                  name="username"
                  label="Username"
                />
              </TextFlexStyle>
              <TextFlexStyle>
                <Field
                  component={TextField}
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                />
              </TextFlexStyle>
              <Button
                variant="contained"
                type="submit"
                disabled={frm.isSubmitting}
                fullWidth
              >
                Sign In
              </Button>
              <CenterElementStyle>
                <Link
                  component={RouterLink}
                  to={`/auth/forgot-password?email=${encodeURIComponent(
                    frm.values.username,
                  )}`}
                >
                  Forgot Password?
                </Link>
              </CenterElementStyle>
            </Form>
          </FormStyle>
        </Box>
      </ContainerStyle>
    );
  },
);
