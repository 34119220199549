import { Button, Grid, Hidden, Typography } from "@mui/material";
import logo from "../../assets/logo/loop-logo.png";

export const SomethingWentWrong = () => {
  return (
    <>
      <Grid
        sx={{
          backgroundColor: "#9957A3",
        }}
        height={"100vh"}
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        gap={2}
        alignItems={"center"}
      >
        {" "}
        <Grid
          item
          sx={{ backgroundColor: "#FFF", p: 2, borderRadius: "5px" }}
          width={"fit-content"}
        >
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
          <Hidden xsDown>
            <Grid item></Grid>
          </Hidden>
        </Grid>
        <Grid
          item
          gap={2}
          textAlign={"center"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Typography
            variant="h2"
            fontFamily={"Cera Pro"}
            fontWeight={"bold"}
            color={"#FFF"}
          >
            Something went wrong
          </Typography>
          <Typography
            variant="h2"
            fontFamily={"Cera Pro"}
            fontWeight={"bold"}
            color={"#FFF"}
          >
            We’re sorry. Don’t worry though. Our best man is on the case
          </Typography>
          <Button color="secondary" variant="contained" href="/">
            Return Home
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
