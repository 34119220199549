import { useEffect, useState } from "react";
import { DataGridPro, GridSelectionModel, } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors } from "../../state";
import { RECORDINGS_COLUMNS } from "../../components/tables/columns";
import { RootListViewStyled } from "./GenericPage.styles";
import { ToolBar } from "../../components";
import { Box, Typography } from "@mui/material";
import AdminPages from ".";
import { buildParams } from "../../lib/requestBuilder";

export const RecordingsPage = () => {
  const dispatch = useDispatch();
  const recordings = useSelector(adminSelectors.recordings);
  const [pageSize, setPageSize] = useState(25);
  const [tableStateParams, setTableStateParams] = useState("");
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  useEffect(() => {
    dispatch(adminActions.getRecordings(tableStateParams));
  }, [tableStateParams]);

  return (
    <RootListViewStyled>
      <Typography variant="h3">Recordings</Typography>

      <DataGridPro
        rows={recordings.rows}
        columns={RECORDINGS_COLUMNS(tableStateParams)}
        pagination
        checkboxSelection
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        rowsPerPageOptions={[10, 25, 50, 100]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowCount={recordings.count}
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        disableSelectionOnClick
        onStateChange={(s, _e, _d) => setTableStateParams(buildParams(s))}
        components={{
          Toolbar: ToolBar,
        }}
        componentsProps={{
          toolbar: {
            showQuickSearch: true,
            showAddButton: true,
            label: "Add Recording",
            to: AdminPages.recordingCreate.path,
            showSelected: true,
            amountSelected: selectionModel.length,
            actionOptions: ["Delete Recordings"],
            onSelectAction: () => {
              const confirmDelete = confirm(
                "Are you sure you want to delete these recordings? This action can't be undone",
              );
              // handleClose;
              if (confirmDelete) {
                dispatch(adminActions.bulkDeleteRecordings(selectionModel, tableStateParams));
              }
            },
            actionsDisabled: selectionModel.length === 0,
            actionsTooltip:
              selectionModel.length === 0 ? (
                <Box>
                  <Box>Select recording(s)</Box> <Box>to choose an action</Box>
                </Box>
              ) : (
                ""
              ),
          },
        }}
      />
    </RootListViewStyled>
  );
};
