import { Typography } from "@mui/material";
import { RootListViewStyled } from "./GenericPage.styles";
import { VOICEMAIL_BOX_COLUMNS } from "../../components/tables/columns";
import { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  adminActions,
  adminSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { buildParams } from "../../lib/requestBuilder";
import { ToolBar } from "../../components";
import { VoicemailBoxForm } from "../../components/forms/VoicemailBoxForm";
import CustomModal from "../../components/modal/CustomModal";
export const VoicemailBoxesPage = () => {
  const [pageSize, setPageSize] = useState(25);
  const [tableStateParams, setTableStateParams] = useState("");
   const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const vm = useSelector(adminSelectors.voicemailBoxes);
  const voicemailRowCount = useSelector(adminSelectors.voicemailBoxes).length;
  useEffect(() => {
    dispatch(adminActions.getVoicemailBoxes(tableStateParams));
  }, [dispatch, tableStateParams]);
  const openModalClick = () => {
    setOpenModal(!openModal);
    dispatch(adminActions.setDefaultVMBox());
  };
  const openModalEdit = async (id) => {
    setOpenModal(!openModal);
    await dispatch(adminActions.getVoicemailBox(id));
  };
  return (
    <RootListViewStyled>
      <Typography variant="h3" mb={3}>
        Voicemail Boxes
      </Typography>

      <DataGridPro
        rows={vm}
        columns={VOICEMAIL_BOX_COLUMNS}
        pagination
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowCount={voicemailRowCount}
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        onStateChange={(s, _e, _d) => setTableStateParams(buildParams(s))}
        onRowClick={(v) => openModalEdit(v.id)}
        components={{
          Toolbar: ToolBar,
        }}
        componentsProps={{
          toolbar: {
            showQuickSearch: true,
            showAddButton: true,
            label: "Add Voicemail Box",
            onClick: openModalClick,
          },
        }}
      />
      <CustomModal open={openModal} onClose={openModalClick}>
        <VoicemailBoxForm closeModal={openModalClick} />
      </CustomModal>
    </RootListViewStyled>
  );
};
