import { Grid, Typography, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackIcon } from "../../components";
import { LiveShowForm } from "../../components/forms/LiveShowForm";

export const LiveShowCreateOrEdit = () => {
  const nav = useNavigate();
  const returnToLivePage = () => {
    nav("/admin/live");
  };
  const { id } = useParams();
  return (
    <>
      <Grid item xs={12} md={3}>
        <Typography variant="h3">
          <IconButton onClick={returnToLivePage}>
            <ArrowBackIcon />
          </IconButton>
          {id ? "Edit" : "Create"} live Show
        </Typography>
      </Grid>
      <LiveShowForm />
    </>
  );
};
