import React from "react";
import { Field } from "formik";
import { TextField } from "formik-mui";
import {
  //
  Button,
  Box,
  Link,
  Typography,
} from "@mui/material";
import { Form, RouterLink, useFormik, yup } from "../../lib";
import { authActions, useAction } from "../../state";
import {
  ContainerStyle,
  FormStyle,
  TextFlexStyle,
  CenterElementStyle,
} from "./Auth.styles";
import { useMobile, useTablet } from "../../themes";
const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const LoginPage = React.memo(
  /**
   *
   */
  function LoginPage() {
    const login = useAction(authActions.login);
    const frm = useFormik({
      initialValues: {
        username: "",
        password: "",
      },
      validationSchema,
      async onSubmit(values) {
        const success = await login(values);
        if (!success) {
          frm.setFieldValue("password", "");
        }
      },
    });
    const isTablet = useTablet();
    const isMobile = useMobile();
    const isSmallerThanPc = isTablet || isMobile;
    return (
      <ContainerStyle>
        <Box>
          <Box>
            <Typography
              variant="h3"
              gutterBottom
              textAlign={isSmallerThanPc ? "center" : "justify"}
              paddingTop={isSmallerThanPc ? 2 : 0}
            >
              Welcome
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              textAlign={isSmallerThanPc ? "center" : "justify"}
            >
              Sign in to your account
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              textAlign={isSmallerThanPc ? "center" : "justify"}
              sx={{ color: "#66788A" }}

            >
              Don't have an account yet?     <Link
                component={RouterLink}
                to={`/auth/verify-account?email=${encodeURIComponent(
                  frm.values.username,
                )}`}
              >
                Create Account
              </Link>
            </Typography>
          </Box>
          <FormStyle>
            <Form form={frm}>
              <TextFlexStyle>
                <Field
                  component={TextField}
                  autoFocus
                  fullWidth
                  name="username"
                  label="Email"
                  className={
                    isSmallerThanPc ? "mobile-root" : ".MuiTextField-root"
                  }
                />
              </TextFlexStyle>
              <TextFlexStyle>
                <Field
                  component={TextField}
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  className={
                    isSmallerThanPc ? "mobile-root" : ".MuiTextField-root"
                  }
                />
              </TextFlexStyle>
              <Button
                variant="contained"
                type="submit"
                disabled={frm.isSubmitting}
                fullWidth
              >
                Sign In
              </Button>
              <CenterElementStyle>
                <Link
                  component={RouterLink}
                  to={`/auth/forgot-password?email=${encodeURIComponent(
                    frm.values.username,
                  )}`}
                >
                  Forgot Password?
                </Link>
              </CenterElementStyle>
            </Form>
          </FormStyle>
        </Box>
      </ContainerStyle>
    );
  },
);
