import {
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import { InputField, SelectField } from "../inputs/InputField";
import { yup } from "../../lib";
import {
  adminActions,
  adminSelectors,
  store,
  useDispatch,
  useSelector,
} from "../../state";
import { useEffect, useState } from "react";
import { PaperDropzone } from "../";
import { useNavigate } from "react-router";
import AdminPages from "../../pages/admin";
import { useParams } from "react-router-dom";

const validationSchemaBasic = {
  label: yup.string().required("Label is required"),
  name: yup.string().required("Name is required"),
  recording_type_id: yup.number().required("Please select recording type"),
};

const createValidationSchema = yup.object({
  ...validationSchemaBasic,
  public_url: yup.string().when("file_mode", {
    is: (a) => a === "useDownloadUrl",
    then: yup.string().required("file url is required."),
    otherwise: yup.string(),
  }),
  file: yup.mixed().when("file_mode", {
    is: (a) => a !== "useDownloadUrl",
    then: yup.mixed().required("file is required."),
    otherwise: yup.mixed(),
  }),
});

const editValidationSchema = yup.object(validationSchemaBasic);

interface formData {
  id?: number;
  file_path?: string;
  original_filename?: string;
  recording_type_id: number;
  internal_name?: string;
  name?: string;
  label: string;
  mime_type?: string;
  size?: string;
  duration?: string;
  full_url?: string;
  file_mode?: string;
}

export const RecordingForm = () => {
  const navigate = useNavigate();
  const _initialValues = {
    label: "",
    name: "",
    recording_type_id: 1,
    file: "",
    public_url: "",
    file_mode: "useDownloadUrl",
  };

  const [initialValues, setInitialValues] = useState<formData>(_initialValues);

  const dispatch = useDispatch<typeof store.dispatch>();
  const recording_types_options = useSelector(
    adminSelectors.recording_types_options,
  );
  const recording_types = useSelector(adminSelectors.recordingTypes);

  const { id } = useParams();
  const isEdit = !!id;

  const handleSubmit = async (values) => {
    if (isEdit) {
      await dispatch(adminActions.updateRecording(values));
    } else if (values.file_mode === "useDownloadUrl") {
      await dispatch(adminActions.saveRecordingUrl(values, false));
    } else {
      await dispatch(adminActions.saveRecording(values, false));
    }
    navigate(AdminPages.recording.path);
  };

  useEffect(() => {
    dispatch(adminActions.getRecordingTypes());
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (id) {
        const recording = await dispatch(
          adminActions.getRecording(parseInt(id)),
        );

        if (recording) {
          setInitialValues({ ...recording, name: recording.internal_name });
        }
      }
    })();
  }, [dispatch, id]);

  const isReadOnly =
    !!initialValues?.recording_type_id &&
    !!recording_types.find((t) => t.id === initialValues.recording_type_id)
      ?.is_internal_type;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={isEdit ? editValidationSchema : createValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <Grid alignItems={"center"} container spacing={4}>
            <Grid item xs={12} md={12}>
              <InputField label="Label" name="label" />
              <InputField label="Name" name="name" />
              <SelectField
                label="Recording Type"
                name="recording_type_id"
                options={recording_types_options}
                initialValues={initialValues}
              />
              {!isEdit && (
                <>
                  <ToggleButtonGroup
                    fullWidth
                    sx={{ mt: 4, mb: 4 }}
                    value={values.file_mode}
                    exclusive
                    onChange={(e, v) => {
                      if (!v) return;
                      setFieldValue("file_mode", v);
                    }}
                  >
                    <ToggleButton value="useDownloadUrl">
                      <Typography>Have Public Download URL</Typography>
                    </ToggleButton>
                    <ToggleButton value="useUploadFile">
                      <Typography>Upload File less than 32 MB</Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {values.file_mode === "useDownloadUrl" ? (
                    <InputField label="Public Location" name="public_url" />
                  ) : (
                    <PaperDropzone
                      filesCB={(files) => setFieldValue("file", files)}
                    />
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              {isReadOnly && (
                <Grid item xs={12} md={12}>
                  <Typography color={"error.main"}>
                    placeholder recordings are not editable
                  </Typography>
                </Grid>
              )}
              <Button
                fullWidth
                sx={{ mt: 3, ml: 0 }}
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting || isReadOnly}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
