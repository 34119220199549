import { Box, DialogActions, Divider, Typography } from "@mui/material";
import { RootListViewStyled } from "./GenericPage.styles";
import { VOICEMAIL_COLUMNS } from "../../components/tables/columns";
import { useEffect, useState } from "react";
import {
  DataGridPro,
  GridSelectionModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import {
  adminActions,
  adminSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { buildParams } from "../../lib/requestBuilder";

import CustomModal from "../../components/modal/CustomModal";
import { yup } from "../../lib";
import { Formik, Form } from "formik";
import { InputField, SelectField } from "../../components/inputs/InputField";
import Button from "@mui/material/Button";
import { ToolBar } from "../../components";

export const VoicemailsPage = () => {
  const [pageSize, setPageSize] = useState(25);
  const [tableStateParams, setTableStateParams] = useState("");
  const [transferToRecordingOpen, setTransferToRecordingOpen] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const dispatch = useDispatch();
  const vm = useSelector(adminSelectors.voicemails);
  const voicemailRowCount = useSelector(adminSelectors.voicemailRowCount);
  const recording_types_options = useSelector(
    adminSelectors.recording_types_options,
  );
  const apiRef = useGridApiRef();
  useEffect(() => {
    dispatch(adminActions.getRecordingTypes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(adminActions.getVoicemails(tableStateParams));
  }, [dispatch, tableStateParams]);

  return (
    <RootListViewStyled>
      <Box textAlign={"right"}></Box>
      <CustomModal
        open={transferToRecordingOpen}
        onClose={() => setTransferToRecordingOpen(false)}
        sx={{ minWidth: "50%" }}
      >
        <Box>
          <Typography mb={1} variant="h4">
            Transfer voicemails to recordings
          </Typography>
          <Typography mb={2}>
            {selectionModel.length} voicemails selected
          </Typography>
          <Formik
            initialValues={{ prefix: "", recording_type_id: 1 }}
            validationSchema={yup.object({
              prefix: yup.string(),
              recording_type_id: yup.number(),
            })}
            onSubmit={async (values) => {
              await dispatch(
                adminActions.voicemailsToRecording(
                  selectionModel,
                  values.recording_type_id,
                  values.prefix,
                ),
              );
              setTransferToRecordingOpen(false);
            }}
          >
            {({ values }) => (
              <Form>
                <Box
                  display={"inline-flex"}
                  width={"100%"}
                  justifyContent={"space-between"}
                  marginBottom={"40px"}
                >
                  <Box width={"100%"} marginRight={"15px"}>
                    <InputField
                      label="Prefix"
                      name="prefix"
                      helperText="This will be appended to the name of each recording"
                    />
                  </Box>
                  <Box width={"100%"} marginLeft={"15px"}>
                    <SelectField
                      label="Recording Type"
                      name="recording_type_id"
                      options={recording_types_options}
                      initialValues={values}
                    />
                  </Box>
                </Box>

                <Divider
                  sx={{
                    display: "flex",
                    alignSelf: "center",
                  }}
                />
                <DialogActions sx={{ py: 2 }}>
                  <Button
                    variant="outlined"
                    color="inherit"
                    sx={{
                      maxWidth: "180px",
                      border: "none",
                      "&:hover": {
                        backgroundColor: "#E4E7F5",
                      },
                      backgroundColor: "#E4E7F5",
                    }}
                    onClick={() => {
                      setTransferToRecordingOpen(false);
                    }}
                    autoFocus
                    fullWidth
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{ maxWidth: "180px" }}
                    variant="contained"
                    type="submit"
                    fullWidth
                  >
                    Save
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Box>
      </CustomModal>

      <DataGridPro
        rows={vm}
        columns={VOICEMAIL_COLUMNS}
        pagination
        checkboxSelection
        apiRef={apiRef}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowCount={voicemailRowCount}
        sortingMode="server"
        filterMode="server"
        paginationMode="server"
        disableSelectionOnClick
        onStateChange={(s, _e, _d) => setTableStateParams(buildParams(s))}
        components={{
          Toolbar: ToolBar,
        }}
        componentsProps={{
          toolbar: {
            showQuickSearch: true,
            showSelected: true,
            amountSelected: selectionModel.length,
            actionOptions: ["Transfer to recordings"],
            onSelectAction: () => setTransferToRecordingOpen(true),
            actionsDisabled: selectionModel.length === 0,
            actionsTooltip:
              selectionModel.length === 0 ? (
                <Box>
                  <Box>Select voicemail(s)</Box> <Box>to choose an action</Box>
                </Box>
              ) : (
                ""
              ),
          },
        }}
      />
    </RootListViewStyled>
  );
};
