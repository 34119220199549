import { styled } from "@mui/material/styles";
import { Paper, IconButton, PaperProps } from "@mui/material";
import { useDispatch, uiActions } from "../../state";
import { drawerWidth } from "./MiniDrawer";
import logo from "../../assets/img/logo.png";
import MenuIcon from "@mui/icons-material/Menu";

interface AppBarProps extends PaperProps {
  open?: boolean;
}

const PaperBar = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function MobileTopBar() {
  const dispatch = useDispatch();
  const handleDrawerOpen = () => {
    dispatch(uiActions.setMenuOpen(true));
  };

  return (
    <>
      <PaperBar
        elevation={1}
        sx={{
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
        >
          <MenuIcon />
        </IconButton>
        <img src={logo} alt="loop-logo" width={"120px"} />
      </PaperBar>
    </>
  );
}
