import { useEffect, useState } from "react";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors } from "../../state";
import { RootListViewStyled } from "./GenericPage.styles";
import { GAME_VERSIONS_COLUMNS } from "../../components/tables/columns";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useLocation } from "../../lib";

export const GamesVersionsPage = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const gameVersions = useSelector(adminSelectors.gameVersions);
  const apiRef = useGridApiRef();
  const location = useLocation();
  const [pageSize, setPageSize] = useState(
    Number(location.query.pageSize ?? 25),
  );

  useEffect(() => {
    dispatch(adminActions.getGameVersions());
  }, [dispatch]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (pageSize) {
      params.append("pageSize", pageSize.toString());
    } else {
      params.delete("pageSize");
    }
    nav({ search: params.toString() });
  }, [pageSize]);

  return (
    <RootListViewStyled>
      <Grid alignItems={"center"} container spacing={4}>
        <Grid item xs={12} md={3}>
          <Typography gutterBottom variant="h3">
            Game Versions
          </Typography>
        </Grid>
      </Grid>
      <DataGridPro
        autoHeight
        apiRef={apiRef}
        rows={gameVersions}
        columns={GAME_VERSIONS_COLUMNS}
        pagination
        rowsPerPageOptions={[10, 25, 50]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      />
    </RootListViewStyled>
  );
};
