import {
  Grid,
  Typography,
  Box,
  Button,
  Autocomplete,
  AutocompleteChangeReason,
  TextField,
} from "@mui/material";
import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatPhone, yup } from "../../lib";
import { CountryCodes } from "../../lib/CountryCodes";
import {
  adminActions,
  adminSelectors,
  customerActions,
  CustomerPhones,
  customerSelectors,
} from "../../state";
import { useMobile } from "../../themes";
import { InputField, SelectField } from "../inputs/InputField";
import { useParams } from "react-router-dom";
interface Props {
  closeModal: () => void;
  mode: "admin" | "customer";
  subscription_filter?: number | string;
}
export const AddPhoneForm = ({
  closeModal,
  mode,
  subscription_filter = "",
}: Props) => {
  const [dropDownCountryCode, setDropDownCountryCode] = useState({
    country_code: "+1",
    flag: "🇺🇸",
    country: "US",
    label: "United States",
  });
  const [inputValue, setInputValue] = useState("");
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const params = useParams();

  const customer =
    mode === "admin"
      ? useSelector(adminSelectors.customer)
      : useSelector(customerSelectors.customer).customer;

  const customerSubscriptions = useSelector(
    adminSelectors.customerSubscriptions,
  );
  const activeSubId = params.subscription_id;
  const _initialValues = {
    label: "",
    country: "US",
    country_code: "+1",
    phone_number: "",
    number_type: "loop",
    customer_id: customer.id,
    subscription_id:
      subscription_filter != ""
        ? subscription_filter
        : activeSubId ? activeSubId : 0
  } as Partial<CustomerPhones>;
  const [initialValues, setInitialValues] = useState(_initialValues);

  const phone = useSelector(adminSelectors.customerPhones);
  const currentPhone = phone?.phone_number ?? undefined;
  const validationSchemaObject = {
    label: yup.string(),
    country: yup.string().required("Country Required"),
    phone_number: yup
      .string()
      .required("Number required")
      .matches(/^\d+$/, "A phone number should only include numbers")
      .min(7, "phone number should be at least 7 digits long")
      .max(10, "phone number should be max 10 digits long")
      .test(
        "phones-exist1",
        "Phone number already in use",
        async function (value) {
          if ((this.parent.number_type != "loop" && mode === "admin") || value === currentPhone) {
            return true;
          }
          const phone_one_cc = this.parent.country_code?.replace("+", "");
          const validPhoneNumber = (await dispatch(
            customerActions.validatePhoneNumber(value, phone_one_cc),
          )) as unknown as { phoneNumberAvailable?: boolean };
          return validPhoneNumber.phoneNumberAvailable ?? true;
        },
      ),
    country_code: yup.string().required("Country Code Required"),
    subscription_id: yup.number(),
  };
  if (mode === "admin") {
    validationSchemaObject.subscription_id = yup
      .number()
      .moreThan(0, "Subscription Required")
      .required("Subscription Required");
  }
  const validationSchema = yup.object(validationSchemaObject);

  useEffect(() => {
    (async () => {
      if (mode === "admin" && customer.id) {
        dispatch(adminActions.getCustomer(customer.id));
      }
      if (phone.id) {
        setInitialValues({
          ...phone,
          subscription_id:
            phone.subscription_id || (activeSubId ? +activeSubId : 0),
        });
        const currentCC = CountryCodes.find(
          (c) => c.country.toUpperCase() === phone.country.toUpperCase(),
        );
        setDropDownCountryCode(currentCC ?? dropDownCountryCode);
        setEditMode(true);
      } else {
        setEditMode(false);
      }
    })();
  }, [dispatch, phone]);

  const handleSubmit = (values, { setSubmitting }) => {
    if (editMode) {
      dispatch(adminActions.updateCustomerPhone(phone.id, values, mode));
      dispatch(adminActions.getCustomerPhone(customer.id!, mode));
    } else {
      dispatch(adminActions.createPhoneNumber(values, mode));
    }
    setSubmitting(false);
    closeModal();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form
          onBlur={() => {
            setFieldValue("label", values.label);
          }}
        >
          <Grid
            sx={{
              width: "100%",
              border: "1px solid #E4E7EB",
              borderRadius: 2,
              m: 1,
              mt: 2,
              p: 2,
            }}
            display={"flex"}
            flexDirection="column"
            container
          >
            <Typography variant="h6">
              {editMode ? "Edit Phone" : "Add Phone"}
            </Typography>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <InputField label={"Nickname (optional)"} name={"label"} />
            </Grid>

            <Autocomplete
              disablePortal
              options={CountryCodes}
              getOptionLabel={(option) => option.country_code + option.flag}
              value={dropDownCountryCode}
              onChange={(
                event: any,
                newValue: any,
                reason: AutocompleteChangeReason,
              ) => {
                setDropDownCountryCode(newValue);
                let _newValue = newValue;
                if (reason === "clear") {
                  _newValue = {
                    country_code: "+1",
                    flag: "🇺🇸",
                    country: "US",
                    label: "United States",
                  };
                }
                setFieldValue("country_code", _newValue.country_code);
                setFieldValue("country", _newValue.country);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  className={isMobile ? "mobile-root" : ".MuiTextField-root"}
                  {...params}
                  label="Country Code"
                />
              )}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.flag} {option.label} {option.country_code}
                </Box>
              )}
              isOptionEqualToValue={(option, value) =>
                option.country_code === value.country_code
              }
            />
            <Grid item xs={12}>
              <InputField label={"Phone Number"} name={"phone_number"} />
            </Grid>
            {mode === "admin" && (
              <Grid item xs={12}>
                <InputField disabled label={"Type"} name={"number_type"} />
              </Grid>
            )}
            {mode === "admin" && (
              <SelectField
                label="Subscription"
                name="subscription_id"
                options={
                  customerSubscriptions
                    ?.filter(
                      (s) =>
                        !!s.active &&
                        s.subscription_plan_id === 10,
                    )
                    .map((s, idx) => {
                      return {
                        id: s.id!,
                        label:
                          idx + 1 + " - " + ((s?.phone?.phone_number?.length === 10
                            ? formatPhone(s.phone.phone_number)
                            : formatPhone(
                              s?.phone?.area_code ??
                              "" + s?.phone?.phone_number,
                            )) ?? "") +
                          " - " +
                          s.shipping_name + " | " + s.provider_subscription_id,
                      };
                    }) ?? []
                }
                initialValues={initialValues}
              />
            )}
          </Grid>
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <Button
              sx={{ mt: 3, ml: 3, mr: 0 }}
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Save Phone
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
