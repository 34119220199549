import AdminPages from ".";
import {
  ArrowBackIcon,
  BulkUploadRecordingForm,
  RecordingForm,
} from "../../components";
import {
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { RootListViewStyled } from "./GenericPage.styles";
import { useState } from "react";

export const RecordingsCreatePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isEdit = !!id;
  const [uploadType, setUploadType] = useState("single");

  return (
    <RootListViewStyled>
      <Grid
        container
        spacing={12}
        direction="row"
        justifyContent="space-between"
        paddingBottom={3}
      >
        <Grid item>
          <Typography variant="h3">
            <IconButton
              color="primary"
              onClick={() => navigate(AdminPages.recording.path)}
            >
              <ArrowBackIcon />
            </IconButton>
            {isEdit ? "Edit" : "Add"} Recording
          </Typography>
          {!isEdit && (
            <ToggleButtonGroup
              fullWidth
              sx={{ mt: 4, mb: 4 }}
              value={uploadType}
              exclusive
              onChange={(e, v) => {
                if (!v) return;
                setUploadType(v);
              }}
            >
              <ToggleButton value="single">
                <Typography>Single upload</Typography>
              </ToggleButton>
              <ToggleButton value="bulk">
                <Typography>Batch upload</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          )}
          {uploadType === "single" ? (
            <RecordingForm />
          ) : (
            <BulkUploadRecordingForm />
          )}
        </Grid>
      </Grid>
    </RootListViewStyled>
  );
};
