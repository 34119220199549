import { Grid, Typography, Box, Button } from "@mui/material";
import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { yup } from "../../lib";
import { adminActions, adminSelectors } from "../../state";
import { InputField, SelectField } from "../inputs/InputField";

export const GameRulesForm = () => {
  const validationSchema = yup.object({
    game_id: yup.number(),
    rule_name: yup.string().required("title is required"),
    min_score: yup.number(),
    max_age_points: yup.number(),
    prize_type: yup.string(),
    play_limit: yup.number(),
    game_level: yup.number(),
    point_count: yup.number(),
  });
  const [editMode, setEditMode] = useState(false);

  const _initialValues = {
    game_id: "",
    rule_name: "",
    location_id: "",
    min_score: -1,
    max_age_points: -1,
    prize_type: "points",
    play_limit: -1,
    game_level: -1,
    point_count: -1,
  };
  const dispatch = useDispatch();
  const games = useSelector(adminSelectors.games);

  const options = games.rows.map((g) => {
    return { id: g.id, label: g.name };
  });
  const params = useParams();
  const [initialValues, setInitialValues] = useState(_initialValues);
  useEffect(() => {
    (async () => {
      if (params.id) {
        const game: any = await dispatch(
          adminActions.getGameRulesByGameId(params.id),
        );
        if (game) {
          setEditMode(true);
          setInitialValues({ ...initialValues, ...game });
        }
      }
    })();
  }, [dispatch, params.id]);

  useEffect(() => {
    dispatch(adminActions.getGames());
    dispatch(adminActions.getGameRules());
  }, [dispatch]);
  const handleSubmit = (values, { setSubmitting }) => {
    if (editMode) {
      dispatch(adminActions.updateGameRules(params.id, values));
    } else {
      dispatch(adminActions.createGameRules(values));
    }
    setSubmitting(false);
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid
            sx={{
              width: "50%",
              border: "1px solid #E4E7EB",
              borderRadius: 2,
              m: 1,
              mt: 2,
              p: 2,
            }}
            display={"flex"}
            flexDirection="column"
            container
          >
            <Typography variant="h6"> </Typography>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <SelectField
                label={"Game Name"}
                name={"game_id"}
                options={options}
                initialValues={initialValues}
              />
            </Grid>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <InputField label={"Rule name"} name={"rule_name"} />
            </Grid>

            <Grid item xs={12}>
              <InputField
                label={"Max Age Points"}
                name={"max_age_points"}
                type={"number"}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label={"Location ID"}
                name={"location_id"}
                type={"text"}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label={"Minimum Score"}
                name={"min_score"}
                type={"number"}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectField
                label={"Prize Type"}
                name={"prize_type"}
                options={[
                  { id: "points", label: "points" },
                  { id: "prize", label: "prize" },
                  { id: "other", label: "other" },
                  { id: "none", label: "none" },
                ]}
                initialValues={initialValues}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label={"Play Limit"}
                name={"play_limit"}
                type={"number"}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label={"Game Level"}
                name={"game_level"}
                type={"number"}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label={"Point Count"}
                name={"point_count"}
                type={"number"}
              />
            </Grid>
          </Grid>
          <Box display={"flex"} width={"50%"} justifyContent={"flex-end"}>
            <Button
              sx={{ mt: 3, ml: 3, mr: 0 }}
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Save Game Rules
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
