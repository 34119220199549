import { Grid, Typography, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ArrowBackIcon } from "../../components";
import { CustomerForm } from "../../components/forms/CustomerForm";
import { adminSelectors } from "../../state";

export const CustomerCreateOrEdit = () => {
  const nav = useNavigate();
  const c = useSelector(adminSelectors.customer);
  const returnToCustomerPage = () => {
    nav(`/admin/customers/${c.id}/general`);
  };

  return (
    <>
      <Grid item xs={12} md={3} mb={2}>
        <Typography variant="h3">
          <IconButton onClick={returnToCustomerPage}>
            <ArrowBackIcon />
          </IconButton>
          {c.id ? "Edit" : "Create"} Customer
        </Typography>
      </Grid>
      <CustomerForm />
    </>
  );
};
