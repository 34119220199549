import { Grid, Typography, Box, Button } from "@mui/material";
import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { yup } from "../../lib";
import { adminActions } from "../../state";
import { InputField } from "../inputs/InputField";

export const GameForm = () => {
  const validationSchema = yup.object({
    name: yup.string().required("Game name is required"),
  });
  const [editMode, setEditMode] = useState(false);

  const _initialValues = {
    name: "",
  };
  const dispatch = useDispatch();

  const params = useParams();
  const [initialValues, setInitialValues] = useState(_initialValues);
  useEffect(() => {
    (async () => {
      if (params.id) {
        const game: any = await dispatch(adminActions.getGame(params.id));
        if (game) {
          setEditMode(true);
          setInitialValues({ ...game });
        }
      }
    })();
  }, [dispatch, params.id]);

  const handleSubmit = (values, { setSubmitting }) => {
    if (editMode) {
      dispatch(adminActions.updateGame(params.id, values));
    } else {
      dispatch(adminActions.createGame(values));
    }
    setSubmitting(false);
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid
            sx={{
              width: "40%",
              border: "1px solid #E4E7EB",
              borderRadius: 2,
              m: 1,
              mt: 2,
              p: 2,
            }}
            display={"flex"}
            flexDirection="column"
            container
          >
            <Typography variant="h6"> </Typography>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <InputField label={"Name"} name={"name"} />
            </Grid>
          </Grid>
          <Box display={"flex"} width={"40%"} justifyContent={"flex-end"}>
            <Button
              sx={{ mt: 3, ml: 3, mr: 0 }}
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Save Game
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
