import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { REACT_APP_SITE_TITLE } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { authActions, authSelectors } from "../../state";
import { Route, useLocation } from "react-router-dom";
import { Navigation, parseQueryString } from ".";
import { capitalizeFirstLetter } from "../utils";

function Page(props) {
  const { view: Component, title, anon, ...rest } = props;
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    const query = parseQueryString(location.search);
    if (query?.impersonate_token) {
      dispatch(authActions.loginWithToken(query.impersonate_token));
    }
  }, [location.search]);
  useEffect(() => {
    if (
      anon === false &&
      !isLoggedIn &&
      location.pathname.indexOf("email") < 0
    ) {
      Navigation.go("/auth/login?after=" + location.pathname + location.search);
    }
  }, [anon, isLoggedIn, location.pathname, location.search]);
  let _title = title;
  if (_title.indexOf("/:tab")) {
    _title = title.replace(
      "/:tab",
      " " +
        capitalizeFirstLetter(
          location.pathname.split("/").pop()?.replace("selection", ""),
        ),
    );
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {_title} | {REACT_APP_SITE_TITLE}
        </title>
      </Helmet>
      <Component {...rest} />
    </>
  );
}

export function renderPageRoutes(pages) {
  if (!pages) {
    throw new Error("Missing pages: " + typeof pages);
  }
  return Object.keys(pages).map((pageName) => {
    const { path, ...rest } = pages[pageName];

    if (!rest.title) {
      throw new Error("Page missing title");
    }
    return (
      <Route
        {...rest}
        key={path}
        // exact={pathExact}
        path={path}
        element={<Page {...rest} />}
      />
    );
  });
}
