import { useEffect, useState } from "react";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import AdminPages from ".";
import { ToolBar } from "../../components";
import { RootListViewStyled } from "./GenericPage.styles";
import { GAMES_COLUMNS } from "../../components/tables/columns";
import { generatePath, useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useLocation } from "../../lib";
import { adminActions, adminSelectors } from "../../state";

export const GamesPage = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const games = useSelector(adminSelectors.games);
  const apiRef = useGridApiRef();
  const location = useLocation();
  const [pageSize, setPageSize] = useState(
    Number(location.query.pageSize ?? 25),
  );

  useEffect(() => {
    dispatch(adminActions.getGames());
  }, [dispatch]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (pageSize) {
      params.append("pageSize", pageSize.toString());
    } else {
      params.delete("pageSize");
    }
    nav({ search: params.toString() });
  }, [pageSize]);

  return (
    <RootListViewStyled>
      <Grid alignItems={"center"} container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="h3">Games</Typography>
        </Grid>
      </Grid>

      <DataGridPro
        autoHeight
        apiRef={apiRef}
        rows={games.rows}
        columns={GAMES_COLUMNS}
        pagination
        onRowClick={(row) =>
          nav(
            generatePath(AdminPages.gamesEdit.path, {
              id: row.id.toString(),
            }),
          )
        }
        rowsPerPageOptions={[10, 25, 50, 100, 200]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          Toolbar: ToolBar,
        }}
        componentsProps={{
          toolbar: {
            showQuickSearch: true,
            showAddButton: true,
            label: "Add Games",
            to: AdminPages.gamesCreate.path,
          },
        }}
      />
    </RootListViewStyled>
  );
};
