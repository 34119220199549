import { Button, Grid } from "@mui/material";
import { Formik, Form } from "formik";
import { InputField, SelectField } from "../inputs/InputField";
import { yup } from "../../lib";
import {
  adminActions,
  adminSelectors,
  AppType,
  useDispatch,
  useSelector,
} from "../../state";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import AdminPages from "../../pages/admin";
import { JSONField } from "..";
import { useParams } from "react-router-dom";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  type: yup.string().required("Type is required"),
  // meta: yup.mixed().required("File is required"),//TODO: make sure is valid json or undefined
});

export const AppTypeForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const initialValues = {
    name: "",
    type: "internal",
    meta: undefined,
  } as AppType;

  const appTypes = useSelector(adminSelectors.app_types);
  const [appTypeValue, setAppTypeValue] = useState<AppType>(initialValues);

  const dispatch = useDispatch();
  const handleSubmit = async (values) => {
    await dispatch(adminActions.saveAppType(values));
    navigate(AdminPages.appTypes.path);
  };

  useEffect(() => {
    dispatch(adminActions.getRecordingTypes());
  }, [dispatch]);

  useEffect(() => {
    const id = params.id ? +params.id : -1;
    const activeAppType = appTypes.find((a) => a.id === id);
    if (activeAppType) {
      setAppTypeValue(activeAppType);
    }
  }, [appTypes]);

  return (
    <Formik
      enableReinitialize
      initialValues={appTypeValue}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid alignItems={"center"} container spacing={4}>
            <Grid item xs={12} md={12}>
              <InputField label="Name" name="name" />
              <SelectField
                label="App Type"
                name="type"
                options={[
                  { id: "internal", label: "Internal" },
                  { id: "external", label: "External" },
                ]}
                initialValues={initialValues}
              />
              <JSONField name="meta" label={"Meta"} height={400} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                fullWidth
                sx={{ mt: 3, ml: 0 }}
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
