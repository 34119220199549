import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useParams,
  Link as RouterLink,
  generatePath,
} from "react-router-dom";
import {
  adminActions,
  adminSelectors,
  authActions,
  customerActions,
} from "../../state";
import { RootListViewStyled } from "./GenericPage.styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaceIcon from "@mui/icons-material/Place";
import EmailIcon from "@mui/icons-material/Email";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PersonIcon from "@mui/icons-material/Person";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Status } from "../../components";
import { useMobile } from "../../themes";
import { CustomerGeneralTab } from "../../components/tabs/CustomerGeneralTab";
import { capitalizeAddress, formatPhone } from "../../lib";
import { BillingTab } from "../../components/tabs/BillingTab";
import AdminPages from ".";

export const CustomerPage = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isMobile = useMobile();
  const { id } = useParams();
  const [value, setValue] = useState<string | number>(0);
  const currentCustomer = useSelector(adminSelectors.customer);
  const user = currentCustomer.user;
  const nav = useNavigate();
  const returnToChildrenPage = () => {
    nav("/admin/customers");
  };
  const handleImpersonate = () => {
    if (currentCustomer.user_id) {
      dispatch(authActions.impersonate(currentCustomer.user_id));
    }
  };
  useEffect(() => {
    if (!currentCustomer.user_id && !id) {
      return;
    } else {
      dispatch(adminActions.getCustomer(id));
      dispatch(
        customerActions.getCustomerSubscriptions(
          Number(currentCustomer.user_id),
        ),
      );
      // dispatch(
      //   customerActions.getCustomerCards(Number(currentCustomer.user_id)),
      // );
    }
  }, [dispatch, currentCustomer.user_id, id]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: SyntheticEvent, newValue: string | number) => {
    setValue(newValue);
  };
  const curPhones = [
    ...currentCustomer.customer_phones,
    { phone_number: user.phone, number_type: "customer", label: "" },
  ];

  if (!id) return null;
  const generalTabUrl = generatePath(AdminPages.customer.path, {
    id,
    tab: "general",
  });
  const billingTabUrl = generatePath(AdminPages.customer.path, {
    id,
    tab: "billing",
  });
  return (
    <RootListViewStyled>
      <Grid alignItems={"center"} container>
        <Grid item xs={12} md={6} display={"inline-flex"} alignItems={"center"}>
          <Typography variant="h3" mr={2}>
            <IconButton onClick={returnToChildrenPage}>
              <ArrowBackIcon />
            </IconButton>
            {`${currentCustomer.first_name || user.first_name} ${currentCustomer.last_name || user.last_name
              }`}
          </Typography>
          <Status active={currentCustomer.active} />
        </Grid>

        {!isMobile && (
          <Grid
            item
            xs={12}
            md={6}
            display="inline-flex"
            justifyContent={"flex-start"}
          >
            <Button
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              variant="contained"
              sx={{ width: "180px" }}
            >
              <MoreHorizIcon sx={{ mr: 1 }} />
              Actions
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                style: {
                  marginTop: 4,
                  marginLeft: isMobile ? 0 : 2,
                  width: "300px",
                },
              }}
            >
              <MenuItem sx={{ textAlign: "right" }} onClick={handleImpersonate}>
                <PersonIcon fontSize="small" sx={{ mr: 2 }} /> Login as Customer
              </MenuItem>
              <MenuItem
                sx={{ textAlign: "right" }}
                onClick={() =>
                  nav(
                    `/admin/customer/${currentCustomer.user_id}/${currentCustomer.id}`,
                  )
                }
              >
                <ModeEditIcon fontSize="small" sx={{ mr: 2 }} /> Edit Customer
                Details
              </MenuItem>
              <MenuItem
                onClick={() => {
                  const confirmDelete = confirm(
                    "Are you sure you want to delete this will remove all associations with the customer",
                  );
                  handleClose;
                  if (confirmDelete) {
                    dispatch(adminActions.deleteCustomer(id));
                  } else {
                    nav("/admin/customers");
                  }
                }}
              >
                <DeleteIcon fontSize="small" sx={{ mr: 2 }} /> Delete
              </MenuItem>
            </Menu>
          </Grid>
        )}
      </Grid>
      <Grid
        container={isMobile}
        item={!isMobile}
        xs={12}
        display={"flex"}
        my={isMobile ? 0 : 4}
        width={isMobile ? "100%" : "70%"}
        flexBasis={1}
        justifyContent={"space-between"}
        gap={isMobile ? 2 : 0}
      >
        <Grid
          item
          xs={12}
          md={6}
          display={"inline-flex"}
          alignItems={"flex-start"}
        >
          {!isMobile ? (
            <IconButton
              sx={{ pt: 0 }}
              disabled={true}
              disableFocusRipple={true}
              disableRipple={true}
            >
              <PhoneIcon color="secondary" />
            </IconButton>
          ) : (
            <PhoneIcon color="secondary" />
          )}
          {curPhones && curPhones?.length ? (
            <Box display="flex" flexDirection={"column"} sx={{ ml: 2 }}>
              {curPhones.map((p, idx) => {
                if (p?.number_type === "customer") {
                  return (
                    <Typography key={idx}>
                      {p.label}{" "}
                      {p.phone_number?.length === 10
                        ? formatPhone(p?.phone_number)
                        : p?.phone_number}
                    </Typography>
                  );
                }
              })}
            </Box>
          ) : null}
        </Grid>
        <Grid item xs={12} md={6} display={"inline-flex"} ml={4}>
          <PlaceIcon color="secondary" />
          <Box
            display="flex"
            flexDirection={isMobile ? "row" : "column"}
            sx={{ ml: 2 }}
          >
            <Typography>
              {capitalizeAddress(`${currentCustomer.address} , ${currentCustomer.city}
                ${currentCustomer.state} ${currentCustomer.zip}`)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display={"inline-flex"} ml={4}>
          <EmailIcon color="secondary" />
          <Box
            display="flex"
            flexDirection={isMobile ? "row" : "column"}
            sx={{ ml: 2 }}
          >
            <Typography>{user.email}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display={"inline-flex"} ml={4}>
          {/* <Typography>
            Parent's Pin
            <Box ml={1} component={"span"} fontWeight={"bold"}>
              {currentCustomer.pin}
            </Box>
          </Typography> */}
        </Grid>
      </Grid>
      <Box
        sx={{
          width: isMobile ? "100%" : 600,
          textAlign: "center",
          display: "flex",
        }}
      >
        <Tabs value={value} onChange={handleChange} textColor="inherit">
          <Tab component={RouterLink} label={"Numbers & Children"} to={generalTabUrl} />
          <Tab
            component={RouterLink}
            label="Subscriptions"
            to={billingTabUrl}
          />
        </Tabs>
      </Box>
      <Divider />
      <CustomerGeneralTab mode="admin" value={generalTabUrl} />
      <BillingTab value={billingTabUrl} />


    </RootListViewStyled>
  );
};
