import axios from "axios";
import { initChargebeeInstance } from "../../lib";
import { AppThunk } from "../types";
import { ui, UIViewType, UIViewState } from "./state";

const { actions } = ui;

export const uiActions = {
  ...actions,

  // Notifications

  showError(
    message = "There was an error processing your request.",
    duration = 4000,
  ) {
    return actions.showNotification({ message, duration, variant: "error" });
  },

  showMessage(message: string) {
    return actions.showNotification({ message });
  },

  showSuccess(message: string) {
    return actions.showNotification({ message, variant: "success" });
  },
  showNotificationModal(content) {
    return actions.showNotificationModal(content);
  },

  // Views

  /** Dynamically creates an action function which sets `view[name] = false`. */
  toHideView(name: keyof UIViewState) {
    return () => actions.setView({ [name]: false });
  },
  /** Dynamically creates an action function which removes `view[name]`. */
  toRemoveView(name: keyof UIViewState) {
    return () => actions.setView({ [name]: undefined });
  },
  /** Dynamically creates an action function which sets `view[name] = value`. */
  toSetView(name: keyof UIViewState) {
    return (value?: UIViewType) => actions.setView({ [name]: value });
  },
  /** Dynamically creates an action function which sets `view[name] = true`. */
  toShowView(name: keyof UIViewState) {
    return () => actions.setView({ [name]: true });
  },
  getChargebeeSettings(): AppThunk {
    return async (dispatch) => {
      const { status, data } = await axios.get(`/api/V1/providers/public-info`);
      if (status === 200) {
        initChargebeeInstance(data);
        dispatch(actions.setChargebeeConfig(data));
      } else {
        dispatch(uiActions.showError("Failed to load payment Config"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
};
