import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CountCard = styled(Card)(({ theme }) => ({
  height: 100,
  width: 280,
  padding: 24,
  border: "1px solid #E4E7EB",
  borderRadius: 4,
  marginTop: 8,
  marginBottom: 8,
  textTransform: "capitalize",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
