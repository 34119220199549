import React, { useEffect } from "react";
import { Backdrop, CircularProgress, CssBaseline } from "@mui/material";
import { uiActions, uiSelectors, useDispatch, useSelector } from "../../state";
import MiniDrawer from "./MiniDrawer";
import PermanentDrawer from "./PermanentDrawer";
import MobileDrawer from "./MobileDrawer";
import Notification from "../../components/Notification";
import { Outlet, useLocation } from "react-router-dom";
import {
  ADMIN_BASE_URL,
  COLLAPSIBLE_DRAWER,
  CUSTOMER_SITE_BASE_URL,
} from "../../config";
import { MainContentStyled } from "./AdminLayout.styles";
import { useMobile } from "../../themes";
import MobileTopBar from "./MobileTopBar";
import { ImpersonateBanner } from "../../components/ImpersonateBanner";
import { NotificationModal } from "../../components/NotificationModal";

export const AdminLayout = React.memo(function () {
  const loading = useSelector(uiSelectors.loading);
  const menuOpen = useSelector(uiSelectors.menuOpen);
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const location = useLocation();
  useEffect(() => {
    if (window.location.origin === CUSTOMER_SITE_BASE_URL) {
      window.location.href = ADMIN_BASE_URL;
    }
  }, [location]);

  useEffect(() => {
    if (!isMobile) {
      dispatch(uiActions.setMenuOpen(true));
    } else {
      dispatch(uiActions.setMenuOpen(false));
    }
    window.FreshworksWidget("hide");
    dispatch(uiActions.getChargebeeSettings());
  }, []);

  return (
    <>
      <CssBaseline />
      <ImpersonateBanner />
      {isMobile ? (
        <>
          <MobileTopBar />
          <MobileDrawer />
        </>
      ) : COLLAPSIBLE_DRAWER ? (
        <MiniDrawer />
      ) : (
        <PermanentDrawer />
      )}
      {/* Figure out if the ml is always correct feels hacky */}
      <MainContentStyled
        sx={{
          ml: isMobile ? 1 : menuOpen ? 36 : 14,
          mr: isMobile ? 1 : 6,
          mt: isMobile ? 1 : undefined,
        }}
      >
        <Outlet />
      </MainContentStyled>
      <Notification />
      <NotificationModal />
      {loading && (
        <Backdrop open={true}>
          <CircularProgress size={128} />
        </Backdrop>
      )}
    </>
  );
});
