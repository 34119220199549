import AdminPages from ".";
import { AppTypeForm, ArrowBackIcon } from "../../components";
import { Grid, IconButton, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { RootListViewStyled } from "./GenericPage.styles";
import { useEffect } from "react";
import { adminActions, useDispatch } from "../../state";
export const AppTypesCreateOrEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(adminActions.getAppTypes());
  }, [dispatch]);

  return (
    <RootListViewStyled>
      <Grid
        container
        spacing={12}
        direction="row"
        justifyContent="space-between"
        paddingBottom={3}
      >
        <Grid item>
          <Typography variant="h3">
            <IconButton
              color="primary"
              onClick={() => navigate(AdminPages.appTypes.path)}
            >
              <ArrowBackIcon />
            </IconButton>
            {id ? "Edit" : "Add"} App Type
          </Typography>
          <AppTypeForm />
        </Grid>
      </Grid>
    </RootListViewStyled>
  );
};
