export const CountryCodes = [
  { country_code: "+1", flag: "🇨🇦", country: "CA", label: "Canada" },
  { country_code: "+1", flag: "🇺🇸", country: "US", label: "United States" },
  { country_code: "+972", flag: "🇮🇱", country: "IL", label: "Israel" },
  { country_code: "+44", flag: "🇬🇧", country: "GB", label: "United Kingdom" },
  { country_code: "+1242", flag: "🇧🇸", country: "BS", label: "Bahamas" },
  { country_code: "+1246", flag: "🇧🇧", country: "BB", label: "Barbados" },
  { country_code: "+1264", flag: "🇦🇮", country: "AI", label: "Anguilla" },
  {
    country_code: "+1268",
    flag: "🇦🇬",
    country: "AG",
    label: "Antigua and Barbuda",
  },
  {
    country_code: "+1284",
    flag: "🇻🇬",
    country: "VG",
    label: "Virgin Islands, British",
  },
  {
    country_code: "+1340",
    flag: "🇻🇮",
    country: "VI",
    label: "Virgin Islands, U.S.",
  },
  { country_code: "+1441", flag: "🇧🇲", country: "BM", label: "Bermuda" },
  { country_code: "+1473", flag: "🇬🇩", country: "GD", label: "Grenada" },
  {
    country_code: "+1649",
    flag: "🇹🇨",
    country: "TC",
    label: "Turks and Caicos Islands",
  },
  { country_code: "+1664", flag: "🇲🇸", country: "MS", label: "Montserrat" },
  {
    country_code: "+1670",
    flag: "🇲🇵",
    country: "MP",
    label: "Northern Mariana Islands",
  },
  { country_code: "+1671", flag: "🇬🇺", country: "GU", label: "Guam" },
  { country_code: "+1684", flag: "🇦🇸", country: "AS", label: "American Samoa" },
  { country_code: "+1758", flag: "🇱🇨", country: "LC", label: "Saint Lucia" },
  { country_code: "+1767", flag: "🇩🇲", country: "DM", label: "Dominica" },
  {
    country_code: "+1784",
    flag: "🇻🇨",
    country: "VC",
    label: "Saint Vincent and the Grenadines",
  },
  {
    country_code: "+1849",
    flag: "🇩🇴",
    country: "DO",
    label: "Dominican Republic",
  },
  {
    country_code: "+1868",
    flag: "🇹🇹",
    country: "TT",
    label: "Trinidad and Tobago",
  },
  {
    country_code: "+1869",
    flag: "🇰🇳",
    country: "KN",
    label: "Saint Kitts and Nevis",
  },
  { country_code: "+1876", flag: "🇯🇲", country: "JM", label: "Jamaica" },
  { country_code: "+1939", flag: "🇵🇷", country: "PR", label: "Puerto Rico" },
  { country_code: "+20", flag: "🇪🇬", country: "EG", label: "Egypt" },
  { country_code: "+211", flag: "🇸🇸", country: "SS", label: "South Sudan" },
  { country_code: "+212", flag: "🇲🇦", country: "MA", label: "Morocco" },
  { country_code: "+213", flag: "🇩🇿", country: "DZ", label: "Algeria" },
  { country_code: "+216", flag: "🇹🇳", country: "TN", label: "Tunisia" },
  {
    country_code: "+218",
    flag: "🇱🇾",
    country: "LY",
    label: "Libyan Arab Jamahiriya",
  },
  { country_code: "+220", flag: "🇬🇲", country: "GM", label: "Gambia" },
  { country_code: "+221", flag: "🇸🇳", country: "SN", label: "Senegal" },
  { country_code: "+222", flag: "🇲🇷", country: "MR", label: "Mauritania" },
  { country_code: "+223", flag: "🇲🇱", country: "ML", label: "Mali" },
  { country_code: "+224", flag: "🇬🇳", country: "GN", label: "Guinea" },
  { country_code: "+225", flag: "🇨🇮", country: "CI", label: "Cote d'Ivoire" },
  { country_code: "+226", flag: "🇧🇫", country: "BF", label: "Burkina Faso" },
  { country_code: "+227", flag: "🇳🇪", country: "NE", label: "Niger" },
  { country_code: "+228", flag: "🇹🇬", country: "TG", label: "Togo" },
  { country_code: "+229", flag: "🇧🇯", country: "BJ", label: "Benin" },
  { country_code: "+230", flag: "🇲🇺", country: "MU", label: "Mauritius" },
  { country_code: "+231", flag: "🇱🇷", country: "LR", label: "Liberia" },
  { country_code: "+232", flag: "🇸🇱", country: "SL", label: "Sierra Leone" },
  { country_code: "+233", flag: "🇬🇭", country: "GH", label: "Ghana" },
  { country_code: "+234", flag: "🇳🇬", country: "NG", label: "Nigeria" },
  { country_code: "+235", flag: "🇹🇩", country: "TD", label: "Chad" },
  {
    country_code: "+236",
    flag: "🇨🇫",
    country: "CF",
    label: "Central African Republic",
  },
  { country_code: "+237", flag: "🇨🇲", country: "CM", label: "Cameroon" },
  { country_code: "+238", flag: "🇨🇻", country: "CV", label: "Cape Verde" },
  {
    country_code: "+239",
    flag: "🇸🇹",
    country: "ST",
    label: "Sao Tome and Principe",
  },
  {
    country_code: "+240",
    flag: "🇬🇶",
    country: "GQ",
    label: "Equatorial Guinea",
  },
  { country_code: "+241", flag: "🇬🇦", country: "GA", label: "Gabon" },
  { country_code: "+242", flag: "🇨🇬", country: "CG", label: "Congo" },
  {
    country_code: "+243",
    flag: "🇨🇩",
    country: "CD",
    label: "Congo, The Democratic Republic of the Congo",
  },
  { country_code: "+244", flag: "🇦🇴", country: "AO", label: "Angola" },
  { country_code: "+245", flag: "🇬🇼", country: "GW", label: "Guinea-Bissau" },
  {
    country_code: "+246",
    flag: "🇮🇴",
    country: "IO",
    label: "British Indian Ocean Territory",
  },
  { country_code: "+248", flag: "🇸🇨", country: "SC", label: "Seychelles" },
  { country_code: "+249", flag: "🇸🇩", country: "SD", label: "Sudan" },
  { country_code: "+250", flag: "🇷🇼", country: "RW", label: "Rwanda" },
  { country_code: "+251", flag: "🇪🇹", country: "ET", label: "Ethiopia" },
  { country_code: "+252", flag: "🇸🇴", country: "SO", label: "Somalia" },
  { country_code: "+253", flag: "🇩🇯", country: "DJ", label: "Djibouti" },
  { country_code: "+254", flag: "🇰🇪", country: "KE", label: "Kenya" },
  {
    country_code: "+255",
    flag: "🇹🇿",
    country: "TZ",
    label: "Tanzania, United Republic of Tanzania",
  },
  { country_code: "+256", flag: "🇺🇬", country: "UG", label: "Uganda" },
  { country_code: "+257", flag: "🇧🇮", country: "BI", label: "Burundi" },
  { country_code: "+258", flag: "🇲🇿", country: "MZ", label: "Mozambique" },
  { country_code: "+260", flag: "🇿🇲", country: "ZM", label: "Zambia" },
  { country_code: "+261", flag: "🇲🇬", country: "MG", label: "Madagascar" },
  {
    country_code: "+262",
    flag: "🇹🇫",
    country: "TF",
    label: "French Southern Territories",
  },
  { country_code: "+262", flag: "🇾🇹", country: "YT", label: "Mayotte" },
  { country_code: "+262", flag: "🇷🇪", country: "RE", label: "Reunion" },
  { country_code: "+263", flag: "🇿🇼", country: "ZW", label: "Zimbabwe" },
  { country_code: "+264", flag: "🇳🇦", country: "NA", label: "Namibia" },
  { country_code: "+265", flag: "🇲🇼", country: "MW", label: "Malawi" },
  { country_code: "+266", flag: "🇱🇸", country: "LS", label: "Lesotho" },
  { country_code: "+267", flag: "🇧🇼", country: "BW", label: "Botswana" },
  { country_code: "+268", flag: "🇸🇿", country: "SZ", label: "Swaziland" },
  { country_code: "+269", flag: "🇰🇲", country: "KM", label: "Comoros" },
  { country_code: "+27", flag: "🇿🇦", country: "ZA", label: "South Africa" },
  {
    country_code: "+290",
    flag: "🇸🇭",
    country: "SH",
    label: "Saint Helena, Ascension and Tristan Da Cunha",
  },
  { country_code: "+291", flag: "🇪🇷", country: "ER", label: "Eritrea" },
  { country_code: "+297", flag: "🇦🇼", country: "AW", label: "Aruba" },
  { country_code: "+298", flag: "🇫🇴", country: "FO", label: "Faroe Islands" },
  { country_code: "+299", flag: "🇬🇱", country: "GL", label: "Greenland" },
  { country_code: "+30", flag: "🇬🇷", country: "GR", label: "Greece" },
  { country_code: "+31", flag: "🇳🇱", country: "NL", label: "Netherlands" },
  { country_code: "+32", flag: "🇧🇪", country: "BE", label: "Belgium" },
  { country_code: "+33", flag: "🇫🇷", country: "FR", label: "France" },
  { country_code: "+34", flag: "🇪🇸", country: "ES", label: "Spain" },
  { country_code: "+345", flag: "🇰🇾", country: "KY", label: "Cayman Islands" },
  { country_code: "+350", flag: "🇬🇮", country: "GI", label: "Gibraltar" },
  { country_code: "+351", flag: "🇵🇹", country: "PT", label: "Portugal" },
  { country_code: "+352", flag: "🇱🇺", country: "LU", label: "Luxembourg" },
  { country_code: "+353", flag: "🇮🇪", country: "IE", label: "Ireland" },
  { country_code: "+354", flag: "🇮🇸", country: "IS", label: "Iceland" },
  { country_code: "+355", flag: "🇦🇱", country: "AL", label: "Albania" },
  { country_code: "+356", flag: "🇲🇹", country: "MT", label: "Malta" },
  { country_code: "+357", flag: "🇨🇾", country: "CY", label: "Cyprus" },
  { country_code: "+358", flag: "🇦🇽", country: "AX", label: "Åland Islands" },
  { country_code: "+358", flag: "🇫🇮", country: "FI", label: "Finland" },
  { country_code: "+359", flag: "🇧🇬", country: "BG", label: "Bulgaria" },
  { country_code: "+36", flag: "🇭🇺", country: "HU", label: "Hungary" },
  { country_code: "+370", flag: "🇱🇹", country: "LT", label: "Lithuania" },
  { country_code: "+371", flag: "🇱🇻", country: "LV", label: "Latvia" },
  { country_code: "+372", flag: "🇪🇪", country: "EE", label: "Estonia" },
  { country_code: "+373", flag: "🇲🇩", country: "MD", label: "Moldova" },
  { country_code: "+374", flag: "🇦🇲", country: "AM", label: "Armenia" },
  { country_code: "+375", flag: "🇧🇾", country: "BY", label: "Belarus" },
  { country_code: "+376", flag: "🇦🇩", country: "AD", label: "Andorra" },
  { country_code: "+377", flag: "🇲🇨", country: "MC", label: "Monaco" },
  { country_code: "+378", flag: "🇸🇲", country: "SM", label: "San Marino" },
  {
    country_code: "+379",
    flag: "🇻🇦",
    country: "VA",
    label: "Holy See (Vatican City State)",
  },
  { country_code: "+380", flag: "🇺🇦", country: "UA", label: "Ukraine" },
  { country_code: "+381", flag: "🇷🇸", country: "RS", label: "Serbia" },
  { country_code: "+382", flag: "🇲🇪", country: "ME", label: "Montenegro" },
  { country_code: "+383", flag: "🇽🇰", country: "XK", label: "Kosovo" },
  { country_code: "+385", flag: "🇭🇷", country: "HR", label: "Croatia" },
  { country_code: "+386", flag: "🇸🇮", country: "SI", label: "Slovenia" },
  {
    country_code: "+387",
    flag: "🇧🇦",
    country: "BA",
    label: "Bosnia and Herzegovina",
  },
  { country_code: "+389", flag: "🇲🇰", country: "MK", label: "North Macedonia" },
  { country_code: "+39", flag: "🇮🇹", country: "IT", label: "Italy" },
  { country_code: "+40", flag: "🇷🇴", country: "RO", label: "Romania" },
  { country_code: "+41", flag: "🇨🇭", country: "CH", label: "Switzerland" },
  { country_code: "+420", flag: "🇨🇿", country: "CZ", label: "Czech Republic" },
  { country_code: "+421", flag: "🇸🇰", country: "SK", label: "Slovakia" },
  { country_code: "+423", flag: "🇱🇮", country: "LI", label: "Liechtenstein" },
  { country_code: "+43", flag: "🇦🇹", country: "AT", label: "Austria" },
  { country_code: "+44", flag: "🇬🇬", country: "GG", label: "Guernsey" },
  { country_code: "+44", flag: "🇮🇲", country: "IM", label: "Isle of Man" },
  { country_code: "+44", flag: "🇯🇪", country: "JE", label: "Jersey" },
  { country_code: "+45", flag: "🇩🇰", country: "DK", label: "Denmark" },
  { country_code: "+46", flag: "🇸🇪", country: "SE", label: "Sweden" },
  { country_code: "+47", flag: "🇧🇻", country: "BV", label: "Bouvet Island" },
  { country_code: "+47", flag: "🇳🇴", country: "NO", label: "Norway" },
  {
    country_code: "+47",
    flag: "🇸🇯",
    country: "SJ",
    label: "Svalbard and Jan Mayen",
  },
  { country_code: "+48", flag: "🇵🇱", country: "PL", label: "Poland" },
  { country_code: "+49", flag: "🇩🇪", country: "DE", label: "Germany" },
  {
    country_code: "+500",
    flag: "🇫🇰",
    country: "FK",
    label: "Falkland Islands (Malvinas)",
  },
  {
    country_code: "+500",
    flag: "🇬🇸",
    country: "GS",
    label: "South Georgia and the South Sandwich Islands",
  },
  { country_code: "+501", flag: "🇧🇿", country: "BZ", label: "Belize" },
  { country_code: "+502", flag: "🇬🇹", country: "GT", label: "Guatemala" },
  { country_code: "+503", flag: "🇸🇻", country: "SV", label: "El Salvador" },
  { country_code: "+504", flag: "🇭🇳", country: "HN", label: "Honduras" },
  { country_code: "+505", flag: "🇳🇮", country: "NI", label: "Nicaragua" },
  { country_code: "+506", flag: "🇨🇷", country: "CR", label: "Costa Rica" },
  { country_code: "+507", flag: "🇵🇦", country: "PA", label: "Panama" },
  {
    country_code: "+508",
    flag: "🇵🇲",
    country: "PM",
    label: "Saint Pierre and Miquelon",
  },
  { country_code: "+509", flag: "🇭🇹", country: "HT", label: "Haiti" },
  { country_code: "+51", flag: "🇵🇪", country: "PE", label: "Peru" },
  { country_code: "+52", flag: "🇲🇽", country: "MX", label: "Mexico" },
  { country_code: "+53", flag: "🇨🇺", country: "CU", label: "Cuba" },
  { country_code: "+54", flag: "🇦🇷", country: "AR", label: "Argentina" },
  { country_code: "+55", flag: "🇧🇷", country: "BR", label: "Brazil" },
  { country_code: "+56", flag: "🇨🇱", country: "CL", label: "Chile" },
  { country_code: "+57", flag: "🇨🇴", country: "CO", label: "Colombia" },
  {
    country_code: "+58",
    flag: "🇻🇪",
    country: "VE",
    label: "Venezuela, Bolivarian Republic of Venezuela",
  },
  { country_code: "+590", flag: "🇬🇵", country: "GP", label: "Guadeloupe" },
  {
    country_code: "+590",
    flag: "🇧🇱",
    country: "BL",
    label: "Saint Barthelemy",
  },
  { country_code: "+590", flag: "🇲🇫", country: "MF", label: "Saint Martin" },
  {
    country_code: "+591",
    flag: "🇧🇴",
    country: "BO",
    label: "Bolivia, Plurinational State of bolivia",
  },
  { country_code: "+592", flag: "🇬🇾", country: "GY", label: "Guyana" },
  { country_code: "+593", flag: "🇪🇨", country: "EC", label: "Ecuador" },
  { country_code: "+594", flag: "🇬🇫", country: "GF", label: "French Guiana" },
  { country_code: "+595", flag: "🇵🇾", country: "PY", label: "Paraguay" },
  { country_code: "+596", flag: "🇲🇶", country: "MQ", label: "Martinique" },
  { country_code: "+597", flag: "🇸🇷", country: "SR", label: "Suriname" },
  { country_code: "+598", flag: "🇺🇾", country: "UY", label: "Uruguay" },
  {
    country_code: "+599",
    flag: "	",
    country: "N	",
    label: "etherlands Antilles",
  },
  { country_code: "+60", flag: "🇲🇾", country: "MY", label: "Malaysia" },
  { country_code: "+61", flag: "🇦🇺", country: "AU", label: "Australia" },
  { country_code: "+61", flag: "🇨🇽", country: "CX", label: "Christmas Island" },
  {
    country_code: "+61",
    flag: "🇨🇨",
    country: "CC",
    label: "Cocos (Keeling) Islands",
  },
  { country_code: "+62", flag: "🇮🇩", country: "ID", label: "Indonesia" },
  { country_code: "+63", flag: "🇵🇭", country: "PH", label: "Philippines" },
  { country_code: "+64", flag: "🇳🇿", country: "NZ", label: "New Zealand" },
  { country_code: "+64", flag: "🇵🇳", country: "PN", label: "Pitcairn" },
  { country_code: "+65", flag: "🇸🇬", country: "SG", label: "Singapore" },
  { country_code: "+66", flag: "🇹🇭", country: "TH", label: "Thailand" },
  { country_code: "+670", flag: "🇹🇱", country: "TL", label: "Timor-Leste" },
  { country_code: "+672", flag: "🇦🇶", country: "AQ", label: "Antarctica" },
  {
    country_code: "+672",
    flag: "🇭🇲",
    country: "HM",
    label: "Heard Island and Mcdonald Islands",
  },
  { country_code: "+672", flag: "🇳🇫", country: "NF", label: "Norfolk Island" },
  {
    country_code: "+673",
    flag: "🇧🇳",
    country: "BN",
    label: "Brunei Darussalam",
  },
  { country_code: "+674", flag: "🇳🇷", country: "NR", label: "Nauru" },
  {
    country_code: "+675",
    flag: "🇵🇬",
    country: "PG",
    label: "Papua New Guinea",
  },
  { country_code: "+676", flag: "🇹🇴", country: "TO", label: "Tonga" },
  { country_code: "+677", flag: "🇸🇧", country: "SB", label: "Solomon Islands" },
  { country_code: "+678", flag: "🇻🇺", country: "VU", label: "Vanuatu" },
  { country_code: "+679", flag: "🇫🇯", country: "FJ", label: "Fiji" },
  { country_code: "+680", flag: "🇵🇼", country: "PW", label: "Palau" },
  {
    country_code: "+681",
    flag: "🇼🇫",
    country: "WF",
    label: "Wallis and Futuna",
  },
  { country_code: "+682", flag: "🇨🇰", country: "CK", label: "Cook Islands" },
  { country_code: "+683", flag: "🇳🇺", country: "NU", label: "Niue" },
  { country_code: "+685", flag: "🇼🇸", country: "WS", label: "Samoa" },
  { country_code: "+686", flag: "🇰🇮", country: "KI", label: "Kiribati" },
  { country_code: "+687", flag: "🇳🇨", country: "NC", label: "New Caledonia" },
  { country_code: "+688", flag: "🇹🇻", country: "TV", label: "Tuvalu" },
  {
    country_code: "+689",
    flag: "🇵🇫",
    country: "PF",
    label: "French Polynesia",
  },
  { country_code: "+690", flag: "🇹🇰", country: "TK", label: "Tokelau" },
  {
    country_code: "+691",
    flag: "🇫🇲",
    country: "FM",
    label: "Micronesia, Federated States of Micronesia",
  },
  {
    country_code: "+692",
    flag: "🇲🇭",
    country: "MH",
    label: "Marshall Islands",
  },
  { country_code: "+7", flag: "🇰🇿", country: "KZ", label: "Kazakhstan" },
  { country_code: "+7", flag: "🇷🇺", country: "RU", label: "Russia" },
  { country_code: "+81", flag: "🇯🇵", country: "JP", label: "Japan" },
  {
    country_code: "+82",
    flag: "🇰🇷",
    country: "KR",
    label: "Korea, Republic of South Korea",
  },
  { country_code: "+84", flag: "🇻🇳", country: "VN", label: "Vietnam" },
  {
    country_code: "+850",
    flag: "🇰🇵",
    country: "KP",
    label: "Korea, Democratic People's Republic of Korea",
  },
  { country_code: "+852", flag: "🇭🇰", country: "HK", label: "Hong Kong" },
  { country_code: "+853", flag: "🇲🇴", country: "MO", label: "Macao" },
  { country_code: "+855", flag: "🇰🇭", country: "KH", label: "Cambodia" },
  { country_code: "+856", flag: "🇱🇦", country: "LA", label: "Laos" },
  { country_code: "+86", flag: "🇨🇳", country: "CN", label: "China" },
  { country_code: "+880", flag: "🇧🇩", country: "BD", label: "Bangladesh" },
  { country_code: "+886", flag: "🇹🇼", country: "TW", label: "Taiwan" },
  { country_code: "+90", flag: "🇹🇷", country: "TR", label: "Türkiye" },
  { country_code: "+91", flag: "🇮🇳", country: "IN", label: "India" },
  { country_code: "+92", flag: "🇵🇰", country: "PK", label: "Pakistan" },
  { country_code: "+93", flag: "🇦🇫", country: "AF", label: "Afghanistan" },
  { country_code: "+94", flag: "🇱🇰", country: "LK", label: "Sri Lanka" },
  { country_code: "+95", flag: "🇲🇲", country: "MM", label: "Myanmar" },
  { country_code: "+960", flag: "🇲🇻", country: "MV", label: "Maldives" },
  { country_code: "+961", flag: "🇱🇧", country: "LB", label: "Lebanon" },
  { country_code: "+962", flag: "🇯🇴", country: "JO", label: "Jordan" },
  {
    country_code: "+963",
    flag: "🇸🇾",
    country: "SY",
    label: "Syrian Arab Republic",
  },
  { country_code: "+964", flag: "🇮🇶", country: "IQ", label: "Iraq" },
  { country_code: "+965", flag: "🇰🇼", country: "KW", label: "Kuwait" },
  { country_code: "+966", flag: "🇸🇦", country: "SA", label: "Saudi Arabia" },
  { country_code: "+967", flag: "🇾🇪", country: "YE", label: "Yemen" },
  { country_code: "+968", flag: "🇴🇲", country: "OM", label: "Oman" },
  {
    country_code: "+970",
    flag: "🇵🇸",
    country: "PS",
    label: "Palestinian Territory, Occupied",
  },
  {
    country_code: "+971",
    flag: "🇦🇪",
    country: "AE",
    label: "United Arab Emirates",
  },
  { country_code: "+973", flag: "🇧🇭", country: "BH", label: "Bahrain" },
  { country_code: "+974", flag: "🇶🇦", country: "QA", label: "Qatar" },
  { country_code: "+975", flag: "🇧🇹", country: "BT", label: "Bhutan" },
  { country_code: "+976", flag: "🇲🇳", country: "MN", label: "Mongolia" },
  { country_code: "+977", flag: "🇳🇵", country: "NP", label: "Nepal" },
  {
    country_code: "+98",
    flag: "🇮🇷",
    country: "IR",
    label: "Iran, Islamic Republic of Persian Gulf",
  },
  { country_code: "+992", flag: "🇹🇯", country: "TJ", label: "Tajikistan" },
  { country_code: "+993", flag: "🇹🇲", country: "TM", label: "Turkmenistan" },
  { country_code: "+994", flag: "🇦🇿", country: "AZ", label: "Azerbaijan" },
  { country_code: "+995", flag: "🇬🇪", country: "GE", label: "Georgia" },
  { country_code: "+996", flag: "🇰🇬", country: "KG", label: "Kyrgyzstan" },
  { country_code: "+998", flag: "🇺🇿", country: "UZ", label: "Uzbekistan" },
  { country_code: "", flag: "", country: "", label: "" },
];
