import { useEffect, useState } from "react";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors } from "../../state";
import AdminPages from ".";
import { AddButton, ToolBar } from "../../components";
import { ActionBox, RootListViewStyled } from "./GenericPage.styles";
import { MENU_COLUMNS } from "../../components/tables/columns";
import { debounce, TextField, Typography, Grid } from "@mui/material";
import { useMobile } from "../../themes";

export const MenusPage = () => {
  const dispatch = useDispatch();
  const menus = useSelector(adminSelectors.menus);
  const apiRef = useGridApiRef();
  const [pageSize, setPageSize] = useState(25);
  const isMobile = useMobile();
  useEffect(() => {
    dispatch(adminActions.getMenus());
  }, [dispatch]);

  const handleQuickSearch = (e) =>
    apiRef.current.setQuickFilterValues([e.target.value]);

  return (
    <RootListViewStyled>
      <Grid alignItems={"center"} container spacing={4}>
        <Grid item xs={12} md={3}>
          <Typography variant="h3">Menus</Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            fullWidth
            placeholder="Search for Menus"
            onChange={debounce(handleQuickSearch, 300)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          display={isMobile ? "block" : "flex"}
          justifyContent={"flex-end"}
        >
          <ActionBox sx={{ position: "inherit" }}>
            <AddButton label={"Add Menu"} to={AdminPages.menuCreate.path} />
          </ActionBox>
        </Grid>
      </Grid>

      <DataGridPro
        autoHeight
        apiRef={apiRef}
        rows={menus}
        columns={MENU_COLUMNS}
        pagination
        rowsPerPageOptions={[10, 25, 50, 100, 200]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          Toolbar: ToolBar,
        }}
        // componentsProps={{
        //   toolbar: {
        //     showSavePref: true,
        //     apiRef,
        //   },
        // }}
      />
    </RootListViewStyled>
  );
};
