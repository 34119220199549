import { Typography } from "@mui/material";
import { RootListViewStyled } from "./GenericPage.styles";
import { REPORTS_COLUMNS } from "../../components/tables/columns";
import { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  adminActions,
  adminSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { ToolBar } from "../../components";
import { generatePath, useNavigate } from "react-router-dom";
import AdminPages from ".";

export const ReportsPage = () => {
  const nav = useNavigate();
  const [pageSize, setPageSize] = useState(25);

  const dispatch = useDispatch();
  const reports = useSelector(adminSelectors.reports);

  useEffect(() => {
    dispatch(adminActions.getReports());
  }, [dispatch]);

  return (
    <RootListViewStyled>
      <Typography variant="h3" mb={3}>
        Reports
      </Typography>

      {reports && reports.rows && (
        <DataGridPro
          rows={reports.rows}
          columns={REPORTS_COLUMNS}
          pagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onRowClick={(row) =>
            nav(
              generatePath(AdminPages.reportsView.path, {
                id: row.id.toString(),
              }),
            )
          }
          components={{
            Toolbar: ToolBar,
          }}
          componentsProps={{
            toolbar: {
              showQuickSearch: true,
              showAddButton: false,
            },
          }}
        />
      )}
    </RootListViewStyled>
  );
};
