import {
  Box,
  Button,
  Divider,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Formik, Form, FormikValues, useFormikContext } from "formik";
import { InputField, SelectField, DatePicker } from "../inputs/InputField";
import { pathEquals, yup } from "../../lib";
import {
  adminActions,
  adminSelectors,
  Child,
  customerSelectors,
  uiActions,
} from "../../state";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMobile } from "../../themes";
import { parse, isDate, format } from "date-fns";
// import { childrenAmount } from "./SubscriberInfoForm";
import { formatPhone } from "../../lib";
import { useParams } from "react-router-dom";

function parseDateString(value) {
  const parsedDate = isDate(value)
    ? value
    : parse(value, "yyyy-MM-dd", new Date());

  return parsedDate;
}

const formatDate = (date: Date | undefined | null | string) => {
  if (date && typeof date === "object") {
    return format(date, "yyyy-MM-dd");
  } else if (typeof date === "string") {
    return date;
  } else {
    return format(new Date(), "yyyy-MM-dd");
  }
};

interface Props {
  title?: string | boolean;
  isModal?: boolean;
  closeModal?: () => void;
  changeFunction?: (v) => void;
  activeToggle?: boolean;
  saveButton?: boolean;
  mode: "admin" | "customer";
  index?: number;
  sx?: any;
  subscription_filter?: number | string;
}

const yesterday = new Date().setDate(new Date().getDate() - 1)

export const ChildForm = ({
  title = "Add Child",
  isModal = false,
  changeFunction,
  closeModal,
  activeToggle = true,
  saveButton = true,
  mode,
  subscription_filter = "",
  index,
  sx,
}: Props) => {
  const validationSchemaObject = {
    first_name: yup
      .string()
      .matches(
        /^[A-Za-z ]*$/,
        "If your name contains numbers please contact customer service",
      )
      .when("pin", {
        is: (fn) => fn && fn.trim() !== "",
        then: yup.string().required("first name is required"),
        otherwise: yup.string().optional().nullable(),
      }),
    last_name: yup
      .string()
      .matches(
        /^[A-Za-z ]*$/,
        "If your name contains numbers please contact customer service",
      )
      .required("Please enter child last name"),
    dob: yup
      .date()
      .transform(parseDateString)
      .max(new Date(yesterday), "Incorrect Birthday")
      .required("Please enter a dob"),
    gender: yup.string().required("Please select a gender"),
    pin: yup
      .string()
      .matches(/^\d+$/, "A pin should only include numbers")
      .min(3, "pin should be 3 digits long")
      .max(3, "pin should be 3 digits long")
      .when("first_name", {
        is: (fn) => fn && fn.trim() !== "",
        then: yup.string().required("pin is required"),
        otherwise: yup.string().optional().nullable(),
      }),
    active: yup.boolean().required(),
    subscription_id: yup.number().transform((value) => Number.isNaN(value) ? null : value)
      .nullable(),
  };
  if (mode === "admin") {
    validationSchemaObject.subscription_id = yup
      .number()
      .moreThan(0, "Subscription Required")
      .required("Subscription is Required");
  }
  const validationSchemaRequired = yup
    .object()
    .shape(validationSchemaObject, [["first_name", "pin"]]);

  const dispatch = useDispatch();
  const param = useParams();
  const customer =
    mode === "admin"
      ? useSelector(adminSelectors.customer)
      : useSelector(customerSelectors.customer).customer;

  const customerSubscriptions = useSelector(
    adminSelectors.customerSubscriptions,
  );
  const [editMode, setEditMode] = useState(false);

  const isMobile = useMobile();
  const _initialValues = {
    first_name: "",
    last_name: customer.user?.last_name ?? "",
    customer_id: customer.id,
    dob: format(new Date(), "yyyy-MM-dd"),
    gender: "male",
    active: activeToggle ?? true,
    pin: "",
    subscription_id: subscription_filter ?? 0,
  } as Partial<Child>;
  const [initialValues, setInitialValues] = useState(_initialValues);
  const currentChild = useSelector(adminSelectors.childInfo);
  const f = useFormikContext<FormikValues>();

  const getChildCount = () => {
    const customerChildren = customer?.customer_children;
    const foundChild = customerChildren?.findIndex(
      (c) => c.id === currentChild.id,
    );
    const r =
      foundChild !== -1 && foundChild
        ? foundChild
        : customerChildren
          ? customerChildren.length
          : 0;
    return r + 1;
  };
  let whichChild: Child | undefined;
  if (Array.isArray(customer?.customer_children) && index) {
    // since index is possibly undefined 0 wasn't coming through so I added 1 in subscriber form
    const num = Number(index) - 1;
    whichChild = customer?.customer_children?.at(num);
  }
  useEffect(() => {
    (async () => {
      if (mode === "admin" && customer.id) {
        dispatch(adminActions.getCustomer(customer.id));
      }
      if (currentChild) {
        setInitialValues({
          ...currentChild,
          last_name: currentChild.last_name || customer.user?.last_name || "",
          dob: currentChild.dob || format(new Date(), "yyyy-MM-dd"),
          gender: currentChild.gender || "male",
          subscription_id:
            currentChild.subscription_id ?? subscription_filter,
          customer_id: customer.id,
        });
      }
      if (whichChild && mode === "admin") {
        if (whichChild.dob) {
          setInitialValues({
            ...whichChild,
            dob: format(new Date(whichChild.dob), "yyyy-MM-dd"),
            customer_id: customer.id,
            subscription_id: currentChild.subscription_id ?? 0,
          });
        }
        Object.keys(whichChild).map((k) => {
          changeFunction &&
            changeFunction({
              name: k,
              value: whichChild && whichChild[k],
            });
        });
      }
      if (currentChild.id || (whichChild && whichChild.id)) {
        setEditMode(true);
      } else {
        setEditMode(false);
      }
    })();
  }, [dispatch, currentChild, index]);

  const handleSubmit = (_values, { setSubmitting }) => {
    const values = {
      ..._values,
      dob: formatDate(_values.dob),
      subscription_id: param.subscription_id ? +param.subscription_id : _values.subscription_id,
    };
    const customerChildSamePin = customer.customer_children
      ?.filter(
        (c) =>
          c.id !== currentChild.id &&
          c.subscription_id === currentChild.subscription_id,
      )
      ?.map((c) => c.pin)
      .indexOf(values.pin);

    if (customerChildSamePin && customerChildSamePin > 0) {
      dispatch(
        uiActions.showError("Failed to save, There is a child with same pin"),
      );
    } else {
      if (editMode) {
        dispatch(adminActions.updateChild(currentChild.id, values, mode));
      } else {
        dispatch(adminActions.createChild(values, mode));
      }
      setSubmitting(false);
    }
    isModal && closeModal && closeModal();
  };

  const pinArr: {
    name: string;
    pin: string;
    fn: string;
  }[] = [];
  let curPin: string;
  let curFirst: string;
  // Dont think we need anymore - no more checkout
  // if (mode === "customer" && !pathEquals("/manage-loop")) {
  //   pinArr = createNumberArr(childrenAmount)
  //     .map((n) => {
  //       return {
  //         name: `${n}`,
  //         pin: f.values[`child_${n}_pin`],
  //         fn: f.values[`child_${n}_first_name`],
  //       };
  //     })
  //     .filter((p) => p.pin !== "" || p.fn.trim() !== "");
  //   const newestInsert =
  //     pinArr?.length > 1 ? pinArr[pinArr.length - 1].name : 1;
  //   curPin =
  //     f.values[`child_${newestInsert}_pin`] &&
  //     f.values[`child_${newestInsert}_pin`].trim();
  //   curFirst =
  //     f.values[`child_${newestInsert}_first_name`] &&
  //     f.values[`child_${newestInsert}_first_name`].trim();
  // }
  // unique pins
  const pinSet = new Set(pinArr?.map((p) => p.pin)) ?? new Set();

  const allPinsAreUnique = pinSet.size === pinArr?.length;

  return (
    <>
      {title && (
        <Typography id="child-form-title" variant="h4">
          {editMode
            ? `Edit ${currentChild.first_name}`
            : `Add ${getChildCount()}`}
        </Typography>
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchemaRequired}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          values,
          setFieldValue,
          errors,
          setErrors,
          setTouched,
          touched,
        }) => (
          <Form
            //setFieldValues doesn't trigger onChange
            onBlur={() => {
              if (whichChild && mode === "admin") {
                Object.keys(whichChild).map((k) => {
                  changeFunction &&
                    changeFunction({
                      name: k,
                      value: whichChild && whichChild[k],
                    });
                });
              }
              changeFunction &&
                (changeFunction({
                  name: "dob",
                  value: formatDate(values.dob),
                }),
                  changeFunction({
                    name: "gender",
                    value: values.gender === "" ? "male" : values.gender,
                  }));
              // if duplicate pin then set newest val empty
              if (!allPinsAreUnique) {
                const firstFound: typeof pinArr = [];
                const foundDupe = pinArr
                  ?.map((p) => {
                    if (!pinSet.has(p.pin)) {
                      return p;
                    } else {
                      firstFound.push(p);
                      pinSet.delete(p.pin);
                    }
                  })
                  .filter(Boolean);
                // since this will run each time there should never be more than one duplicate at a time
                // this should clear the currently open child
                if (foundDupe?.length && foundDupe[0]?.name !== "1") {
                  setErrors({
                    ...errors,
                    pin: "Duplicate PIN not allowed",
                  });
                  f.setErrors({
                    ...f.errors,
                    [`child_${index}_pin`]: "Duplicate PIN not allowed",
                  });
                  //Wait since yup will clear this out
                  setTimeout(() => {
                    setErrors({
                      ...errors,
                      pin: "Duplicate PIN not allowed",
                    });
                    f.setErrors({
                      ...f.errors,
                      [`child_${index}_pin`]: "Duplicate PIN not allowed",
                    });
                  }, 500);
                }
              }
              if (mode === "customer" && !pathEquals("/manage-loop")) {
                const testDependents = (
                  val1: string,
                  val2: string,
                  fieldEdited: string,
                  errMsg: string,
                ) => {
                  const field = `child_${index}_${fieldEdited}`;
                  // doesn't have val1 or val1 is empty and val2 is not empty then
                  if (
                    (!val1 || (val1 && val1.trim() === "")) &&
                    val2 &&
                    val2.trim() !== ""
                  ) {
                    // set the local form and referenced form
                    setFieldValue(fieldEdited, "", true);
                    setTouched({
                      ...touched,
                      [fieldEdited]: true,
                    });
                    setErrors({
                      ...errors,
                      [fieldEdited]: errMsg,
                    });
                    f.setFieldValue(field, "", true);
                    f.setTouched({
                      ...f.touched,
                      [field]: true,
                    });
                    f.setErrors({
                      ...f.errors,
                      [field]: errMsg,
                    });
                  }
                };
                // pin filled but missing name
                testDependents(
                  curFirst,
                  curPin,
                  "first_name",
                  "first name is required",
                );
                // opposite of above this is missing pin but filled name
                testDependents(curPin, curFirst, "pin", "Pin is required");
              }
            }}
            onChange={({ target }) => {
              changeFunction && changeFunction(target);
            }}
          >
            <Grid alignItems={"center"} container spacing={4}>
              <Grid item xs={12} md={12} sx={{ ...sx }} width={"100%"}>
                <Box
                  display={"flex"}
                  flexDirection={isMobile ? "column" : "row"}
                  gap={isMobile ? 0 : 2}
                >
                  <InputField
                    label="First name*"
                    name="first_name"
                    checkForAutoComplete={true}
                  />
                  <InputField label="Last name" name="last_name" />
                </Box>
                <Box
                  display={"flex"}
                  gap={isMobile ? 0 : 2}
                  alignItems={"center"}
                  flexDirection={isMobile ? "column" : "row"}
                >
                  <DatePicker
                    sx={{ width: "100%", maxWidth: 342 }}
                    label="Birthday"
                    fieldName="dob"
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                  />
                  <ToggleButtonGroup
                    color="primary"
                    sx={{ width: isMobile ? "100%" : "50%", height: "100%" }}
                    value={values.gender}
                    defaultValue={values.gender}
                    exclusive={true}
                    onChange={(_e, v) => {
                      !!v && setFieldValue("gender", v);
                    }}
                    aria-label="male"
                  >
                    <ToggleButton
                      value={"male"}
                      sx={{ width: "50%", height: "50%" }}
                    >
                      Boy
                    </ToggleButton>
                    <ToggleButton
                      value={"female"}
                      sx={{ width: "50%", height: "50%" }}
                    >
                      Girl
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Box
                  display={"flex"}
                  gap={isMobile ? 0 : 2}
                  alignItems={"center"}
                >
                  {activeToggle && mode === "admin" && (
                    <ToggleButtonGroup
                      color="primary"
                      sx={{ width: isMobile ? "100%" : "50%", height: "100%" }}
                      value={values.active}
                      exclusive
                      onChange={(_e, v) => setFieldValue("active", v)}
                      aria-label="male"
                    >
                      <ToggleButton
                        value={true}
                        sx={{ width: "50%", height: "50%" }}
                      >
                        Active
                      </ToggleButton>
                      <ToggleButton
                        value={false}
                        sx={{ width: "50%", height: "50%" }}
                      >
                        Inactive
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                  <Box
                    display={"flex"}
                    sx={{
                      width: isMobile ? "100%" : "48%",
                      alignItems: "flex-end",
                    }}
                  >
                    <InputField
                      label="Pin*"
                      name="pin"
                      helperText="Pin should be 3 digits"
                      checkForAutoComplete={true}
                    />
                  </Box>
                </Box>
                {mode === "admin" && (
                  <SelectField
                    label="Subscription"
                    name="subscription_id"
                    options={
                      customerSubscriptions
                        ?.filter((s) => !!s.active &&
                          s.subscription_plan_id === 10)
                        .map((s, idx) => {
                          return {
                            label:
                              idx + 1 + " - " + ((s?.phone?.phone_number?.length === 10
                                ? formatPhone(s.phone.phone_number)
                                : formatPhone(
                                  s?.phone?.area_code ??
                                  "" + s?.phone?.phone_number,
                                )) ?? "") +
                              " - " +
                              s.shipping_name + " | " + s.provider_subscription_id,
                            id: s.id!,
                          };
                        }) ?? []
                    }
                    initialValues={initialValues}
                  />
                )}
              </Grid>
              <Divider />
              {saveButton && (
                <Grid item xs={12} md={12}>
                  <Button
                    fullWidth
                    sx={{ mt: 3, ml: 0 }}
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
