import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Roles, User } from "../types";

export interface AuthState {
  roles?: Roles[];
  token?: string;
  oldAuth?: Partial<AuthState>;
  userId?: number;
  userName?: string;
  user?: User;
  impersonating?: boolean;
}

const initialState: AuthState = {};

export const auth = {
  ...createSlice({
    name: "auth",
    initialState,
    reducers: {
      setAuthState(state, { payload }: PayloadAction<AuthState | undefined>) {
        if (!payload) {
          return initialState;
        }
        Object.assign(state, payload);
      },
      setImpersonate(state, { payload }: PayloadAction<AuthState | undefined>) {
        if (!payload) {
          return state.oldAuth;
        } else {
          const oldAuth = { ...state };
          return { ...state, ...payload, oldAuth };
        }
      },
      setImpersonateFlag(state, { payload }: PayloadAction<boolean>) {
        state.impersonating = payload;
      },
      clearAuth() {
        return {};
      },
    },
  }),
  persist: true,
};
