import NotFound from "../../assets/img/404.png";

import { RightPanel, RootStyle } from "./HomePage.styles";
import { useMobile } from "../../themes";
import { useTheme } from "@mui/material";
export function NotFoundPage() {
  const isMobile = useMobile();

  const { palette } = useTheme();
  return (
    <RootStyle>
      <RightPanel
        style={{
          backgroundColor: palette.primary.main,
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <img
          width={isMobile ? "90%" : "350px"}
          alt={"404-Page-Not-Found"}
          src={NotFound}
        />
      </RightPanel>
    </RootStyle>
  );
}
