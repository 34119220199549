import { styled } from "@mui/material/styles";

export const RootStyle = styled(`div`)(({ theme }) => ({
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom center",
  backgroundSize: "100%",
  display: "flex",
  height: "100vh",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "space-between",
    flexDirection: "column",
  },
}));

export const LeftPanel = styled(`div`)(({ theme }) => ({
  display: "flex",
  left: 0,
  top: 0,
  bottom: 0,
  [theme.breakpoints.up("xs")]: {
    position: "fixed",
    height: "100%",
    width: "100%",
  },
  [theme.breakpoints.up("md")]: {
    position: "block",
    width: "50%",
  },
  "& img": {
    [theme.breakpoints.up("xs")]: {
      height: "100%",
      minWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "100%",
    },
    maxWidth: "100%",
  },
}));

export const RightPanel = styled(`div`)(({ theme }) => ({
  right: 0,
  gap: 16,

  height: "inherit",
  display: "flex",
  alignItems: "center",

  [theme.breakpoints.up("xs")]: {
    position: "absolute",
    justifyContent: "center",
    width: "100%",
  },
  [theme.breakpoints.up("sm")]: {
    position: "absolute",
    justifyContent: "center",
    width: "100vw",
  },
  [theme.breakpoints.up("md")]: {
    position: "absolute",
    justifyContent: "center",
    width: "calc(100vw - 50%)",
  },

  flexDirection: "column",
}));
export const OutletPanel = styled(`div`)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    width: "70%",
  },
  [theme.breakpoints.up("md")]: {
    width: "50%",
  },
}));
