import { Grid, Typography, Box, Button } from "@mui/material";
import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { yup } from "../../lib";
import { adminActions, adminSelectors, VoicemailBox } from "../../state";
import { InputField } from "../inputs/InputField";
interface Props {
  closeModal: () => void;
}
export const VoicemailBoxForm = ({ closeModal }: Props) => {
  const [editMode, setEditMode] = useState(false);

  const validationSchema = yup.object({
    name: yup.string().required("name is required"),
  });

  const _initialValues = {
    name: "",
    external_name: "",
  } as Partial<VoicemailBox>;

  const [initialValues, setInitialValues] = useState(_initialValues);

  const dispatch = useDispatch();
  const vmBox = useSelector(adminSelectors.voicemailBox);
  useEffect(() => {
    (async () => {
      if (vmBox.id) {
        setInitialValues({ ...vmBox });
        setEditMode(true);
      } else {
        setEditMode(false);
      }
    })();
  }, [dispatch, vmBox]);

  const handleSubmit = (values, { setSubmitting }) => {
    if (editMode) {
      dispatch(adminActions.updateVoicemailBox(vmBox.id, values));
    } else {
      dispatch(adminActions.createVoicemailBox(values));
    }
    setSubmitting(false);
    closeModal();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid
            sx={{
              width: "100%",
              border: "1px solid #E4E7EB",
              borderRadius: 2,
              m: 1,
              mt: 2,
              p: 2,
            }}
            display={"flex"}
            flexDirection="column"
            container
          >
            <Typography variant="h6">
              {editMode ? "Edit Voicemail Box" : "Add Voicemail Box"}
            </Typography>

            <Grid item xs={12}>
              <InputField label={"Name"} name={"name"} />
            </Grid>
            <Grid item xs={12}>
              <InputField label="External Name" name={"external_name"} />
            </Grid>
          </Grid>
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <Button
              sx={{ mt: 3, ml: 3, mr: 0 }}
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Save Voicemail Box
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
