import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { useMobile, useTablet, useSmallerHeight } from "../../themes";
import sideImg from "../../assets/img/sidebar.png";
import backgroundImg from "../../assets/img/mobilebackground.png";
import subscribeheader from "../../assets/img/subscribeheader.jpg";
import tabletheader from "../../assets/img/tabletheader.jpg";

import { useDispatch, useSelector } from "react-redux";
import { authSelectors, customerActions, uiActions } from "../../state";
import { ADMIN_BASE_URL, CUSTOMER_SITE_BASE_URL } from "../../config";
import { pathEquals } from "../../lib";
import { ArrowBackIcon } from "../../components";
import { useNavigate } from "react-router-dom";
import AuthPages from "../../pages/auth";
interface Props {
  customerButton?: boolean;
  subHeader?: boolean;
  children?: ReactNode;
  sx?: object;
  showTabs?: boolean;
  displayContents?: boolean;
  title?: string;
  backAction?: () => void;
}
export const SubscribeLayout = ({ title, children, backAction }: Props) => {
  const isTablet = useTablet();
  const isMobile = useMobile();
  const isSmallerThanPc = isTablet || isMobile;
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const nav = useNavigate();

  const isSmallerH = useSmallerHeight();

  useEffect(() => {
    if (window.location.origin === ADMIN_BASE_URL) {
      window.location.href = CUSTOMER_SITE_BASE_URL;
    }
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(customerActions.getAllInfoOnLogin());
      dispatch(customerActions.getCustomerSubscriptions());
    }
  }, [isLoggedIn]);
  useEffect(() => {
    dispatch(uiActions.getChargebeeSettings());
  }, []);

  const homePage = pathEquals("/");
  const countryPage = pathEquals("/country");
  return (
    <>
      <Grid
        container
        display={"grid"}
        gridTemplateColumns={isSmallerThanPc ? "repeat(12,1fr)" : "480px 1fr"}
        position={isMobile || (isTablet && homePage) ? "unset" : "fixed"}
        // gridTemplateRows={isTablet && homePage ? "1fr" : "repeat(12,1fr)"}
        width={!isSmallerThanPc ? "100vw" : "auto"}
        height={
          (countryPage && !isSmallerThanPc)
            ? "max-content"
            : isSmallerH && !isSmallerThanPc
              ? "initial"
              : "100vh"
        }
        gap={2}
        style={{
          overflowY:
            isSmallerThanPc || (isSmallerH && !isSmallerThanPc)
              ? "auto"
              : "hidden",
          background: `top center / cover no-repeat url("${isMobile && homePage ? backgroundImg : ""
            }")`,
          display: isMobile ? "block" : undefined,
        }}
      >
        <Grid
          item
          style={{
            display:
              !isSmallerThanPc || (!isSmallerThanPc && homePage)
                ? "block"
                : "none",
            height: "min-content",
          }}
          gridColumn={1}
          gridRow={"1/10"}
        >
          <img
            width={"100%"}
            style={{
              objectFit: "cover",
            }}
            alt="side-img"
            src={sideImg}
          />
        </Grid>

        <Grid
          item
          style={{
            display: (isMobile && !homePage) || isTablet ? "block" : "none",
          }}
          gridRow={1}
          gridColumn={"1/12"}
          height={"fit-content"}
        >
          <img
            alt="side-img"
            src={isMobile ? subscribeheader : tabletheader}
            width={"100vw"}
            style={{ minWidth: "100vw" }}
            height={"100%"}
          />
        </Grid>
        <Grid
          item
          // width={"100%"}
          // gridRow={isSmallerThanPc || smallerW ? 1 : 2}
          // gridColumn={isSmallerThanPc || smallerW ? "1/12" : "2/12"}
          // display={!isMobile ? "flex" : "initial"}
          sx={{
            width: isMobile ? "100%" : "calc(100vw - 480px)",
            minHeight: isMobile ? "auto" : "80vh",
            height: isMobile ? undefined : "100vh",
            overflowY: isMobile ? undefined : "scroll",
          }}
        >
          <Box
            sx={{
              p: isMobile ? 2 : 6,
              position: "relative",
              width: "100%",
              minHeight: isMobile ? "auto" : "80vh",
            }}
          >
            <Box
              sx={{
                borderBottom: homePage ? "none" : "1px #D5D8EC solid",
                pb: 3,
                mb: 6,
                width: "100%",
              }}
            >
              {!(homePage && isMobile) && (
                <Typography
                  sx={{
                    color: "#3A3B3F",
                    fontFamily: "Cera Pro",
                    fontSize: isMobile ? 28 : 44,
                    fontWeight: "bold",
                  }}
                >
                  {!!backAction && (
                    <IconButton color="inherit" onClick={backAction}>
                      <ArrowBackIcon />
                    </IconButton>
                  )}
                  Welcome!
                </Typography>
              )}
              <Typography
                sx={{
                  color: "#3A3B3F",
                  fontFamily: "Cera Pro",
                  fontSize: isMobile ? 16 : 20,
                  fontWeight: 500,
                  letterSpacing: 0,
                  lineHeight: 2.2,
                }}
              >
                {title}
              </Typography>
            </Box>
            {/* <Divider sx={{ width: "100%" }} /> */}
            {children}
            {isLoggedIn && (
              <Box
                sx={{
                  position: "absolute",
                  borderTop: isMobile ? undefined : "1px #D5D8EC solid",
                  pt: 4,
                  pb: 4,
                  height: 100,
                  width: "92.5%",
                  bottom: -100,
                }}
              >
                <Button
                  fullWidth={isMobile}
                  color="info"
                  variant="contained"
                  sx={{
                    color: "#000000",
                    backgroundColor: "#E5E7F5",
                  }}
                  onClick={() => nav(AuthPages.logout.path)}
                >
                  Logout
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
