import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export const RootStyle = styled("div")(() => ({
  height: "100vh",
  display: "flex",
  width: "100%",
  gap: 32,
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
}));

export const CountryButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: "flex",
  gap: 24,
  flexDirection: "row",
  width: "100%",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  [theme.breakpoints.up("sm")]: {
    gap: "24px 50px",
  },
  [theme.breakpoints.up("lg")]: {
    gap: "24px 100px",
  },
})) as typeof ToggleButtonGroup;

export const CountryButton = styled(ToggleButton)(({ theme }) => ({
  boxSizing: "border-box",
  color: "#000",
  height: 100,
  [theme.breakpoints.up("xs")]: {
    width: "44%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "233px",
  },
  justifyItems: "center",
  boxShadow: "0px 0px 0px 1px #D7D9EC ",
  backgroundColor: "#FFF !important",
  flexDirection: "column",
  borderRadius: "10px !important",
  webkitBorderRadius: "500px",
  mozBorderRadius: "500px",
  fontWeight: "bold",
  fontSize: 20,
  justifyContent: "center",
  alignItems: "center",
  "&.Mui-selected": {
    webkitBorderRadius: "500px",
    mozBorderRadius: "500px",
    borderRadius: "10px !important",
    color: "#9957A3",
    position: "relative",
    [theme.breakpoints.up("xs")]: {
      boxShadow: "0px 0px 0px 5px #9957A3",
    },
  },
})) as typeof ToggleButton;
