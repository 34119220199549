import { Typography } from "@mui/material";
import { RootListViewStyled } from "./GenericPage.styles";
import { SERIES_COLUMNS } from "../../components/tables/columns";
import { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  adminActions,
  adminSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { ToolBar } from "../../components";
import { useNavigate } from "react-router-dom";
import AdminPages from ".";

export const SeriesPage = () => {
  const [pageSize, setPageSize] = useState(25);
  const dispatch = useDispatch();
  const series = useSelector(adminSelectors.series);
  const nav = useNavigate();
  useEffect(() => {
    dispatch(adminActions.getSeries());
  }, [dispatch]);

  return (
    <RootListViewStyled>
      <Typography variant="h3" mb={3}>
        Series
      </Typography>

      <DataGridPro
        rows={series.rows}
        columns={SERIES_COLUMNS}
        pagination
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          Toolbar: ToolBar,
        }}
        componentsProps={{
          toolbar: {
            showQuickSearch: true,
            showAddButton: true,
            label: "Add Series",
            onClick: () => nav(AdminPages.seriesCreate.path),
          },
        }}
      />
    </RootListViewStyled>
  );
};
