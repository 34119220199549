import React, { useEffect, memo } from "react";
import Notification from "../../components/Notification";
import { Outlet } from "react-router-dom";
import {
  RootStyle,
  LeftPanel,
  RightPanel,
  OutletPanel,
} from "./AuthLayout.styles";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors, uiSelectors } from "../../state";
import { Navigation } from "../../lib";
import { Backdrop, CircularProgress } from "@mui/material";
import logo from "../../assets/img/authsidebar.jpg";
import backgroundImg from "../../assets/img/mobilebackground.png";

import { useMobile, useTablet } from "../../themes";
import { ADMIN_BASE_URL } from "../../config";
import { MainPages } from "../../pages/main";

export const AuthLayout = memo(function AuthLayout() {
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const isAdmin = useSelector(authSelectors.isAdmin);
  const isTablet = useTablet();
  const isMobile = useMobile();
  const isSmallerThanPc = isTablet || isMobile;
  const loading = useSelector(uiSelectors.loading);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isLoggedIn === true) {
      if (isAdmin) {
        Navigation.go(`${ADMIN_BASE_URL}/admin`);
      } else {
        Navigation.go(MainPages.loopDash.path);
      }
    }
  }, [isLoggedIn, dispatch]);
  return (
    <RootStyle>
      <LeftPanel
        style={{
          background: `url("${isMobile ? "" : logo}")`,
          backgroundSize: isMobile ? "" : "cover",
          backgroundRepeat: isMobile ? "" : "no-repeat",
          backgroundPosition: isMobile ? "" : "top center",
        }}
      >
        {isMobile && <img alt="loop-auth-logo" src={backgroundImg} />}
      </LeftPanel>
      <RightPanel>
        <OutletPanel
          style={{
            backgroundColor: isSmallerThanPc ? "#FFF" : "transparent",
            width: isMobile ? "85%" : isTablet ? "70%" : "50%",
            paddingLeft: isSmallerThanPc ? 6 : 0,
            paddingRight: isSmallerThanPc ? 6 : 0,
            borderRadius: isSmallerThanPc ? "5px" : 0,
            margin: isMobile ? 0 : 2,
            height: isMobile ? "360px" : isTablet ? "345px" : "50%",
          }}
        >
          <Outlet />
        </OutletPanel>
      </RightPanel>
      <Notification />
      {loading && (
        <Backdrop open={true}>
          <CircularProgress size={128} />
        </Backdrop>
      )}
    </RootStyle>
  );
});
