import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type TStatSection = "circle" | "loop" | "book";

export interface IStatItem {
  country: string;
  total: number;
}

export type IStats = Record<TStatSection, IStatItem[]>;

export interface StatsState {
  stats: IStats;
}

const initialState: StatsState = {
  stats: {
    circle: [],
    loop: [],
    book: [],
  },
};

export const stats = {
  ...createSlice({
    name: "stats",
    initialState,
    reducers: {
      setStatsState(state, { payload }: PayloadAction<IStats>) {
        state.stats = payload;
      },
      clearStats() {
        return initialState;
      },
    },
  }),
  persist: true,
};
