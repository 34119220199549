import { Grid, Typography, Box, Button, TextareaAutosize } from "@mui/material";
import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { yup } from "../../lib";
import { adminActions, adminSelectors } from "../../state";
interface Props {
  closeModal: () => void;
  id?: number;
}
export const NoteForm = ({ closeModal, id }: Props) => {
  const validationSchema = yup.object({
    notes: yup.string(),
  });

  const _initialValues = {
    notes: "",
  };
  const [initialValues, setInitialValues] = useState(_initialValues);
  const dispatch = useDispatch();
  const customer_id = useParams();
  const notes = useSelector(adminSelectors.customerNotes);
  const edit = notes.find((n) => n.id === id);
  useEffect(() => {
    if (edit) {
      setInitialValues({ notes: edit.note });
    }
  }, [id, notes, dispatch]);

  const handleSubmit = ({ notes }) => {
    if (edit) {
      dispatch(adminActions.updateNote(Number(id), notes));
    } else {
      dispatch(adminActions.createNote(Number(customer_id.id), notes));
    }
    closeModal();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <Grid
            sx={{
              width: "100%",
              border: "1px solid #E4E7EB",
              borderRadius: 2,
              m: 1,
              mt: 2,
              p: 2,
            }}
            display={"flex"}
            flexDirection="column"
            container
          >
            <Typography variant="h6">Note</Typography>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <TextareaAutosize
                style={{
                  minHeight: "150px",
                  maxHeight: "150px",
                  minWidth: "100%",
                  maxWidth: "100%",
                  fontFamily: "Cera Pro",
                  fontSize: 18,
                  padding: 16,
                }}
                value={values.notes}
                name={"notes"}
                onChange={(v) => setFieldValue("notes", v.target.value)}
              />
            </Grid>
          </Grid>
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <Button
              sx={{ mt: 3, ml: 3, mr: 0 }}
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Save Note
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
