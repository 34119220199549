import { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors } from "../../state";
import { APP_TYPES_COLUMNS } from "../../components/tables/columns";
import { ActionBox, RootListViewStyled } from "./GenericPage.styles";
import { AddButton, ToolBar } from "../../components";
import { Typography } from "@mui/material";
import AdminPages from ".";
import { useNavigate } from "react-router-dom";

export const AppTypesPage = () => {
  const dispatch = useDispatch();
  const rows = useSelector(adminSelectors.app_types);
  const nav = useNavigate();
  const [pageSize, setPageSize] = useState(25);
  useEffect(() => {
    dispatch(adminActions.getAppTypes());
  }, [dispatch]);

  return (
    <RootListViewStyled>
      <Typography variant="h3">App Types</Typography>
      <ActionBox>
        <AddButton
          label={"Add App Types"}
          to={AdminPages.appTypesCreate.path}
        />
      </ActionBox>
      <DataGridPro
        rows={rows}
        columns={APP_TYPES_COLUMNS}
        pagination
        onRowClick={(row) =>
          nav(AdminPages.appTypesEdit.path.replace(":id", row.id + ""))
        }
        rowsPerPageOptions={[10, 25, 50, 100]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          Toolbar: ToolBar,
        }}
      />
    </RootListViewStyled>
  );
};
