import { Grid, Typography } from "@mui/material";

import { TabPanel } from "./TabPanel";
import { useSelector } from "react-redux";
import { adminSelectors } from "../../state";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { SUBSCRIPTIONS_COLUMNS } from "../tables/columns";

export const BillingTab = ({ value }) => {
  const customerSubscriptions = useSelector(
    adminSelectors.customerSubscriptions,
  );

  return (
    <TabPanel value={value} index={1}>
      <Grid sx={{ minHeight: 600 }} container spacing={3}>
        <Grid item xs={12} md={12}>
          <Typography variant="h3" mb={3}>
            Subscriptions
          </Typography>

          <DataGridPro
            rows={customerSubscriptions}
            columns={SUBSCRIPTIONS_COLUMNS}
          />
        </Grid>
      </Grid>
    </TabPanel>
  );
};
