import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { statsActions, statsSelectors } from "../../state";
import {
  book,
  circle,
  kidsStyles,
  loop,
  loopStyle,
  manStyles,
  oval,
  voice,
  voiceStyles,
  wrapper,
} from "./StatsPage.styles";
import { Box, useTheme } from "@mui/material";
import StatBanner from "./components/StatBanner";
// import MapDashboard from "./components/MapDashboard";
import kids from "../../assets/stats/kids.png";
import man from "../../assets/stats/man.png";
import voiceLogo from "../../assets/stats/the-voice.png";
import loopLogo from "../../assets/stats/the-loop-logo.png";
import Gauge from "./components/Gauge";

export function Stats() {
  const dispatch = useDispatch();
  const stats = useSelector(statsSelectors.allStats);
  const [goal, setGoal] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    dispatch(statsActions.getStats());

    const timer = setInterval(() => {
      dispatch(statsActions.getStats());
    }, 1000 * 60 * 30);
    return () => {
      clearInterval(timer);
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const response = (await dispatch(
        statsActions.getVoiceGoal(),
      )) as unknown as { goal: number };
      setGoal(response.goal);
    })();
  }, []);

  return (
    <Box sx={wrapper}>
      <Box sx={circle(theme)}>
        <StatBanner info={stats.circle} />
        {/* <MapDashboard mapType={"circle"} data={stats.circle} /> */}
      </Box>

      <Box sx={loop(theme)}>
        <Box sx={loopStyle}>
          <img src={loopLogo} alt="the loop" />
        </Box>
        <StatBanner info={stats.loop} />
        {/* <MapDashboard mapType={"loop"} data={stats.loop} /> */}
      </Box>

      <Box sx={book(theme)}>
        <StatBanner info={stats.book} />
        {/* <MapDashboard mapType={"book"} data={stats.book} /> */}
        <Box sx={oval(theme)} />
      </Box>

      <Box sx={voice(theme)}>
        <Box sx={voiceStyles}>
          <img src={voiceLogo} alt="the voice" />
        </Box>

        <Gauge value={goal} />
      </Box>

      <Box sx={kidsStyles}>
        <img src={kids} alt="kids" />
      </Box>

      <Box sx={manStyles}>
        <img src={man} alt="man" />
      </Box>
    </Box>
  );
}
