import { IconButton, Box, ListItem, List, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { uiActions, useSelector, uiSelectors, useDispatch } from "../state";
import CustomModal from "./modal/CustomModal";
import { UINotificationModal } from "../state/ui/state";
export const NotificationModal = () => {
  const notification: UINotificationModal = useSelector(
    uiSelectors.notificationModal,
  );

  const dispatch = useDispatch();
  const hideNotification = () => dispatch(uiActions.hideNotificationModal());

  const { title, message, bulletpoints } = notification || {};
  return (
    <CustomModal
      open={!!notification}
      onClose={hideNotification}
      sx={{
        padding: "16px",
        border: "0px",
        borderRadius: "3px",
        maxWidth: "500px",
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography
          paddingLeft="16px"
          paddingTop="16px"
          fontSize="20px"
          fontWeight="500"
          display="inline-block"
        >
          {" "}
          {title}
        </Typography>
        <IconButton onClick={hideNotification}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      <Box
        paddingRight="16px"
        paddingLeft="16px"
        paddingBottom="16px"
        paddingTop="16px"
      >
        <Box>{message}</Box>
        {bulletpoints && (
          <List sx={{ listStyleType: "disc", pl: 4 }}>
            {bulletpoints.map((p, i) => (
              <ListItem sx={{ display: "list-item" }} key={i}>
                {p}
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </CustomModal>
  );
};
