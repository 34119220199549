import React from "react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import {
  __DEV__,
  NODE_ENV,
  REACT_APP_ERRORS_DEV,
  REACT_APP_ERRORS_DSN,
  REACT_APP_VERSION,
  REACT_APP_BASE_API_URL,
} from "../../config";
import { SomethingWentWrong } from "./SomethingWentWrong";

const usingDevConfig = __DEV__ && !REACT_APP_ERRORS_DEV;
export function setUser(user) {
  // reportDialogUser = {
  //   name: user.username,
  //   email: user.email,
  // };
  Sentry.configureScope((scope) => {
    scope.setUser(user);
  });
}

const devConfig = usingDevConfig
  ? {
      beforeSend() {
        // Do not send errors in development.
        return null;
      },
    }
  : undefined;

if (__DEV__ && !usingDevConfig) {
  console.warn("Reporting errors to https://sentry.io during development.");
}

/** See https://docs.sentry.io/learn/configuration */
export function init() {
  Sentry.init({
    /** Where to send errors. This is NOT a secret. */
    dsn: REACT_APP_ERRORS_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [REACT_APP_BASE_API_URL],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: NODE_ENV,
    release: REACT_APP_VERSION,
    ...devConfig,
  });
}

export function ErrorSentryFallBack() {
  return <SomethingWentWrong />;
}
