import { Box, InputLabel } from "@mui/material";
import { useFormikContext, FormikValues } from "formik";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

export const JSONField = ({
  label,
  name,
  height = undefined,
}: {
  label: string;
  name: string;
  height?: number | undefined;
}) => {
  const { touched, errors, values, setFieldValue } =
    useFormikContext<FormikValues>();
  //const loaded = useRef(false);

  //Based on https://github.com/AndrewRedican/react-json-editor-ajrm/issues/88#issuecomment-1184712475
  // useEffect(() => {
  //   if (values[name]) {
  //     setTimeout(() => {
  //       loaded.current = true;
  //     }, 1500);
  //   }
  // }, [values[name]]);

  return (
    <Box>
      <InputLabel>{label}</InputLabel>
      <JSONInput
        name={name}
        label={label}
        placeholder={values[name]}
        onChange={(e) => {
          setFieldValue(name, e.jsObject);
        }}
        colors={{
          default: "#484c4f",
          background: "#eff1f6",
          background_warning: "#FEECEB",
          string: "#FA7921",
          number: "#70CE35",
          colon: "#49B8F7",
          keys: "#1277ab",
        }}
        height={height}
        width={"100%"}
        locale={locale}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
      />
    </Box>
  );
};
