import { SubscribeLayout } from "../../layouts/main/SubscribeLayout";
import sentEmail from "../../assets/img/sentemail.png";
import { Button, Typography } from "@mui/material";
import { useMobile } from "../../themes";
import { FlowSuccess } from "./FlowSuccess";

export const EmailSent = () => {
  const isMobile = useMobile();
  return (
    <SubscribeLayout subHeader={false} showTabs={false}>
      <FlowSuccess
        img={sentEmail}
        alt={"sent-email-img"}
        caption={"Seems like you're already a customer!"}
      >
        <Typography
          variant={isMobile ? "body1" : "h4"}
          fontWeight={"bold"}
          textAlign={"center"}
        >
          We've sent you an email that will give you access to your account.
        </Typography>
        <Button variant="contained" href="/auth/login" >
          Go to Login
        </Button>
      </FlowSuccess>
    </SubscribeLayout>
  );
};
