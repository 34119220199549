import { Typography } from "@mui/material";
import { CircleIcon } from "../icons";

export const Status = ({ active, includeActiveTag = true }) => {
  return (
    <>
      <CircleIcon
        sx={{ height: 8, width: 8, mr: 1 }}
        color={active ? `success` : `error`}
      />
      {includeActiveTag && (
        <Typography variant="body2" display={"inline"}>
          {active ? `Active` : `Inactive`}
        </Typography>
      )}
    </>
  );
};
