import { apiClient, uiActions } from "../states";
import { AppThunk, AppThunkDispatch } from "../types";
import { stats } from "./state";

const { actions } = stats;

export const statsActions = {
  ...actions,
  getStats(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await apiClient.get(
        "/subscriptions-totals-by-country",
      );

      if (status !== 200) {
        dispatch(uiActions.showError("failed to get statistic"));
      } else {
        dispatch(actions.setStatsState(data));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getVoiceGoal(): AppThunkDispatch<Promise<{ goal: number }>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await apiClient.get("/voice-goal");
      dispatch(uiActions.setLoading(false));

      if (status !== 200) {
        dispatch(uiActions.showError("failed to get voice goal"));
        return { goal: 0 };
      } else {
        return data;
      }
    };
  },
};
