import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import { InputField } from "../inputs/InputField";
import { yup } from "../../lib";
import { adminActions, useDispatch } from "../../state";
import { useParams } from "react-router-dom";

const validationSchema = yup.object({
  description: yup.string().required("Description is required"),
  points: yup
    .number()
    .min(1, "points must be greater than 0")
    .required("points is required"),
  child_id: yup.number().required(),
  point_type: yup.string().required(),
  location_id: yup.string(),
  game_version_id: yup.number()
});
type Prop = { closeModal: () => void };

export const PointsForm = ({ closeModal }: Prop) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const initialValues = {
    child_id: Number(id),
    description: "",
    amount: 0,
    points: 0,
    point_type: "game",
  };

  const handleSubmit = (values) => {
    dispatch(adminActions.givePoints(id, values));
    closeModal();
  };
  const handleCancel = () => closeModal();

  return (
    <>
      <Typography id="modal-modal-title" variant="h3" component="h2">
        Give points
      </Typography>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <Grid alignItems={"center"} container spacing={4}>
              <Grid item xs={12} md={12} width={"100%"}>
                <Box display={"flex"} gap={2} width="50%">
                  <InputField
                    label="Amount of points*"
                    name="points"
                    type="number"
                  />
                </Box>
                <InputField label="Reason*" name="description" />
                <Grid item xs={12} md={12} width={"100%"}>
                  <FormControl
                    margin={"normal"}
                    style={{ width: "100%", marginBottom: 32 }}
                  >
                    <InputLabel id="mui-label-point-type">
                      Point type
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="mui-label-point-type"
                      value={values.point_type}
                      label={"point type"}
                      onChange={(e) => {
                        return setFieldValue("point_type", e.target.value);
                      }}
                    >
                      <MenuItem value={"game"}>Game</MenuItem>
                      <MenuItem value={"manual"}>Manual</MenuItem>
                      <MenuItem value={"live_show"}>Live Show</MenuItem>
                      <MenuItem value={"other"}>Other</MenuItem>
                    </Select>
                    <InputField
                      label="Location Id (i.e. junior_bopit, game_simon)"
                      name="location_id"
                    />
                    <InputField
                      label="Game version id"
                      name="game_version_id"
                      type="number"
                    />
                  </FormControl>
                </Grid>
                <Divider />
                <Grid
                  item
                  xs={12}
                  md={12}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  gap={2}
                  width={"100%"}
                >
                  <Button
                    sx={{ mt: 3, ml: 0, width: "25%" }}
                    color="secondary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{ mt: 3, ml: 0, width: "25%" }}
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
