import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { colors, fontFamily } from "../StatsPage.styles";

export const wrapper: SxProps<Theme> = {
  position: "relative",
  borderTop: `1px solid ${colors.blue1}`,
  borderBottom: `1px solid ${colors.blue1}`,
  width: "100%",
  marginTop: "315px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const bg: SxProps<Theme> = {
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  width: "100%",
  backgroundColor: "rgba(255,255,255,0.55)",
};

export const infoBlock: SxProps<Theme> = {
  position: "relative",
  flexBasis: "50%",
  zIndex: 1,
  margin: 6,
  "&:after": {
    position: "absolute",
    content: '""',
    top: "50%",
    right: 0,
    boxSizing: "border-box",
    width: "1px",
    border: `2px solid ${colors.violet3}`,
    transform: "translateY(-50%)",
  },

  "&:last-of-type": {
    "&:after": {
      display: "none",
    },
  },
};

export const amount = (theme: Theme): SxProps<Theme> => {
  return {
    color: colors.violet2,
    fontSize: 70,
    fontWeight: "bold",
    letterSpacing: -0.13,
    textAlign: "center",
    fontFamily,
    lineHeight: 1,
    [theme.breakpoints.only("md")]: {
      fontSize: 34,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
  };
};

export const text: SxProps<Theme> = {
  color: colors.grey1,
  fontSize: 20,
  fontWeight: "bold",
  letterSpacing: "-0.04px",
  textAlign: "center",
  fontFamily,
};
