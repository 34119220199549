import { FC } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { wrapper, bg, infoBlock, amount, text } from "./StatBanner.styles";
import { StatInfo } from "../../../state/stats/selectors";
import { numberWithCommas } from "../../../lib";

interface Props {
  info: StatInfo[];
}

const StatBanner: FC<Props> = ({ info }) => {
  const theme = useTheme();

  if (!info.length) return null;

  return (
    <Box sx={wrapper}>
      <Box sx={bg} />

      {info.map((i, index) => {
        return (
          <Box key={index} sx={infoBlock}>
            <Typography sx={amount(theme)}>
              {numberWithCommas(i.amount)}
            </Typography>
            <Typography sx={text}>{i.text}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default StatBanner;
