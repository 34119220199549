import { styled } from "@mui/material/styles";

export const RootStyle = styled("div")(({ theme }) => ({
  height: "100vh",
  display: "flex",
  gap: 16,
  justifyContent: "center",
  [theme.breakpoints.up("xs")]: {
    alignItems: "flex-end",
  },
  [theme.breakpoints.up("md")]: {
    alignItems: "center",
  },

  flexDirection: "row",
}));

export const SidePanel = styled("div")(({ theme }) => ({
  position: "fixed",
  left: 0,
  top: 0,
  bottom: 0,
  [theme.breakpoints.up("xs")]: {
    height: "100%",
    width: "100%",
    zIndex: 1,
    display: "flex",
  },
  [theme.breakpoints.up("md")]: {
    display: "block",
    width: "25%",
  },
  "& img": {
    [theme.breakpoints.up("xs")]: {
      height: "100%",
      minWidth: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      height: "auto",
      minWidth: "",
    },
    [theme.breakpoints.up("md")]: {
      height: "auto",
      minWidth: "480px",
    },
    maxWidth: "100%",
  },
}));

export const RightPanel = styled("div")(({ theme }) => ({
  right: 0,
  gap: 16,

  height: "inherit",
  alignItems: "center",

  [theme.breakpoints.up("xs")]: {
    justifyContent: "flex-end",
    width: "100%",
    zIndex: 2,
  },
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    position: "absolute",
    justifyContent: "center",
    width: "auto",
    backgroundColor: "transparent",
  },
  [theme.breakpoints.up("md")]: {
    width: "calc(100vw - 480px)",
  },

  flexDirection: "column",
}));

export const OptionWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 16,
}));

export const Option = styled("div")(() => ({
  boxSizing: "border-box",
  height: 238,
  width: 238,
  border: "1px solid #D7D9EC",
  backgroundColor: "#FFFFFF",
  borderRadius: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
