import {
  Create,
  OpenInNew,
  OpenInBrowser as OpenInBrowserIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ControlPointDuplicateOutlinedIcon from "@mui/icons-material/ControlPointDuplicateOutlined";
import {
  GridCellParams,
  GridColDef,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import AdminPages from "../../pages/admin";
import {
  adminActions,
  AppState,
  AppThunk,
  authActions,
  authSelectors,
  uiSelectors,
} from "../../state";
import { DeleteIcon, EditIcon, ImpersonateIcon } from "../icons";
import { generatePath, Link, useNavigate } from "react-router-dom";
import {
  capitalizeAddress,
  capitalizeFirstLetter,
  formatDate,
  formatNumber,
  formatPhone,
} from "../../lib";
import { Status } from "./Status";
import { format } from "date-fns";
import { NavLink } from "react-router-dom";
import { buildParams } from "../../lib/requestBuilder";
import { store } from "../../state/store";
import { useState } from "react";
import { VoicemailActionsForm } from "../forms/VoicemailActionsForm";
import SendIcon from "@mui/icons-material/Send";
import { AddPhoneForm } from "../forms/AddPhoneForm";
import { ChildForm } from "../forms/ChildForm";
import CustomModal from "../modal/CustomModal";
import { ConfirmDeleteDialog } from "./ConfirmDeleteDialog";
import { MainPages } from "../../pages/main";

let vmBoxes: string[] = [];
interface ParamProp {
  params: GridCellParams;
}
interface ActionParams extends ParamProp {
  editPath: string;
  createPath?: string;
  deleteAction: (id: number, tableStateParams?: string) => AppThunk;
  resource: string;
  tableStateParams?: string;
}
const loadVoicemailBoxes = async () => {
  const _state: unknown = store.getState();
  const state = _state as AppState;
  const roles = state.auth.roles;
  const isAdmin =
    roles?.includes("super_admin") ||
    roles?.includes("admin") ||
    roles?.includes("employee");
  if (isAdmin) {
    await store.dispatch(
      adminActions.getVoicemailBoxes("range=0&range=300", false),
    );
    const _stateInner: unknown = store.getState();
    const stateInner = _stateInner as AppState;
    vmBoxes = stateInner.admin?.voicemailBoxes?.rows.map((v) => v.name);
  }
};
let render = false;
const getVoicemailBoxes = () => {
  if (!render) {
    loadVoicemailBoxes();
    render = true;
  }
  return vmBoxes;
};

const DeleteFieldUser = (props) => {
  const dispatch = useDispatch();
  const roles = useSelector(authSelectors.roles);
  if (!roles.length) {
    console.warn("No active role or agency Id");
    return null;
  }
  return (
    <IconButton
      onClick={() => {
        dispatch(adminActions.deleteUser(props.row.id));
      }}
    >
      <DeleteIcon />
    </IconButton>
  );
};

const VoicemailBoxesActions = ({ params }: ParamProp) => {
  const dispatch = useDispatch();
  return (
    <Box>
      <Button
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          const confirmPurge = confirm(
            "Are you sure you want to purge this voicemail box? This action can't be undone",
          );
          // handleClose;
          if (confirmPurge) {
            dispatch(adminActions.purgeVoicemailBox(params.row.id));
          }


        }}
      >
        Purge Box
      </Button>
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          const confirmDelete = confirm(
            "Are you sure you want to delete this voicemail box?",
          );
          if (confirmDelete) {
            dispatch(adminActions.deleteVoicemailBox(params.row.id));
          }
        }}
      >
        <DeleteOutlinedIcon />
      </IconButton>
    </Box>
  );
};

const SerialsActions = ({ params }: ParamProp) => {
  const dispatch = useDispatch();
  return (
    <Box>
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          const confirmDelete = confirm(
            "Are you sure you want to delete this serial?",
          );
          if (confirmDelete) {
            dispatch(adminActions.deleteSerial(params.row.id));
          }
        }}
      >
        <DeleteOutlinedIcon />
      </IconButton>
    </Box>
  );
};

const ImpersonateButton = (props) => {
  const dispatch = useDispatch();
  const handleImpersonate = () => {
    dispatch(authActions.impersonate(props.row.id));
  };
  return (
    <IconButton onClick={handleImpersonate} color="secondary" size="small">
      <ImpersonateIcon />
    </IconButton>
  );
};
const BumpVersionButton = ({ params }: ParamProp) => {
  const dispatch = useDispatch();
  const handleBumpVersion = () => {
    dispatch(adminActions.bumpGameVersions(params.row.game_id));
  };
  return <Button onClick={handleBumpVersion}>Bump version</Button>;
};

const EmailSubscriptionButton = ({ params }: ParamProp) => {
  const dispatch = useDispatch();
  const handleEmailSubscription = () => {
    dispatch(adminActions.accountEmail({ subscription_id: params.row.id }));
  };
  return <Button onClick={handleEmailSubscription}>Email Info</Button>;


};

const CBCustomerLink = ({ params }: ParamProp) => {
  const CBConfig = useSelector(uiSelectors.chargebeeConfig);
  return (
    <a
      href={`${CBConfig?.domain}/d/customers/${params.value}`}
      target="_blank"
      rel="noreferrer"
    >
      {params.value}
    </a>
  );
};

const CBSubLink = ({ params }: ParamProp) => {
  const CBConfig = useSelector(uiSelectors.chargebeeConfig);
  return (
    <a
      href={`${CBConfig?.domain}/d/subscriptions/${params.value}`}
      target="_blank"
      rel="noreferrer"
    >
      {params.value}
    </a>
  );
};

const VoicemailStatusButtons = ({ params }: ParamProp) => {
  const dispatch = useDispatch();
  const apiRef = useGridApiContext();
  const statuses = ["new", "old", "saved", "discard"];
  const handleClick = async (status: string) => {
    const state = apiRef.current.state;
    dispatch(
      adminActions.updateVoicemail(
        params.row.id,
        { status },
        buildParams(state),
      ),
    );
  };
  return (
    <Box>
      {statuses.map((s) => (
        <Button
          onClick={() => handleClick(s)}
          sx={{ m: 1, fontSize: 12, p: 0.5 }}
          variant={params.value === s ? "contained" : "text"}
          color={params.value === s ? "primary" : "secondary"}
          key={s}
        >
          {s}
        </Button>
      ))}
    </Box>
  );
};

const VoicemailPlayer = ({ params }: ParamProp) => {
  const dispatch = useDispatch();
  const apiRef = useGridApiContext();

  const handlePlay = () => {
    if (params.row.status === "new") {
      const state = apiRef.current.state;
      dispatch(
        adminActions.updateVoicemail(
          params.row.id,
          { status: "old" },
          buildParams(state),
        ),
      );
    }
  };

  return (
    <audio onPlay={handlePlay} controls src={params.value}>
      <a href={params.value}>Download audio</a>
    </audio>
  );
};
const CustomerButton = ({ params }: ParamProp) => {
  if (!params.row.customer_id) return null;
  return (
    <NavLink
      to={generatePath(AdminPages.customer.path, {
        id: params.row.customer_id,
        tab: "general",
      })}
    >
      {params.row.customer_id}
    </NavLink>
  );
};

const TableActions = ({
  params,
  editPath,
  createPath,
  deleteAction,
  resource,
  tableStateParams,
}: ActionParams) => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  return (
    <span>
      {createPath && (
        <IconButton
          onClick={() =>
            window.open(createPath + "?from=" + params.id.toString(), "_blank")
          }
          sx={{ paddingLeft: 0 }}
        >
          <ControlPointDuplicateOutlinedIcon />
        </IconButton>
      )}
      <IconButton
        onClick={() =>
          nav(
            generatePath(editPath, {
              id: params.id.toString(),
            }),
          )
        }
      >
        <EditIcon />
      </IconButton>
      <IconButton
        onClick={() =>
          window.open(
            generatePath(editPath, {
              id: params.id.toString(),
            }),
            "_blank",
          )
        }
      >
        <OpenInNew />
      </IconButton>
      <IconButton
        onClick={() => {
          const confirmDelete = confirm(
            `Are you sure you want to delete ${params.row.label} ${resource}?`,
          );
          if (confirmDelete) {
            dispatch(deleteAction(params.row.id, tableStateParams));
          }
        }}
      >
        <DeleteOutlinedIcon />
      </IconButton>
    </span>
  );
};
const CustomerActions = ({ params }: ParamProp) => {
  const nav = useNavigate();
  return (
    <span>
      <IconButton
        onClick={() => {
          window.open(
            generatePath(AdminPages.customerEdit.path, {
              customerId: params.row.id,
              userId: params.row.user_id,
            }),
            "_blank",
          );
        }}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          window.open(
            generatePath(AdminPages.customer.path, {
              id: params.id.toString(),
              tab: "general",
            }),
            "_blank",
          );
        }}
      >
        <OpenInNew />
      </IconButton>
      <IconButton
        onClick={() => {
          nav(
            generatePath(AdminPages.customer.path, {
              id: params.id.toString(),
              tab: "general",
            }),
          );
        }}
      >
        <OpenInBrowserIcon />
      </IconButton>
    </span>
  );
};

const PhoneActions = ({ params }: ParamProp) => {
  const dispatch = useDispatch();
  const admin = useSelector(authSelectors.isAdmin);
  const mode = admin ? "admin" : "customer";
  const [addPhoneModal, setAddPhoneModal] = useState(false);
  params.colDef.headerName = admin ? "Actions" : "";
  const editPhone = async (id) => {
    await dispatch(adminActions.getCustomerPhone(id, mode));
    setAddPhoneModal(true);
  };
  const addPhoneClick = () => {
    setAddPhoneModal(!addPhoneModal);
    dispatch(adminActions.setDefaultCustomerPhone());
  };
  return (
    <Box
      sx={{
        width: "90px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <IconButton
        onClick={() => {
          editPhone(params.row.id);
        }}
      >
        <EditIcon />
      </IconButton>
      <ConfirmDeleteDialog
        description={"Are you sure you want to delete this phone number?"}
        color={"default"}
        icon={<DeleteIcon />}
        handleConfirm={() =>
          dispatch(adminActions.deleteCustomerPhone({ ...params.row }, mode))
        }
      />

      <CustomModal open={addPhoneModal} onClose={addPhoneClick}>
        <AddPhoneForm closeModal={addPhoneClick} mode={mode} />
      </CustomModal>
    </Box>
  );
};
const ChildActions = ({ params: { row, ...r } }: ParamProp) => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const admin = useSelector(authSelectors.isAdmin);
  const mode = admin ? "admin" : "customer";
  const [openModal, setOpenModal] = useState(false);
  r.colDef.headerName = admin ? "Actions" : "";
  const goToChildPage = (id) => {
    nav(`/admin/children/${id}/rewards`);
  };
  const addChildClick = () => {
    setOpenModal(!openModal);
    dispatch(adminActions.setDefaultChildInfo());
  };
  const deleteChild = (childId) => {
    dispatch(adminActions.deleteChild(childId, row.customer_id, mode));
  };
  const editChild = async (id) => {
    await dispatch(adminActions.getChildInfo(mode, id));
    setOpenModal(true);
  };
  return (
    <Box
      sx={{
        width: mode === "admin" ? "150px" : "250px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {mode === "admin" && (
        <IconButton onClick={() => goToChildPage(row.id)}>
          <OpenInNew />
        </IconButton>
      )}
      {mode !== "admin" && (
        <Link
          to={MainPages.loopAudioCustomer.path.replace(
            ":id",
            row.id.toString(),
          )}
        >
          Download CD's
          <IconButton color="primary" size="small">
            <OpenInNew />
          </IconButton>
        </Link>
      )}

      <IconButton onClick={() => editChild(row.id)}>
        <EditIcon />
      </IconButton>

      <ConfirmDeleteDialog
        description={`Are you sure you want to delete this child from the Loop? Doing so will remove all ${row.first_name && capitalizeFirstLetter(row.first_name)
          }'s points. This cannot be undone!`}
        color={"default"}
        icon={<DeleteIcon />}
        handleConfirm={() => deleteChild(row.id)}
      />
      <CustomModal open={openModal} onClose={addChildClick}>
        <ChildForm mode={mode} isModal closeModal={addChildClick} />
      </CustomModal>
    </Box>
  );
};

const CREATED_UPDATED_COLS: GridColDef[] = [
  {
    field: "created_at",
    headerName: "Created",
    width: 300,
    type: "dateTime",
  },
  {
    field: "updated_at",
    headerName: "Updated",
    width: 300,
    type: "dateTime",
  },
];

const CHILD_NAMES_COLUMNS: GridColDef[] = [
  {
    field: "child.first_name",
    headerName: "First name",
    width: 200,
    editable: false,
    valueGetter: (v) => v.row.child?.first_name,
  },
  {
    field: "child.last_name",
    headerName: "Last name",
    width: 200,
    editable: false,
    valueGetter: (v) => v.row.child?.last_name,
  },
];

export const USERS_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "first_name",
    headerName: "First name",
    width: 200,
    editable: false,
  },
  {
    field: "last_name",
    headerName: "Last name",
    width: 200,
    editable: false,
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
    editable: false,
  },
  {
    field: "roles",
    headerName: "Role",
    width: 250,
    editable: false,
    valueGetter: (p) => p.value.map((r) => r.name).join(","),
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 150,
    editable: false,
  },
  {
    field: "edit",
    headerName: "Actions",
    width: 125,
    editable: false,
    // eslint-disable-next-line
    renderCell: (params) => {
      return (
        <>
          <DeleteFieldUser {...params} />
          <Link
            to={AdminPages.usersEdit.path.replace(":id", params.id.toString())}
          >
            <IconButton color="primary" size="small">
              <Create />
            </IconButton>
          </Link>
          {!params.row.roles.filter((r) => r.internal_name === "super_admin")
            .length && <ImpersonateButton {...params} />}
        </>
      );
    },
  },
];
export const MENU_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "actions",
    headerName: "Actions",
    width: 180,
    renderCell: (p) => (
      <TableActions
        params={p}
        editPath={AdminPages.menusEdit.path}
        createPath={AdminPages.menuCreate.path}
        deleteAction={adminActions.deleteMenu}
        resource="menu"
      />
    ),
  },
  {
    field: "internal_name",
    headerName: "Internal Name",
    width: 250,
  },
  {
    field: "label",
    headerName: "Label",
    width: 150,
  },
  {
    field: "keypress_option",
    headerName: "Keypress",
    width: 150,
  },
  {
    field: "parent_apps",
    headerName: "Parents",
    width: 250,
  },
  {
    field: "app_type_external",
    headerName: "External Type",
    width: 150,
    valueGetter: (r) => r.value?.name,
  },
  {
    field: "app_type_internal",
    headerName: "Internal Type",
    width: 150,
    valueGetter: (r) => r.value?.name,
  },
  {
    field: "on_failure_app",
    headerName: "On Failure",
    width: 150,
  },
  {
    field: "on_success_app",
    headerName: "On Success",
    width: 150,
  },
  {
    field: "note",
    headerName: "Note",
    width: 150,
  },
  {
    field: "app_type_id",
    headerName: "serial ID",
    width: 90,
  },
  {
    field: "highest_valid_number",
    headerName: "Highest Number",
    width: 150,
  },
];

export const CUSTOMER_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "actions",
    headerName: "Actions",
    width: 150,
    renderCell: (p) => <CustomerActions params={p} />,
  },
  {
    field: "first_name",
    headerName: "First Name",
    width: 100,
    valueGetter: ({ row: r }) => r.first_name || r.user?.first_name,
  },
  {
    field: "last_name",
    headerName: "Last Name",
    width: 100,
    valueGetter: ({ row: r }) => r.last_name || r.user?.last_name,
  },
  {
    field: "user.email",
    headerName: "Email",
    width: 150,
    valueGetter: ({ row }) => row.user?.email,
    sortable: false,
  },
  {
    field: "created_at",
    headerName: "Member Since",
    width: 150,
    valueFormatter: (v) => formatDate(v.value),
    type: "date",
  },
  {
    field: "recording_id",
    headerName: "Has Recording",
    width: 130,
    sortable: false,
    type: "boolean",
  },
  {
    field: "customer_play_audio",
    headerName: "Play",
    width: 130,
    renderCell: (params) => {
      return params.row.name_audio_url ? (
        <audio preload="none" controls src={params.row.name_audio_url} />
      ) : null;
    },
    sortable: false,
  },
  {
    field: "subscriptions->subscription_plan.plan_type",
    headerName: "Plans",
    width: 180,
    sortable: false,
    type: "singleSelect",
    valueGetter: (v) => v.row.plans,
    valueOptions: ["circle", "loop", "book"],
  },
  {
    field: "customer_phones",
    headerName: "Phone",
    width: 160,
    sortable: false,
    filterable: false,
    valueGetter: (v) => v.value.map((p) => p.phone_number).join(","),
  },
  {
    field: "data_entry_status",
    headerName: "Data Entry Status",
    width: 160,
    valueGetter: (v) =>
      v.value ?? v.row.source === "phone" ? "incomplete" : "",
  },
];

export const RECORDINGS_COLUMNS: (tableStateParams: string) => GridColDef[] = (
  tableStateParams,
) => [
    { field: "id", headerName: "ID", width: 80 },
    {
      field: "actions",
      headerName: "Actions",
      width: 180,
      renderCell: (p) => (
        <TableActions
          params={p}
          editPath={AdminPages.recordingEdit.path}
          deleteAction={adminActions.deleteRecording}
          resource="recording"
          tableStateParams={tableStateParams}
        />
      ),
    },
    {
      field: "original_file_path",
      headerName: "Original",
      width: 400,
    },
    {
      field: "full_url",
      headerName: "file",
      width: 400,
      renderCell: (v) => (
        <audio controls src={v.value}>
          <a href={v.value}>Download audio</a>
        </audio>
      ),
    },
    {
      field: "converted_file_path",
      headerName: "Converted Filename",
      width: 300,
    },
    {
      field: "original_filename",
      headerName: "Original Filename",
      width: 200,
    },
    {
      field: "recording_type.name",
      headerName: "Recording Type",
      minWidth: 200,
      valueGetter: (v) => v.row.recording_type?.name,
    },
    {
      field: "internal_name",
      headerName: "Internal Name",
      minWidth: 200,
    },
    {
      field: "label",
      headerName: "Label",
      minWidth: 200,
    },
  ];
export const CHILDREN_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "first_name",
    headerName: "First Name",
    width: 150,
    renderCell: (v) => (
      <Typography variant="body2" fontWeight={"bold"}>
        <Status active={v.row.active} includeActiveTag={false} />
        {`${v.row?.first_name}`}
      </Typography>
    ),
  },
  {
    field: "last_name",
    headerName: "Last Name",
    width: 150,
  },
  {
    field: "pin",
    headerName: "Pin",
    width: 75,
  },
  {
    field: "customer",
    filterable: false,
    sortable: false,
    headerName: "Home Phone",
    width: 150,
    valueGetter: (params) => {
      const cp = params.row.customer.customer_phones;
      if (cp.length) {
        return formatPhone(params.row.customer.customer_phones[0].phone_number);
      }
    },
  },
  {
    field: "customer.address",
    headerName: "Address",
    width: 250,
    valueGetter: (params) => {
      return capitalizeAddress(
        `${params.row.customer.address} , ${params.row.customer.city} ${params.row.customer.state} ,${params.row.customer.zip}`,
      );
    },
  },
  {
    field: "recording_id",
    headerName: "Has Recording",
    width: 130,
    valueGetter: (v) => (v.row.name_audio_url ? "True" : "False"),
  },
  {
    field: "customer_play_audio",
    headerName: "Play",
    width: 130,
    renderCell: (params) => {
      return params.row.name_audio_url ? (
        <audio preload="none" controls src={params.row.name_audio_url} />
      ) : null;
    },
  },
  {
    field: "points",
    headerName: "Points",
    headerAlign: "right",
    align: "right",
    flex: 1,
    valueGetter: (params) => formatNumber(params.row.points),
  },
];
export const LIVE_SHOW_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "shows",
    headerName: "Event",
    width: 500,
    renderCell: (v) => {
      const now = new Date().getTime();
      const startTime = new Date(v.row.start_time);
      const endTime = new Date(v.row.end_time);
      return (
        <Box
          pl={1}
          width={"100%"}
          component="span"
          display={"inline-flex"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          fontWeight={"bold"}
        >
          {v.row.name}
          {now > startTime.getTime() && now < endTime.getTime() && (
            <Chip
              label="LIVE"
              size="small"
              sx={{
                borderRadius: 1,
                alignSelf: "center",
                backgroundColor: "#ff0000",
                color: "#FFF",
                m: 0,
                p: 0,
                ml: 2,
              }}
            />
          )}
        </Box>
      );
    },
  },
  {
    field: "start_time",
    headerName: "Date",
    width: 150,
    renderCell: (v) => (
      <Typography variant="body2">
        {format(new Date(v.row.start_time), "MM/dd/yyyy")}
      </Typography>
    ),
  },
  {
    field: "end_time",
    headerName: "Time",
    width: 300,
    renderCell: (v) => {
      const start = new Date(v.row.start_time);
      const end = new Date(v.row.end_time);
      return (
        <Typography variant="body2">
          {start.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
          {" - "}
          {end.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
        </Typography>
      );
    },
  },
  {
    field: "",
    flex: 1,
    align: "right",
    disableExport: true,
    disableColumnMenu: true,
    disableReorder: true,
    sortable: false,
    renderCell: (p) => {
      const nav = useNavigate();
      const dispatch = useDispatch();
      return (
        <Box>
          <IconButton
            size="small"
            onClick={() =>
              nav(AdminPages.LiveShowEdit.path.replace(":id", p.row.id + ""))
            }
          >
            <EditOutlinedIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => {
              dispatch(adminActions.deleteLiveShow(p.row.id));
            }}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </Box>
      );
    },
  },
];
export const APP_TYPES_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "name",
    headerName: "Name",
    width: 250,
  },
  {
    field: "type",
    headerName: "Type",
    width: 150,
  },
  {
    field: "meta",
    headerName: "Meta",
    width: 250,
    valueGetter: (params) => {
      return params.value ? JSON.stringify(params.value) : "";
    },
  },
];

export const VOICEMAIL_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "created_at",
    headerName: "Created",
    width: 180,
    valueFormatter: (v) =>
      `${new Date(v.value).toLocaleDateString()} - ${new Date(
        v.value,
      ).toLocaleTimeString()}`,
  },
  {
    field: "cid_name",
    headerName: "CID name",
    width: 150,
  },
  {
    field: "customer_id",
    headerName: "Cust ID",
    width: 75,
    renderCell: (p) => <CustomerButton params={p} />,
  },
  {
    field: "cid_phone",
    headerName: "CID phone",
    width: 120,
  },
  {
    field: "voicemail_box.name",
    headerName: "Voicemail Box",
    width: 180,
    valueGetter: (p) => {
      getVoicemailBoxes();
      return p.row?.voicemail_box?.name;
    },
    sortable: false,
    type: "singleSelect",
    //TODO: Pull from DB
    valueOptions: getVoicemailBoxes,
  },
  {
    field: "status",
    headerName: "Status",
    width: 350,
    type: "singleSelect",
    valueOptions: ["new", "old", "saved", "discard"],
    renderCell: (p) => <VoicemailStatusButtons params={p} />,
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 110,
    renderCell: (p) => {
      const [openModal, setOpenModal] = useState(false);
      const [mode, setMode] = useState("");
      const openModalClick = () => {
        setOpenModal(!openModal);
      };
      return (
        <Grid>
          <IconButton
            color="primary"
            onClick={() => {
              setMode("Duplicate");
              openModalClick();
            }}
          >
            <ControlPointDuplicateOutlinedIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => {
              setMode("Send");
              openModalClick();
            }}
          >
            <SendIcon fontSize="small" />
          </IconButton>
          <Modal open={openModal} onClose={openModalClick}>
            <Box
              sx={{
                width: "18%",
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <VoicemailActionsForm
                closeModal={openModalClick}
                id={p.row.id}
                mode={mode}
              />
            </Box>
          </Modal>
        </Grid>
      );
    },
  },
  {
    field: "full_url",
    headerName: "Recording",
    width: 400,
    renderCell: (p) => <VoicemailPlayer params={p} />,
  },
];
export const VOICEMAIL_BOX_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "name",
    headerName: "Name",
    width: 200,
  },
  { field: "app_id", headerName: "app_id", width: 90 },
  {
    field: "external_name",
    headerName: "External Name",
    width: 200,
  },
  {
    field: "created_at",
    headerName: "Created",
    width: 250,
    valueFormatter: (v) =>
      `${new Date(v.value).toLocaleDateString()} - ${new Date(
        v.value,
      ).toLocaleTimeString()}`,
  },
  {
    field: "actions",
    flex: 1,
    align: "right",
    disableExport: true,
    disableColumnMenu: true,
    disableReorder: true,
    sortable: false,
    renderCell: (p) => <VoicemailBoxesActions params={p} />,
  },
];
export const GAMES_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "name",
    headerName: "Name",
    width: 300,
  },
  ...CREATED_UPDATED_COLS,
];
export const GAMES_RULES_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "game_id" },
  { field: "location_id" },
  { field: "rule_name", width: 300 },
  { field: "min_score" },
  { field: "max_age_points" },
  { field: "prize_type" },
  { field: "play_limit" },
  { field: "game_level" },
  { field: "point_count" },
  {
    field: "version",
    headerName: "Version",
    width: 300,
    type: "number",
  },
  ...CREATED_UPDATED_COLS,
];
export const SERIAL_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },

  {
    field: "name",
    headerName: "Name",
    width: 300,
    valueGetter: (r) => r.value,
  },
  {
    field: "actions",
    flex: 1,
    align: "right",
    disableExport: true,
    disableColumnMenu: true,
    disableReorder: true,
    sortable: false,
    renderCell: (p) => <SerialsActions params={p} />,
  },
];

export const MENU_STATS_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "Line", width: 70 },
  {
    field: "location_id",
    headerName: "Location",
    width: 300,
  },
  {
    field: "count",
    headerName: "Count",
    type: "number",
    width: 200,
    headerAlign: 'left',
    align: 'left'
  },
  {
    field: "unique call count",
    headerName: "Unique Call Count",
    type: "number",
    width: 200,
    headerAlign: 'left',
    align: 'left'
  },
  {
    field: "unique customer count",
    headerName: "Unique Customer Count",
    type: "number",
    width: 200,
    headerAlign: 'left',
    align: 'left'
  },
];

export const ALIASES_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "original_name",
    headerName: "Original Name",
    width: 300,
  },
  {
    field: "alias",
    headerName: "Alias",
  },
  {
    field: "",
    flex: 1,
    align: "right",
    disableExport: true,
    disableColumnMenu: true,
    disableReorder: true,
    sortable: false,
    renderCell: (p) => {
      const dispatch = useDispatch();
      return (
        <Box>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(adminActions.deleteAlias(p.row.id));
            }}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </Box>
      );
    },
  },
];
export const RAFFLES_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "name",
    headerName: "Raffle Name",
    width: 200,
  },
  {
    field: "ticket_price",
    headerName: "Points needed",
    width: 200,
  },
  {
    field: "auto_entry",
    headerName: "Auto Entry",
    type: "boolean",
  },

  {
    field: "",
    flex: 1,
    align: "right",
    disableExport: true,
    disableColumnMenu: true,
    disableReorder: true,
    sortable: false,
    renderCell: (p) => {
      const dispatch = useDispatch();
      return (
        <Box>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              const confirmDelete = confirm(
                "Are you sure you want to delete this raffle?  This will delete all the raffle tickets for this raffle"
              );
              if (confirmDelete) {
                dispatch(adminActions.deleteRaffle(p.row.id));
              }

            }}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </Box>
      );
    },
  },
];
export const SERIES_COLUMNS: GridColDef[] = [
  {
    field: "",
    disableExport: true,
    disableColumnMenu: true,
    disableReorder: true,
    sortable: false,
    renderCell: (p) => {
      const nav = useNavigate();
      return (
        <Box>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              nav({
                pathname: `/admin/series/${p.id}`,
              });
            }}
          >
            <EditIcon />
          </IconButton>
        </Box>
      );
    },
  },
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "name",
    headerName: "Name",
    width: 300,
  },
  ...CREATED_UPDATED_COLS,
  {
    field: "active",
    headerName: "Active",
    type: "boolean",
  },
];

export const SUBSCRIPTIONS_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 120 },
  {
    field: "phone_number",
    headerName: "Phone Number",
    width: 200,
    valueGetter: (r) => {
      const phone = r.row.phone;
      if (phone) {
        return phone.phone_number.length === 10 ? formatPhone(phone.phone_number) : formatPhone(phone.area_code + phone.phone_number);
      }

    },

  },
  {
    field: "shipping_name",
    headerName: "Shipping Name",
    width: 200,
  },
  {
    field: "amount",
    headerName: "Amount",
  },
  {
    field: "active",
    headerName: "Active",
    type: "boolean",
  },
  {
    field: "source",
    headerName: "Source",
  },
  {
    field: "name",
    headerName: "Name",
    width: 200,
  },
  {
    field: "provider_subscription_id",
    headerName: "CB Subscription ID",
    width: 200,
    renderCell: (p) => <CBSubLink params={p} />,
  },
  {
    field: "CB_customer_id",
    headerName: "CB Customer ID",
    width: 200,
    renderCell: (p) => <CBCustomerLink params={p} />,
  },
  {
    field: "plan_type",
    headerName: "Plan Type",
  },
  {
    field: "action",
    headerName: "Actions",
    width: 180,
    renderCell: (p) => {
      if (p.row.plan_type === "loop") {
        return <EmailSubscriptionButton params={p} />
      }
    },

  },
];

export const CHILDREN_POINTS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "amount",
    headerName: "Score",
  },
  {
    field: "description",
    headerName: "Description",
    width: 300,
  },
  {
    field: "game_version_id",
    headerName: "Version ID",
  },
  {
    field: "location_id",
    headerName: "Menu",
    width: 200,
  },
  {
    field: "point_type",
    headerName: "Point Type",
  },
  {
    field: "points",
    headerName: "Points",
  },
  {
    field: "created_at",
    headerName: "Time",
    type: "dateTime",
    width: 200,
  },
];
export const CHILDREN_POINTS_PAGE: GridColDef[] = [
  ...CHILDREN_POINTS,
  ...CHILD_NAMES_COLUMNS,
];

export const REPORTS_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "name",
    headerName: "Name",
    width: 300,
  },
];

export const GAME_VERSIONS_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "Version ID", width: 70 },
  { field: "game_id", headerName: "Game ID", width: 70 },
  {
    field: "name",
    headerName: "Game Name",
    width: 300,
  },
  {
    field: "last_updated_at",
    headerName: "Last Updated On",
    width: 300,
    type: "dateTime",
    valueGetter: (v) => new Date(v.value).toLocaleString(),
  },
  {
    field: "latest_version",
    headerName: "Latest Ver",
    width: 100,
  },
  {
    field: "total_games_played",
    headerName: "Played Curr Ver",
    width: 180,
  },
  {
    field: "action",
    headerName: "Actions",
    width: 180,
    renderCell: (p) => <BumpVersionButton params={p} />,
  },
];

export const PHONE_COLUMNS: GridColDef[] = [
  { field: "label", headerName: "Label", width: 150 },
  { field: "country_code", headerName: "Country Code", width: 50 },
  {
    field: "phone_number",
    headerName: "Phone Number",
    width: 200,
    flex: 1,
  },
  {
    field: "number_type",
    headerName: "Type",
    flex: 1,
    width: 150,
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 100,
    align: "right",
    headerAlign: "right",
    renderCell: (p) => <PhoneActions params={p} />,
  },
];
export const CHILDREN_COLUMNS_GENERAL: GridColDef[] = [
  { field: "first_name", headerName: "Name", width: 150 },
  {
    field: "dob",
    headerName: "DOB",
    renderCell: (p) => {
      return <Typography>{formatDate(p.value, "MM/dd/yyyy", true)}</Typography>;
    },
    width: 150,
  },
  {
    field: "pin",
    headerName: "Pin",
    width: 75,
    renderCell: (p) => {
      return (
        <Typography>
          PIN
          <Box ml={1} component={"span"} fontWeight={"bold"}>
            {p.row.pin}
          </Box>
        </Typography>
      );
    },
  },
  {
    field: "points_earned",
    headerName: "Earned",
    width: 70,
    renderCell: (p) => {
      const points = p.row.children_points ?? 0;
      return <Typography>{points}</Typography>;
    },
  },
  /* TODO: Figure out redemptions "used" is zero for now and balance == earned*/
  {
    field: "points_used",
    headerName: "Used",
    width: 70,
    renderCell: () => {
      return <Typography>{0}</Typography>;
    },
  },
  {
    field: "points_bal",
    headerName: "Balance",
    width: 100,
    renderCell: (p) => {
      const points = p.row.children_points ?? 0;
      return <Typography>{points}</Typography>;
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    minWidth: 150,
    flex: 1,
    align: "right",
    headerAlign: "right",
    renderCell: (p) => <ChildActions params={p} />,
  },
];
