import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { SubscribeLayout } from "../../layouts/main/SubscribeLayout";
import { useMobile } from "../../themes";
import { useDispatch, useSelector } from "react-redux";
import { customerActions, customerSelectors } from "../../state";
import { useEffect, useState } from "react";
import { DownloadIcon, ExpandMoreIcon } from "../../components";
import MusicNote from "../../assets/img/music_note.svg";
import SirConf from "../../assets/img/sir_conference.png";
import { CirConferenceCircle } from "../../components/CirConferenceCircle";

interface Series {
  id: number;
  name: string;
  tracks: Track[];
  date: string;
}

interface Track {
  id: number;
  voicemail_id: number;
  sort: number;
  name: string;
  external_name?: string;
  file_path: string;
  full_url: string;
}

const AccordionTitle = ({ series }: { series: Series }) => {
  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <img src={MusicNote} alt="MusicNote" />
      <Box>
        <Typography
          sx={{
            color: "primary.dark",
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          {series.name}
        </Typography>
        <Typography
          sx={{
            color: "secondary.light",
            fontSize: 16,
            fontWeight: 500,
          }}
        >
          {series.tracks.length} tracks • {series.date}
        </Typography>
      </Box>
    </Box>
  );
};

export const LoopAudioCustomerPage = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const isMobile = useMobile();
  const params = useParams();
  const customerChildObj = useSelector(customerSelectors.customerChildObj);
  const child = customerChildObj.entities[params.id + ""] || {};
  const [series, setSeries] = useState<Series[] | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (!params.id) return;
      const _series = (await dispatch(
        customerActions.getKidsTracks(+params.id),
      )) as unknown as Series[];
      setSeries(_series);
      setIsLoading(false);
    })();
  }, [params.id]);

  useEffect(() => {
    if (isMobile) {
      window.FreshworksWidget("hide", "launcher");
    }
  }, []);

  return (
    <SubscribeLayout
      showTabs={false}
      subHeader={false}
      displayContents={true}
      sx={{ height: !isMobile ? "80vh" : "auto", pt: 0 }}
      title={`${child.first_name} ${child.last_name}'s CDs`}
      backAction={() => nav(-1)}
    >
      <Box
        sx={{
          margin: isMobile ? 2 : 4,
          marginTop: isMobile ? 0 : 12,
          width: isMobile
            ? "calc(100vw - 16px)"
            : "calc(100vw - 480px - 100px)",
          height: "100%",
          position: "relative",
          zIndex: 99,
          overflow: "scroll",
          paddingBottom: 4,
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              minHeight: 600,
            }}
          >
            <CircularProgress size={128} />
          </Box>
        ) : !series ? (
          <Box sx={{ mt: 8, textAlign: "center", minHeight: "60%" }}>
            <Typography variant="h4">There are no CD’s yet</Typography>
            <CirConferenceCircle />
          </Box>
        ) : (
          <>
            {isMobile && <CirConferenceCircle />}
            <Box sx={{ display: "flex", mt: 6 }}>
              <Box>
                {series.map((d, idx) => {
                  return (
                    <Accordion
                      sx={{
                        padding: 1,
                        maxWidth: "90vw",
                        marginBottom: 2,
                      }}
                      key={idx}
                      defaultExpanded={idx === 0}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <AccordionTitle series={d} />
                      </AccordionSummary>
                      <AccordionDetails>
                        {d.tracks.map((t, idx) => {
                          return (
                            <Box
                              key={idx}
                              sx={{
                                width: 760,
                                maxWidth: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: !isMobile ? 2 : 0,
                                paddingTop: 2,
                                paddingBottom: 2,
                                borderBottom:
                                  d.tracks.length - 1 === idx
                                    ? undefined
                                    : "solid 1px #E4E7EB", //Hide last border
                              }}
                            >
                              <Box
                                sx={{
                                  maxWidth: "100%",
                                  width: 460,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography variant="h6">
                                  {t.external_name || `Track ${idx + 1}`}
                                </Typography>
                                <audio
                                  style={{ maxWidth: isMobile ? "50%" : "70%" }}
                                  controls
                                  src={t.full_url}
                                />
                              </Box>
                              <Button
                                onClick={() =>
                                  dispatch(
                                    customerActions.downloadTrack(
                                      t.voicemail_id,
                                      (t.external_name || `Track ${idx + 1}`) +
                                        ".wav",
                                    ),
                                  )
                                }
                                sx={{
                                  color: "primary.dark",
                                  alignContent: "flex-end",
                                }}
                              >
                                <DownloadIcon />
                                {!isMobile && (
                                  <Typography
                                    sx={{
                                      marginLeft: 2,
                                      color: "primary.dark",
                                    }}
                                    variant="h6"
                                  >
                                    Download
                                  </Typography>
                                )}
                              </Button>
                            </Box>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Box>
              {!isMobile && (
                <Box sx={{ width: "10%", height: "calc(100vh - 200px)" }}>
                  <img
                    style={{ position: "fixed", bottom: 300, right: 100 }}
                    src={SirConf}
                    alt="SirConf"
                  />
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
    </SubscribeLayout>
  );
};
