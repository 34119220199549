import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import circleLogo from "../../assets/stats/circle-logo.png";
import bookClubLogo from "../../assets/stats/book-club-logo.png";
import theVoiceBg from "../../assets/stats/the-voice-bg.png";
import circlesBg from "../../assets/stats/circles-bg.svg";
import { TStatSection } from "../../state/stats/state";

export const colors = {
  grey1: "#66788A",
  green1: "#1CA5A8",
  violet1: "#8C5190",
  violet2: "#4B276B",
  violet3: "#4C276B",
  orange: "#EC6740",
  blue1: "#E4E7EB",
  blue2: "#3A3B3F",
};

export const fontFamily = "Cera Pro";

export const getCommonSectionStyles = (
  section: TStatSection | "voice",
  theme: Theme,
): SxProps<Theme> => {
  let backgroundColor = theme.palette.secondary.contrastText;
  let backgroundImage;
  let backgroundPosition = "top left";

  switch (section) {
    case "circle":
      backgroundColor = colors.green1;
      backgroundImage = `url(${circleLogo})`;
      break;

    case "loop":
      backgroundColor = colors.violet1;
      // backgroundImage = `url(${theLoopLogo})`;
      backgroundImage = `url(${circlesBg})`;
      break;

    case "book":
      backgroundColor = colors.orange;
      backgroundImage = `url(${bookClubLogo})`;
      break;

    default:
      backgroundImage = `url(${theVoiceBg})`;
      backgroundPosition = "center center";
      break;
  }

  return {
    position: "relative",
    width: "480px",
    height: "100%",
    backgroundColor,
    backgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundPosition,
    // backgroundSize: "100% auto",
    display: "flex",
    flexDirection: "column",
  };
};

export const wrapper: SxProps<Theme> = {
  width: "100vw",
  height: "100vh",
  fontFamily,
  display: "flex",
};

export const circle = (theme: Theme): SxProps<Theme> => {
  return { ...getCommonSectionStyles("circle", theme) };
};

export const loop = (theme: Theme): SxProps<Theme> => {
  return { ...getCommonSectionStyles("loop", theme) };
};

export const book = (theme: Theme): SxProps<Theme> => {
  return { ...getCommonSectionStyles("book", theme), overflow: "hidden" };
};

export const voice = (theme: Theme): SxProps<Theme> => {
  return { ...getCommonSectionStyles("voice", theme) };
};

export const voiceStyles: SxProps<Theme> = {
  width: "100%",
  padding: "18px 16px 0",

  "& > img": {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
};

export const kidsStyles: SxProps<Theme> = {
  position: "absolute",
  bottom: -10,
  left: `${(85 / 1920) * 100}%`,
  zIndex: 1,

  "& > img": {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
};

export const manStyles: SxProps<Theme> = {
  position: "absolute",
  bottom: -10,
  left: `${(780 / 1920) * 100}%`,
  zIndex: 1,

  "& > img": {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
};

export const loopStyle: SxProps<Theme> = {
  position: "absolute",
  top: 0,
  left: 0,

  "& > img": {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
};

export const oval = (theme: Theme): SxProps<Theme> => {
  return {
    position: "absolute",
    bottom: "-190px",
    right: "-360px",
    height: "677px",
    width: "677px",
    opacity: 0.1,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.contrastText,
  };
};
