import {
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import { InputField, SelectField } from "../inputs/InputField";
import { yup } from "../../lib";
import {
  adminActions,
  adminSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { useEffect } from "react";
import { PaperDropzone } from "../";

const validationSchema = yup.object({
  recording_type_id: yup.number().required("Please select recording type"),
  public_url: yup.string().when("file_mode", {
    is: (a) => a === "useDownloadUrl",
    then: yup.string().required("file url is required."),
    otherwise: yup.string(),
  }),
  file: yup.mixed().when("file_mode", {
    is: (a) => a !== "useDownloadUrl",
    then: yup.mixed().required("file is required."),
    otherwise: yup.mixed(),
  }),
});

export const BulkUploadRecordingForm = () => {
  const initialValues = {
    recording_type_id: "",
    file: "",
    file_mode: "useDownloadUrl",
  };

  const dispatch = useDispatch();
  const recording_types_options = useSelector(
    adminSelectors.recording_types_options,
  );

  const handleSubmit = async (values) => {
    if (values.file_mode === "useDownloadUrl") {
      await dispatch(adminActions.saveRecordingUrl(values, true));
    } else {
      await dispatch(adminActions.saveRecording(values, true));
    }
  };

  useEffect(() => {
    dispatch(adminActions.getRecordingTypes());
  }, [dispatch]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <Grid alignItems={"center"} container spacing={4}>
            <Grid item xs={12} md={12}>
              <SelectField
                label="Recording Type"
                name="recording_type_id"
                options={recording_types_options}
                initialValues={initialValues}
              />
              <ToggleButtonGroup
                fullWidth
                sx={{ mt: 4, mb: 4 }}
                value={values.file_mode}
                exclusive
                onChange={(e, v) => {
                  if (!v) return;
                  setFieldValue("file_mode", v);
                }}
              >
                <ToggleButton value="useDownloadUrl">
                  <Typography>Have Public Download URL</Typography>
                </ToggleButton>
                <ToggleButton value="useUploadFile">
                  <Typography>Upload File less than 32 MB</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
              {values.file_mode === "useDownloadUrl" ? (
                <InputField label="Public Location" name="public_url" />
              ) : (
                <PaperDropzone
                  filesCB={(files) => setFieldValue("file", files)}
                />
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                fullWidth
                sx={{ mt: 3, ml: 0 }}
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
