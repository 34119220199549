import { Box, Typography } from "@mui/material";
import { SubscribeLayout } from "../../layouts/main/SubscribeLayout";
import { LoopSubscriptionCard } from "../../components/LoopSubscriptionCard";
import { useSelector } from "react-redux";
import { customerSelectors } from "../../state";

export const LoopDashboard = () => {
  const loopSubscriptions = useSelector(customerSelectors.loopSubscriptions);
  return (
    <SubscribeLayout
      showTabs={false}
      subHeader={false}
      sx={{ position: "relative" }}
    >
      <Box sx={{ mb: 4 }}>
        <Typography
          sx={{
            color: "rgba(0,0,0,0.87)",
            fontFamily: "Cera Pro",
            fontSize: 20,
            fontWeight: 500,
          }}
        >
          Your current Loop subscriptions
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 3, flexDirection: "column" }}>
        {loopSubscriptions.map(
          ({ shipping_last_name, subscription_phones, id, subscription_children }, idx) => (
            <LoopSubscriptionCard
              key={idx}
              subscription_id={id}
              last_name={shipping_last_name}
              number={subscription_phones.length ? subscription_phones[0].phone_number : ""}
              subscription_children={subscription_children}
            />
          ),
        )}
      </Box>
    </SubscribeLayout>
  );
};
