import AdminPages from ".";
import { ArrowBackIcon } from "../../components";
import { Grid, IconButton, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { RootListViewStyled } from "./GenericPage.styles";
import { GameRulesForm } from "../../components/forms/GameRulesForm";

export const GameRulesCreateOrEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <RootListViewStyled>
      <Grid
        container
        spacing={12}
        direction="row"
        justifyContent="space-between"
        paddingBottom={3}
      >
        <Grid item>
          <Typography variant="h3">
            <IconButton
              color="primary"
              onClick={() => navigate(AdminPages.games.path)}
            >
              <ArrowBackIcon />
            </IconButton>
            {id ? "Edit" : "Add new"} Game Rule
          </Typography>
        </Grid>
      </Grid>
      <GameRulesForm />
    </RootListViewStyled>
  );
};
