import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../types";
import { arrayToEntityList } from "../../lib";

const _state = (state: AppState) => state.customer;

const _customerChildObj = createSelector(_state, (state) => {
  const customerChildren = state.customer.customer_children || [];
  return arrayToEntityList(customerChildren, "id");
});
const _loopSubscriptions = createSelector(_state, (state) => {
  return (
    state.customer?.user?.subscriptions?.filter((s) => s.subscription_plan_id === 10 && s.active === true) || []
  );
});

export const customerSelectors = {
  customer(state: AppState) {
    return state.customer;
  },
  customerChildObj: _customerChildObj,
  products(state: AppState) {
    return state.customer.product_selected ?? [];
  },
  return_customer_products(state: AppState) {
    return state.customer.return_customer_products ?? [];
  },
  products_available(state: AppState) {
    return state.customer.product_available ?? [];
  },
  customer_cards(state: AppState) {
    return state.customer.customer_cards ?? [];
  },
  cb_customer_subscription(state: AppState) {
    return state.customer.cb_customer_subscription ?? [];
  },
  subscriptions(state: AppState) {
    return state.customer.subscriptions ?? [];
  },
  cb_customer_id(state: AppState) {
    return state.customer.provider_customer_id ?? "";
  },
  renewing(state: AppState) {
    return state.customer.renewing ?? {};
  },
  loopSubscriptions: _loopSubscriptions,
};
