import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Drawer, Divider } from "@mui/material";
import Menu from "./Menu";
import TopBar from "./TopBar";
import logo from "../../assets/img/logo.png";

const drawerWidth = 240;
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
export default function PermanentDrawerLeft() {
  return (
    <Box sx={{ display: "flex" }}>
      <TopBar />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <DrawerHeader
          sx={{
            p: 4,
            ml: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={logo} alt="loop-logo" width={"120px"} />
        </DrawerHeader>
        <Divider />
        <Menu />
      </Drawer>
    </Box>
  );
}
