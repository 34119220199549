import { Box, IconButton, Typography } from "@mui/material";
import logo from "../../src/assets/img/loop-transparent-person.png";
import {
  Phone as PhoneIcon,
  ChevronRight as ChevronRightIcon,
  Warning as WarningIcon
} from "@mui/icons-material";
import { formatPhone } from "../lib";
import { useMobile } from "../themes";
import { useNavigate, generatePath } from "react-router-dom";
import { MainPages } from "../pages/main";

export const LoopSubscriptionCard = ({
  number,
  last_name,
  subscription_id,
  subscription_children
}) => {
  const isMobile = useMobile();
  const nav = useNavigate();

  const missingInfo = number === "" || subscription_children.length === 0;

  const alert = <Box
    sx={{
      width: "100%",
      backgroundColor: '#F5F6FA',
      p: 1,
      m: 2
    }}
  >
    <Typography sx={{
      display: "flex",
      alignItems: "center",
      gap: 1,
      color: "#5D6995",
      fontSize: 14
    }}><WarningIcon />Account setup incomplete </Typography>
    <Typography sx={{
      display: "flex",
      alignItems: "center",
      gap: 1,
      color: "#99A1C0",
      pl: 4,
      fontSize: 12
    }}>Click the arrow on the right to add your phone number(s) and/or child(ren).</Typography>
  </Box>;

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        width: "100%",
        border: "1px solid #D7D9EC",
        borderRadius: 4,
        backgroundColor: "#FFFFFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: "primary.dark",
        fontFamily: "Cera Pro",
        fontSize: 20,
        cursor: "pointer",
        flexDirection: isMobile ? "column" : "row"
      }}
      onClick={() =>
        nav(
          generatePath(MainPages.manageLoopSubscription.path, {
            subscription_id,
          }),
        )
      }
    >
      <Box sx={{ display: "flex", width: "100%" }} >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%"
          }}
        >

          <Box
            sx={{
              height: isMobile ? 80 : 120,
              width: isMobile ? 80 : 120,
              borderRadius: 4,
              backgroundColor: "#F5F6FA",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              m: 2,
            }}
          >
            <img style={{ width: "90%" }} src={logo} alt="logo" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "baseline",
              flexGrow: 1
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,

                color: "primary.dark",
                fontFamily: "Cera Pro",
                fontSize: isMobile ? 15 : 20,
                lineHeight: 2,
              }}
            >
              <PhoneIcon /> {formatPhone(number)}
            </Typography>
            <Typography
              sx={{
                color: "primary.dark",
                fontFamily: "Cera Pro",
                fontSize: isMobile ? 16 : 20,
                lineHeight: 2,
              }}
            >
              {last_name ? `${last_name} Family` : ""}
            </Typography>
            {(missingInfo && !isMobile) && (alert)}
          </Box>

        </Box>
        <IconButton sx={{ m: isMobile ? 2 : 0 }} >
          <ChevronRightIcon
            sx={(theme) => ({ fill: theme.palette.primary.dark, fontSize: "40px" })}
          />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex" }} >
        {(missingInfo && isMobile) && (alert)}
      </Box>
    </Box>
  );
};
